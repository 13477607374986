import React, { useState, Fragment, useRef } from "react";
// import { useParams } from "react-router-dom";
// Import Highcharts
import Highcharts from "highcharts";
import drilldown from "highcharts/modules/data.js";
import HighchartsReact from "highcharts-react-official";

import { DatePickerTools } from "../../../../commons/components/date-picker/DatePickerTools";
import { ExportCSV } from "../../../../commons/components/export-excel/ExportCSV";
//services
import { cacheService } from "../../../../utilities/services";
import webviewServices from "../../../../utilities/services/webview";
//type
import { WebviewTypes } from "../../webview/type";
import moment from "moment";
import utilsService from "../../../../utilities/services/utils/utils.service";
import { LOCAL_STORAGE_USER_PARTICIPANTID } from "../../../../constants";
import { NHA_MAY_CHE_BIEN } from "../../../../constants/shrimp-manage/ParticipantRole";
import { ParticipantService } from "./../../../../utilities/services/participants/ParticipantService";

drilldown(Highcharts);

const UNIT = "kg";

const metadataChart = {
  credits: {
    enabled: false,
  },
  chart: {
    type: "column",
  },
  title: {
    text: "",
  },
  xAxis: {
    categories: [],
  },
  yAxis: {
    title: {
      text: "Đơn vị tính: " + UNIT,
    },
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    series: {
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        formatter: function () {
          return utilsService.FormatCurrency(this.y) + " " + UNIT;
        },
      },
    },
  },
  tooltip: {
    pointFormatter: function () {
      return '<span style="color:' + this.color + '">' + this.series.name + "</span>: <b>" + utilsService.FormatCurrency(this.y) + " " + UNIT;
    },
  },
  series: [],
};

const StatisticProductionOutputByGroup = (props) => {
  const [options, setOptions] = useState(metadataChart);
  const [dataTable, setDataTable] = useState(null);
  const [participants, setParticipants] = useState([]);
  const timeOption = useRef(null);
  const dataList = [];

  const isRoot = cacheService.get("isRoot");
  const id = cacheService.get(LOCAL_STORAGE_USER_PARTICIPANTID);
  //const { id } = useParams();

  const styleHeader = {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#123E69",
    textAlign: "left",
    marginBottom: "0",
  };
  /////////***********  Start Function-Management  ***********/////////////////
  const getDataParticipants = async (response, result = []) => {
    try {
      const param = {
        RoleName: NHA_MAY_CHE_BIEN,
        Active: true,
      };
      response = await ParticipantService.getParticipant(param);
      if (response && response?.data?.items?.length) {
        result = response.data.items.map((p) => {
          return {
            value: p.id,
            label: p.name,
          };
        });
      }
      setParticipants(result);
    } catch (err) {
      console.error("getDataParticipants", err);
    }
  };

  const getDataChart = async (filter) => {
    try {
      timeOption.current = filter;
      let quantityTotal = 0;
      let group = [];
      let production = [];
      const response = await webviewServices.getDataReport(id || filter.filterValues?.ParticipantId || props.id, filter.from, filter.to, WebviewTypes.productionOutputByGroup);
      if (response) {
        console.log("getDataChart", response);
        const value = typeof response.data === "object" && response.data.length ? response.data : [];
        value.forEach((v) => {
          group.push(v.groupName);
          v.data.forEach((d) => {
            let index = production.findIndex((prod) => prod.name === d.name);
            if (index !== -1) {
              production[index].data.push(d.value);
            } else {
              production.push({ name: d.name, data: [d.value] });
            }
          });
        });
        setOptions({ ...options, series: production, xAxis: { categories: group } });
        setDataTable(value);
      }
    } catch (err) {
      console.error("getDataChart", err);
    }
  };

  const defineTable = (data, quantity) => {
    const title = timeOption?.current ? `THỐNG KÊ CHẾ BIẾN TỪ NGÀY ${moment(timeOption.current.from).format("DD/MM/YYYY")} ĐẾN ${moment(timeOption.current.to).format("DD/MM/YYYY")}` : "";
    let renderRows = `<tr><th colspan="2" style="text-align:'left'">TỔNG CÁC NHÓM</th><th style="text-align:'left'">${quantity}</th></tr>`;
    data.forEach((item) => {
      if (item.isGroup) {
        renderRows += `<tr>
          <th >${item.index}</th>
          <th style="text-align:'left'">${item.name || ""}</th>
          <th style="text-align:'left'">${item.quantity}</th>
        </tr>`;
      } else {
        renderRows += `<tr>
          <td >${item.index}</td>
          <td>${item.name || ""}</td>
          <td style="text-align:'left'">${item.quantity}</td>
        </tr>`;
      }
    });

    return `
      <table border="1px">
          <thead>
            <tr>
              <th style="background-color:'#eaecf4'; font-size:'16px'" colspan="3">
                ${title}
              </th>
            </tr>
            <tr>
              <th>STT</th>
              <th>Cơ sở</th>
              <th>Sản lượng (${UNIT})</th>
            </tr>
          </thead>
          <tbody>
            ${renderRows}
          </tbody>
      </table>`;
  };
  const getDataExport = async () => {
    try {
      if (dataTable) {
        let dataTotal = [];
        let quantity = 0;
        const groupIndex = ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", "XI", "XII", "XIII", "XIV", "XV"];
        const groups = dataTable || [];
        groups.forEach((group, i) => {
          quantity += group.total;
          dataTotal.push({ isGroup: true, index: groupIndex[i], name: group.groupName, quantity: group.total || 0 });
          group.data.forEach((item, index) => {
            dataTotal.push({ index: index + 1, name: item.name, participantTeamName: item.participantTeamName, quantity: item.value || 0 });
          });
        });
        return defineTable(dataTotal, quantity);
      }
      return;
    } catch (err) {
      console.error("getDataExport", err);
    }
  };

  React.useEffect(() => {
    if (isRoot) {
      getDataParticipants();
    }
  }, []);
  /////////***********   End  Function-Management  ***********/////////////////
  return (
    <Fragment>
      <div className="mb-3">
        <DatePickerTools
          getDate={getDataChart}
          components={
            isRoot && [{
              name: "ParticipantId",
              label: "Nhà máy chế biến",
              data: participants,
            }]} />
      </div>
      <div className="mb-3">
        <h4 style={styleHeader}>Biểu đồ thống kê sản lượng chế biến</h4>
        {dataTable?.length ? <HighchartsReact highcharts={Highcharts} options={options} /> : <div className="text-info">Không có dữ liệu</div>}
      </div>

      <div className="mb-3">
        <div className="d-flex align-items-center justify-content-between">
          <h4 style={styleHeader}>Bảng biểu sản lượng chế biến</h4>
          {!props.webview && (dataTable?.length ? <ExportCSV csvData={getDataExport} fileName={"ThongKeSanLuongCheBien"} /> : "")}
        </div>
        <div className="mt-2">
          {dataTable?.length ? (
            <table className="table table-bordered" id="datatable" style={{ fontSize: "14.5px" }}>
              <thead>
                <tr className="text-center">
                  <th>Thống kê theo nhóm</th>
                  {dataTable[0]?.data?.length
                    ? dataTable[0].data.map((d, i) => {
                      return (
                        <th key={i}>
                          {d.name} ({UNIT})
                        </th>
                      );
                    })
                    : null}
                </tr>
              </thead>
              <tbody>
                {dataTable.map((item, index) => {
                  return (
                    <tr key={index} className="text-center">
                      <td scope="row">{item.groupName}</td>
                      {item?.data?.length &&
                        item?.data.map((i, key) => {
                          dataList[key] = (dataList[key] ?? 0) + i.value || 0;
                          return <td key={key}> {i.value || ""}</td>;
                        })}
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr className="text-center fw-5">
                  <td>TỔNG CÁC NHÓM</td>
                  {dataList.map((d, j) => {
                    return <td key={j}>{d || ""}</td>;
                  })}
                </tr>
              </tfoot>
            </table>
          ) : (
            <div className="text-info">Không có dữ liệu</div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default StatisticProductionOutputByGroup;
