import React from "react";
import { Button } from "reactstrap";
import swal from "sweetalert";
import proxyService from "../../../utilities/services/proxy/proxy.service";

const Blockchain = ({ data, api, method, headers, handleResponseData, whenClose, callback, transform2BE, controls }) => {
  const { getValues, handleSubmit } = controls;
  const requestAPI = async () => {
    if (method === "PUT") {
      const payload = typeof transform2BE === "function" ? await transform2BE(getValues()) : data;
      return await proxyService.put(api + `/${data.id}`, payload, headers);
    }
    if (method === "POST") {
      if (data?.id) {
        return await proxyService.post(api + `/${data.id}`, null, headers);
      } else {
        const payload = typeof transform2BE === "function" ? await transform2BE(getValues()) : data;
        return await proxyService.post(api, payload, headers);
      }
    }
  };

  const onSubmit = async (data) => {
    try {
      const confirmed = await swal({
        title: "Xác nhận",
        text: "Sau khi lưu vào Blockchain thì các thông tin sẽ không được thay đổi. Bạn có chắc muốn lưu không?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });
      if (confirmed) {
        const result = await requestAPI();
        if (!result) {
          throw new Error();
        }
        callback();
        data.id = result.data.id;
        const resData = typeof handleResponseData === "function" ? handleResponseData(result.data) : data;
        if (data.hasOwnProperty("concurrencyStamp") && result.data.concurrencyStamp) {
          resData.concurrencyStamp = result.data.concurrencyStamp;
        }
        if (data.hasOwnProperty("creationTime") && result.data.creationTime) {
          data.creationTime = result.data.creationTime;
        }
        whenClose(resData);
      } else {
        swal({ title: "Đã hủy!", icon: "error", timer: 2000 });
      }
    } catch (err) {
      if(!err.message.includes("status")) {
        swal({ title: "Thất bại!", icon: "error", timer: 2000 });
      }else {
        callback();
      }
    }
  };
  return (
    <Button type="button" color="primary" onClick={handleSubmit(onSubmit)} className="fn-w-600">
      <i className="fas fa-lock"></i> Lưu vào BlockChain
    </Button>
  );
};

export default Blockchain;
