import ErrorMessages from '../../../constants/error-messages/errorMessage.enum';

export const errorMessageHandlingService = {
  getErrorMessage
}

function getErrorMessage(responseData) {

  let errorCode = responseData.code;
  if (errorCode && errorCode.includes('Volo.Abp.Identity')) {
    errorCode = sliceErrorCode(errorCode);
    switch(errorCode) {
      case 'PasswordTooShort':
        return ErrorMessages.PASSWORD_TOO_SHORT;
      case 'PasswordRequiresNonAlphanumeric':
        return ErrorMessages.PASSWORD_REQUIRES_NON_ALPHANUMERIC;
      case 'PasswordRequiresDigit':
        return ErrorMessages.PASSWORD_REQUIRES_DIGIT;
      case 'PasswordRequiresLower':
        return ErrorMessages.PASSWORD_REQUIRES_LOWER;
      case 'PasswordRequiresUpper':
        return ErrorMessages.PASSWORD_REQUIRES_UPPER;
      case 'DefaultError':
        return ErrorMessages.DEFAULT_ERROR;
      case 'ConcurrencyFailure':
        return ErrorMessages.CONCURRENCY_FAILURE;
      case 'PasswordMismatch':
        return ErrorMessages.PASSWORD_MISMATCH;
      case 'InvalidToken':
        return ErrorMessages.INVALID_TOKEN;
      case 'LoginAlreadyAssociated':
        return ErrorMessages.LOGIN_ALREADY_ASSOCIATED;
      case 'InvalidUserName':
        return ErrorMessages.INVALID_USERNAME;
      case 'InvalidEmail':
        return ErrorMessages.INVALID_EMAIL;
      case 'DuplicateUserName':
        return ErrorMessages.DUPLICATE_USERNAME;
      case 'DuplicateEmail':
        return ErrorMessages.DUPLICATE_EMAIL;
      case 'InvalidRoleName':
        return ErrorMessages.INVALID_ROLENAME;
      case 'DuplicateRoleName':
        return ErrorMessages.DUPLICATE_ROLENAME;
      case 'UserAlreadyHasPassword':
        return ErrorMessages.USER_ALREADY_HAS_PASSWORD;
      case 'UserLockoutNotEnabled':
        return ErrorMessages.USER_LOCKOUT_NOT_ENABLED;
      case 'UserAlreadyInRole':
        return ErrorMessages.USER_ALREADY_IN_ROLE;
      case 'UserNotInRole':
        return ErrorMessages.USER_NOT_IN_ROLE;
      default:
        return null;
    }
  } else {
    return responseData.message;
  }
}

function sliceErrorCode(originCode) {
  return originCode.slice(18);
}

export default errorMessageHandlingService;