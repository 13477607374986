// import { WebviewTypes } from "../../../pages/users/webview/type";
import proxyService from "../proxy/proxy.service";

const webviewServices = {
  getTraceabilityByFarmId,
  getTraceabilityByLotId,
  getTraceabilityByTransportLotId,
  getBlocks,
  getBlockDetail,
  getDataReport,
  getDataReportExcel,
  getParticipantDetail,
};

let params = null;
let header = null;

function getTraceabilityByFarmId(id = null, date = null) {
  const url = `/api/app/traceability/${id}/byFarmId`;
  params = {
    HarvestDate: date,
  };
  return proxyService.get(url, header, params);
}

function getTraceabilityByTransportLotId(id = null, date = null) {
  const url = `/api/app/traceability/${id}/byTransportLotId`;
  params = {
    ProcessingDate: date,
  };
  return proxyService.get(url, header, params);
}

function getTraceabilityByLotId(id = null, date = null) {
  const url = `/api/app/traceability/${id}/byLotIdWithManyGroup`;
  params = {
    ProcessingDate: date,
  };
  return proxyService.get(url, header, params);
}

function getBlocks(skip_count = null, max_result_count = null) {
  const url = `/api/app/traceability/blockNum`;
  params = {
    SkipCount: skip_count,
    MaxResultCount: max_result_count,
  };
  return proxyService.get(url, header, params);
}

function getBlockDetail(transaction_id = null) {
  const url = `/api/app/Blockchain/${transaction_id}`;
  return proxyService.get(url, header, params);
}

function getDataReport(id = null, from = null, to = null, type = null) {
  params = {
    Id: id,
    From: from,
    To: to,
    type: type,
  };
  let url = "/api/app/reports";
  // switch (type) {
  //   case WebviewTypes.hatcheryOutput: {
  //     url = `/api/app/reports/hatchery`;
  //     break;
  //   }
  //   case WebviewTypes.farmHarvest: {
  //     url = `/api/app/reports/harvests`;
  //     break;
  //   }
  //   case WebviewTypes.farmStocking: {
  //     url = `/api/app/reports/stockingGroupTeams`;
  //     break;
  //   }
  //   case WebviewTypes.farmDisease: {
  //     url = `/api/app/reports/farmDisease`;
  //     break;
  //   }
  //   case WebviewTypes.productionOutput: {
  //     url = `/api/app/reports/processing`;
  //     break;
  //   }
  //   case WebviewTypes.productionOutputByGroup: {
  //     url = `/api/app/reports/processingByGroup`;
  //     break;
  //   }
  //   case WebviewTypes.purchasing: {
  //     url = `/api/app/reports/purchasing`;
  //     break;
  //   }
  //   default:
  //     break;
  // }
  return proxyService.get(url, header, params);
}

function getDataReportExcel(id = null, from = null, to = null, type = null, roleName = null) {
  params = {
    Id: id,
    From: from,
    To: to,
    type: type,
    RoleName: roleName,
  };
  let url = "/api/app/reports/detail";
  return proxyService.get(url, header, params);
}

function getParticipantDetail(id = null) {
  const url = `/api/app/participants/${id}`;
  return proxyService.get(url, header, params);
}

export default webviewServices;
