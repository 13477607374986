import React, { Fragment } from "react";
import Button from "react-bootstrap/Button";

export const ExportCSV = ({ fileName, csvData }) => {
  const TableToExcel = async () => {
    try {
      const uri = `data:application/vnd.ms-excel;base64,`;
      const template = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body>{table}</body></html>`;
      const fileExtension = ".xls";

      const base64 = function (s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      };
      const format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
          return c[p];
        });
      };

      const response = await csvData();
      if (response) {
        let tab_text = response;
        tab_text = tab_text.replace(/<A[^>]*>|<\/A>/g, ""); //remove if u want links in your table
        tab_text = tab_text.replace(/<a[^>]*>|<\/a>/g, ""); //remove if u want links in your table
        tab_text = tab_text.replace(/<img[^>]*>/gi, ""); // remove if u want images in your table
        tab_text = tab_text.replace(/<input[^>]*>|<\/input>/gi, ""); // reomves input params
        let ctx = { worksheet: "Worksheet", table: tab_text };
        let a = document.createElement("a");
        a.href = uri + base64(format(template, ctx));
        a.download = (fileName ? fileName : "FileName") + fileExtension;
        a.click();
      }
    } catch (err) {
      console.log("TableToExcel", err);
    }
  };

  return (
    <Fragment>
      <Button className="btn-success" variant="primary" onClick={TableToExcel}>
        <i className="ml-2 fas fa-file-export"></i> Xuất Excel
      </Button>
    </Fragment>
  );
};
