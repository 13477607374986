
const INIT_STATE = {
}

const settingReducers = (state = INIT_STATE, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default settingReducers;