import React, { useState } from "react";
import AccordionTitle from "./AccordionTitle";
import AccordionContent from "./AccordionContent";

const Accordion = ({ title, component }) => {
  const [showContent, setShowContent] = useState(true);
  const handleShowContent = () => setShowContent(!showContent);

  return (
    <div className="accordion-wrapper">
      <AccordionTitle active={showContent} toggle={handleShowContent} title={title} />
      <AccordionContent active={showContent} component={component} />
    </div>
  );
};

export default Accordion;
