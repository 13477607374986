import React, { Component } from "react";
import {
  Map,
  InfoWindow,
  // Listing,
  Marker,
  GoogleApiWrapper,
} from "google-maps-react";

export class Maps extends Component {
  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    bounds: {},
    stores: this.props.locations,
  };

  displayMarkers = () => {
    return this.state.stores.map((store, index) => {
      return (
        <Marker
          key={index}
          id={index}
          position={{
            lat: store.lat,
            lng: store.lng,
          }}
          onClick={this.onMarkerClick}
          name={store.name}
        />
      );
    });
  };

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  render() {
    var points = this.props.locations;
    // var bounds = new this.props.google.maps.LatLngBounds();
    // for (var i = 0; i < points.length; i++) {
    //   bounds.extend(points[i]);
    // }

    return (
      <Map zoom={14} google={this.props.google} style={mapStyles} initialCenter={{ ...points[0] }} bounds={""} onClick={this.onMapClicked}>
        {this.displayMarkers()}
        <InfoWindow marker={this.state.activeMarker} visible={this.state.showingInfoWindow}>
          <h5 className="mb-0">{this.state.selectedPlace.name}</h5>
        </InfoWindow>
      </Map>
    );
  }
}
const mapStyles = {
  width: "100%",
  height: "100%",
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyBVjJC0YfitZBQ16t7fnPvK7R8nvFY9CN0",
  language: "vi",
  v: "3.30",
})(Maps);
