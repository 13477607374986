import React from "react";
import "./App.css";
import { Router, Switch, Route } from "react-router-dom";

import indexRoutes from "./commons/routes/index";
import { PrivateRoute } from "./commons/routes/private-routes";

import LoginPage from "./pages/login/Login";
import { configureStore } from "./store/store";
import { Provider } from "react-redux";
import { history } from "./utilities/helpers/history";
import Loading from "./commons/components/loading/Loading";
import SnackBar from "./commons/components/snack-bar/SnackBar";
import DiariesWv from "./pages/users/webview/diary/DiariesWv";
import Traceability from "./pages/users/webview/traceability";
import FarmHarvestBatch from "./pages/users/webview/traceability/FarmHarvestBatch";
import Statistic from "./pages/users/webview/statistics";

const App = () => {
  // useEffect(() => {
  //   return () => SocketHelper.close()
  // }, [])
  return (
    <Provider store={configureStore()}>
      <Router history={history}>
        <Loading />
        <SnackBar />
        <Switch>
          <Route path="/login" component={LoginPage} />;
          <Route path="/diaries" component={DiariesWv} />;
          <Route path="/traceability" component={Traceability} />;
          <Route path="/farm-harvest-detail" component={FarmHarvestBatch} />;
          <Route path="/statistic" component={Statistic} />;
          {indexRoutes.map((prop, key) => {
            return <PrivateRoute path={prop.path} key={key} component={prop.component} />;
          })}{" "}
        </Switch>{" "}
      </Router>{" "}
    </Provider>
  );
};

export default App;
