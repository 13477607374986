import cacheService from '../../utilities/services/cache/cache.service';


const actionsTypes = {
  all: 'ALL',
  filtering: 'FILTERING',
  pageChange: 'PAGE_CHANGE',
  textSearchChange: 'TEXT_SEARCH_CHANGE',
  optionsSearchChange: 'OPTIONS_SEARCH_CHANGE',
  updateTotalRows: 'UPDATE_TOTAL_ROWS',
  updateCacheMode: 'UPDATE_CACHE_MODE',
  optionQuery: "OPTION_QUERY"
};
const getCache = () => {
  return cacheService.get(document.location.pathname);;
};

const initialState = {
  filtering: false,
  pagination: { current: 1, size: 25 },
  sizePerPageList: [{text: '10 dòng', value: 10}, {text: '25 dòng', value: 25}, {text: '50 dòng', value: 50}, {text: '100 dòng', value: 100}],
  textSearch: '',
  optionsSearch: null,
  query: '',
  totalRows: 0,
  isCache: false,
  optionQuery: null,
};

function requestURLCombination(text, pagination, options) {
  let query = '?';
  if (text) {
    query += 'Filter=' + text;
  }
  if (pagination) {
    if (query !== '?') {
      query += '&';
    }
    query += `SkipCount=${(pagination.current - 1) * pagination.size}&MaxResultCount=${pagination.size}`;
  }
  if (options) {
    for (const [key, value] of Object.entries(options)) {
      if (value !== null && value !== undefined && value !== '') {
        if (query !== '?') {
          query += '&';
        }
        query += `${key}=${value}`;
      }
    }
  }
  // console.log("options--->query", query);
  return query === '?' ? '' : query;
}

const updateCache = (state) => {
  if (state.isCache) {
    cacheService.set(document.location.pathname, state);
  }
}
const datatableReducer = (state = initialState, action) => {
  if (state.isCache) {
    const cache = getCache();
    state = cache || state;
  }
  switch (action.type) {

    case actionsTypes.optionQuery:
      const updateOptionQuery = {
        ...state,
        optionQuery: action.payload,
        optionsSearch: {...state.optionsSearch, ...action.payload}
      }
      return updateOptionQuery;

    case actionsTypes.updateTotalRows:
      const updateTotalRows = {
        ...state,
        totalRows: action.payload
      }
      return updateTotalRows;

    case actionsTypes.all:
      const all = {
        ...state,
        filtering: false,
        textSearch: '',
        pagination: { current: 1, size: state.pagination.size },
        optionsSearch: null,
        query: requestURLCombination('', { current: 1, size: state.pagination.size }, null),
      }
      updateCache(all);
      return all;

    case actionsTypes.filtering:
      return {
        ...state,
        filtering: true
      }

    case actionsTypes.pageChange:
      const pageChange = {
        ...state,
        filtering: true,
        pagination: action.payload,
        query: requestURLCombination(state.textSearch, action.payload, state.optionsSearch)
      };
      updateCache(pageChange);
      return pageChange;

    case actionsTypes.textSearchChange:
      const textSearchChange = {
        ...state,
        filtering: true,
        pagination: { current: 1, size: state.pagination.size },
        textSearch: action.payload,
        query: requestURLCombination(action.payload, { current: 1, size: state.pagination.size }, state.optionsSearch)
      }
      updateCache(textSearchChange);
      return textSearchChange;

    case actionsTypes.optionsSearchChange:
      const optionsSearchChange = {
        ...state,
        filtering: true,
        optionsSearch: {...state.optionQuery,...action.payload},
        query: requestURLCombination(state.textSearch, state.pagination, {...state.optionQuery,...action.payload})
      }
      updateCache(optionsSearchChange);
      return optionsSearchChange;

    case actionsTypes.updateCacheMode:
      const updateCacheMode = {
        ...state,
        isCache: action.payload
      }
      return updateCacheMode;

    default:
      return {
        ...state
      }
  }
}

export default datatableReducer;