import React, { Fragment } from "react";
import { useState, useEffect } from "react";
import { Button, Modal, ModalFooter, ModalBody, ModalHeader } from "reactstrap";
import CreateModal from "./CreateModal";
import proxyService from "../../../utilities/services/proxy/proxy.service";
import { Link } from "react-router-dom";
// import Axios from 'axios'

const CrudButtons = ({
  metadata,
  data,
  className,
  setHasChangeData,
  setBack,
  accessGrant,
}) => {
  const createBtn = metadata.crudButtons.create;
  const deleteBtn = metadata.crudButtons.delete;
  const updateListBtn = metadata.crudButtons.updateList;
  const customList = metadata.crudButtons.customList;

  // const localHost = process.env.REACT_APP_BASEURL;

  //Custom xóa item trên API nếu có host khác mà không thể đổi host mặc định
  // const  deleteFromAPI = async (ids,url)=>
  // {
  //   var newurl = url;

  //     for (var i = 0; i < ids.length; i++) {
  //         newurl =  url +`${ids[i]}`;
  //         console.log(Axios.delete(localHost + newurl))
  //         await Axios.delete(localHost + newurl);
  //     }
  // }

  /* 
  Lưu toàn bộ dữ liệu của bảng 
  */
  const [dataSource, setDataSource] = useState();
  /* 
  Lưu trạng thái của modal - hiển thị hoặc ẩn 
  */
  const [openModal, setOpenModal] = useState(false);
  const [openUpdateListModal, setOpenUpdateListModal] = useState(false);
  /* 
  Xóa dòng được chọn trên bảng 
  */

  const removeCheckedItem = () => {
    const temp = data.filter((x) => !x.isChecked);
    setDataSource(temp);
    setBack(temp);
    setHasChangeData(true);
  };

  useEffect(() => {
    if (data && data.length) {
      setDataSource(data);
    }
  }, [data]);

  return (
    <div className={className + " crud-buttons"}>
      {metadata && customList && (
        customList.map((item, index) => {
          const getValue = item.onClick
          if (typeof getValue === "object" && getValue?.pathname) {
            return (
              <Link className={`mr-1 btn btn-${item.color} ` + item.className} to={() => item.onClick} key={index}>
                {item.icon && <i className={item.icon}></i>} {item.label}
              </Link>
            )
          } else {
            return (
              <Button
                key={index}
                color={item.color}
                onClick={getValue}
              >
                {item.icon && <i className={item.icon}></i>} {item.label}
              </Button>
            )
          }
        })
      )}
      {metadata && createBtn && createBtn.active && createBtn.component && (
        <CreateModal
          disabled={!accessGrant.includes('add')}
          className={createBtn.className}
          classNameModal={createBtn.classNameModal}
          dataSource={createBtn.dataSource}
          uiConfigs={createBtn.uiConfigs}
          component={createBtn.component}
          transform2BE={createBtn.transform2BE}
          api={createBtn.api}
          headers={createBtn.headers}
          handleResponseData={createBtn.handleResponseData}
          whenClose={(newRow) => {
            // newRow.id = Date.now();
            newRow.isChecked = false;
            const temp = dataSource || [];
            const displayStratetry =
              typeof metadata.displayStratetry === "function"
                ? metadata.displayStratetry([newRow, ...temp])
                : [newRow, ...temp];
            setBack(displayStratetry);
            setDataSource(displayStratetry);
            console.log(
              "==============================displayStratetry===============================",
              displayStratetry
            );
            setHasChangeData(true);
            if (typeof createBtn.callback === "function") {
              createBtn.callback(newRow);
            }
          }}
          buttons={metadata.crudButtons.create.buttons}
        />
      )}
      {metadata &&
        metadata &&
        updateListBtn &&
        (updateListBtn.active || updateListBtn.props) && (
          <Fragment>
            <Button
              color="success"
              disabled={
                !updateListBtn.dataBinding || !updateListBtn.dataBinding.length
              }
              onClick={() => {
                setOpenUpdateListModal(true);
              }}
            >
              <i className="fa fa-edit mr5"></i>Cập nhật
            </Button>
            <Modal isOpen={openUpdateListModal} className="update-list-modal">
              <ModalHeader>Thông báo</ModalHeader>
              <ModalBody>Bạn có chắc chắn muốn cập nhật dữ liệu?</ModalBody>
              <ModalFooter>
                <Button
                  onClick={() => {
                    setOpenUpdateListModal(false);
                    proxyService
                      .put(updateListBtn.api, updateListBtn.dataBinding)
                      .then((result) => {
                        if (
                          result.data &&
                          typeof updateListBtn.callback === "function"
                        ) {
                          updateListBtn.callback(result.data);
                        }
                      });
                  }}
                  color="success"
                >
                  <i className="fa fa-edit mr5"></i> Cập nhật
                </Button>
                <Button
                  color="secondary"
                  onClick={() => {
                    setOpenUpdateListModal(false);
                  }}
                >
                  <i className="fas fa-times mr5"></i> Đóng
                </Button>
              </ModalFooter>
            </Modal>
          </Fragment>
        )}
      {metadata &&
        metadata &&
        deleteBtn &&
        (deleteBtn.active || deleteBtn.props) && (
          <Fragment>
            <Button
              color="danger"
              disabled={
                !data || data.length <= 0 || !data.some((s) => s.isChecked)
              }
              onClick={() => {
                setOpenModal(true);
              }}
            >
              <i className="fa fa-trash mr5"></i>Xóa
            </Button>
            <Modal isOpen={openModal} className="remove-modal">
              <ModalHeader>Thông báo</ModalHeader>
              <ModalBody>Bạn có chắc chắn muốn xóa dữ liệu?</ModalBody>
              <ModalFooter>
                <Button
                  onClick={() => {
                    const ids = data
                      .filter((x) => x.isChecked)
                      .map((x) => x.id);
                    setOpenModal(false);
                    proxyService
                      .multipleDeleteAPI(deleteBtn.api, ids, deleteBtn.params)
                      .then((result) => {
                        removeCheckedItem();
                        if (
                          result.data &&
                          typeof deleteBtn.callback === "function"
                        ) {
                          deleteBtn.callback();
                        }
                      });
                    // deleteFromAPI(ids,deleteBtn.api)
                    // removeCheckedItem();
                  }}
                  color="danger"
                >
                  <i className="fa fa-trash mr5"></i> Xóa
                </Button>
                <Button
                  color="secondary"
                  onClick={() => {
                    setOpenModal(false);
                  }}
                >
                  <i className="fas fa-times mr5"></i> Đóng
                </Button>
              </ModalFooter>
            </Modal>
          </Fragment>
        )}
    </div>
  );
};
export { CrudButtons };
