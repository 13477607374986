import React from "react";

const AccordionContent = ({ active, component }) => {
  return (
    <div className={`accordion-item ${!active ? "collapsed" : ""}`}>
      <div className="accordion-content text-dark">
        <ul className="list-group list-group-flush">{component}</ul>
      </div>
    </div>
  );
};

export default AccordionContent;
