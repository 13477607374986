import React, { useState, Fragment, useRef } from "react";
import moment from "moment";
import Select from "react-select";
//Commons
import DataTable from "../../../../commons/components/table/DataTable";
// Import Highcharts
import Highcharts from "highcharts";
import drilldown from "highcharts/modules/drilldown.js";
import HighchartsReact from "highcharts-react-official";
//const
import { ExportCSV } from "../../../../commons/components/export-excel/ExportCSV";
import { DatePickerTools } from "../../../../commons/components/date-picker/DatePickerTools";
//type
import { WebviewTypes } from "../../webview/type";
//service
import Util from "../../../../utilities/services/utils/utils.service";
import webviewServices from "../../../../utilities/services/webview";

import { cacheService } from "../../../../utilities/services";
import { LOCAL_STORAGE_USER_PARTICIPANTID } from "../../../../constants";
import { CO_SO_SAN_XUAT_GIONG } from "../../../../constants/shrimp-manage/ParticipantRole";
import { ParticipantService } from "./../../../../utilities/services/participants/ParticipantService";

drilldown(Highcharts);

const UNIT = "con";

const metadataChart = {
  credits: {
    enabled: false,
  },
  chart: {
    type: "column",
  },
  title: {
    text: "",
  },
  subtitle: {
    text: "",
  },
  accessibility: {
    announceNewData: {
      enabled: true,
    },
  },
  xAxis: {
    type: "category",
  },
  yAxis: {
    title: {
      text: UNIT,
    },
  },
  legend: {
    enabled: false,
  },

  plotOptions: {
    series: {
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        // format: "{point.y:,.0f}",
        formatter: function () {
          return Util.FormatCurrency(this.y) + " " + UNIT;
        },
      },
    },
  },

  tooltip: {
    headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
    pointFormatter: function () {
      return '<span style="color:' + this.color + '">' + this.name + "</span>: <b>" + Util.FormatCurrency(this.y) + " " + UNIT;
    },
  },

  series: [
    {
      name: "Tổng sản lượng cung ứng",
      colorByPoint: true,
      data: [],
    },
  ],
  drilldown: {
    breadcrumbs: {
      position: {
        align: "right",
      },
    },
    series: [],
  },
};

const metadataTable = {
  table: {
    selectionBoxs: { dataField: "isChecked" },
    // pagination: true,
    columns: [
      {
        name: "STT",
        width: "50",
        dataAlign: "center",
        dataFormat: (cell, row) => {
          if (!(row?.groupId || "").includes("00000000-0000-0000-0000-000000000000")) {
            return <span className="fw-5">{row.STT || ""}</span>;
          }
          return <span>{row.STT || ""}</span>;
        },
      },
      {
        isKey: true,
        name: "Nhóm, tổ",
        dataField: "name",
        width: "150",
        dataFormat: (cell, row) => {
          if (!(row?.groupId || "").includes("00000000-0000-0000-0000-000000000000")) {
            return <span className="fw-5">&nbsp;{row.name || ""}</span>;
          }
          return <span className="pl-3">{row.name || ""}</span>;
        },
      },
      {
        name: "Số lượng (" + UNIT + ")",
        dataField: "quantity",
        width: "150",
        dataAlign: "center",
        dataFormat: (cell, row) => {
          if (!(row?.groupId || "").includes("00000000-0000-0000-0000-000000000000")) {
            return <span className="fw-5">{Util.FormatCurrency(row.quantity)}</span>;
          }
          return <span>{Util.FormatCurrency(row.quantity)}</span>;
        },
      },
    ],
    tableStyles: {
      striped: false,
      hover: true,
      condensed: false,
    },
  },

  filterTools: {
    components: [],
  },
  crudButtons: {
    create: { active: false },
    update: { active: false },
    delete: { active: false },
  },
};

const Statistic = (props) => {
  const [options, setOptions] = useState(metadataChart);
  const [dataTable, setDataTable] = useState(null);
  const [participants, setParticipants] = useState([]);
  const timeOption = useRef(null);

  const isRoot = cacheService.get("isRoot");
  const id = cacheService.get(LOCAL_STORAGE_USER_PARTICIPANTID);
  //const { id } = useParams();

  const styleHeader = {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#123E69",
    textAlign: "left",
    marginBottom: "0",
  };
  /////////***********  Start Function-Management  ***********/////////////////
  const getDataParticipants = async (response, result = []) => {
    try {
      const param = {
        RoleName: CO_SO_SAN_XUAT_GIONG,
        Active: true,
      };
      response = await ParticipantService.getParticipant(param);
      if (response && response?.data?.items?.length) {
        result = response.data.items.map((p) => {
          return {
            value: p.id,
            label: p.name,
          };
        });
      }
      setParticipants(result);
    } catch (err) {
      console.error("getDataParticipants", err);
    }
  };

  const drilldownData = (arr, group_name) => {
    arr = [...arr].map((item) => {
      return [item.name, item.value];
    });
    return { id: group_name, name: group_name, data: arr };
  };

  const getDataChart = async (filter) => {
    try {
      timeOption.current = filter;
      let teamData = [];
      let dataTable = [];
      let quantity = 0;
      const groupIndex = ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", "XI", "XII", "XIII", "XIV", "XV"];

      const response = await webviewServices.getDataReport(
        id || filter.filterValues?.ParticipantId || props.id,
        filter.from,
        filter.to,
        WebviewTypes.hatcheryOutput
      );
      console.log("getDataChart", response.data);
      if (response) {
        const value = typeof response.data === "object" && response.data.length ? response.data : [];
        const group = value.map((v, i) => {
          let team = drilldownData(v.items || [], v.name);
          teamData.push(team);

          dataTable.push({ ...v, STT: groupIndex[i], quantity: v.value });
          v.items.forEach((item, index) =>
            dataTable.push({
              ...item,
              name: item.name,
              STT: index + 1,
              quantity: item.value,
            })
          );
          quantity += v.value;
          return { name: v.name, y: v.value, drilldown: v.name };
        });
        setOptions({
          ...options,
          series: [{ ...options.series[0], data: group }],
          drilldown: { series: teamData },
        });

        if (dataTable?.length) {
          setDataTable([{ name: "TỔNG CÁC NHÓM", quantity: quantity, stt: "" }, ...dataTable]);
        } else {
          setDataTable(null);
        }
      }
    } catch (err) {
      console.error("getDataChart", err);
    }
  };

  const defineTable = (data, quantity) => {
    const title = timeOption?.current
      ? `THỐNG KÊ CUNG ỨNG GIỐNG TỪ NGÀY ${moment(timeOption.current.from).format("DD/MM/YYYY")} ĐẾN ${moment(timeOption.current.to).format(
        "DD/MM/YYYY"
      )}`
      : "";
    let renderRows = `<tr><th colspan="4" style="text-align:'left'">TỔNG CÁC NHÓM</th><th style="text-align:'left'">${quantity}</th></tr>`;
    data.forEach((item) => {
      if (item.isGroup) {
        renderRows += `<tr>
          <th >${item.index}</th>
          <th colspan="3" style="text-align:'left'">${item.name || ""}</th>
          <th style="text-align:'left'">${item.quantity}</th>
        </tr>`;
      } else {
        renderRows += `<tr>
          <td >${item.index}</td>
          <td>${item.code || ""}</td>
          <td>${item.name || ""}</td>
          <td>${item.participantTeamName || ""}</td>
          <td style="text-align:'left'">${item.quantity}</td>
        </tr>`;
      }
    });

    return `
      <table border="1px">
          <thead>
            <tr>
              <th style="background-color:'#eaecf4'; font-size:'16px'" colspan="5">
                ${title}
              </th>
            </tr>
            <tr>
              <th>STT</th>
              <th>Mã số</th>
              <th>Cơ sở</th>
              <th>Thuộc tổ</th>
              <th>Số lượng (${UNIT})</th>
            </tr>
          </thead>
          <tbody>
            ${renderRows}
          </tbody>
      </table>`;
  };

  const getDataExport = async () => {
    try {
      const response = await webviewServices.getDataReportExcel(
        id || timeOption.current.filterValues?.ParticipantId || props.id,
        timeOption.current.from,
        timeOption.current.to,
        WebviewTypes.hatcheryOutput
      );
      if (response) {
        let dataTotal = [];
        let quantity = 0;
        const groupIndex = ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", "XI", "XII", "XIII", "XIV", "XV"];
        const groups = response.data || [];
        groups.forEach((group, i) => {
          quantity += group.value;
          dataTotal.push({
            isGroup: true,
            index: groupIndex[i],
            name: group.groupsName,
            quantity: group.value || 0,
          });
          group.farms.forEach((item, index) => {
            dataTotal.push({
              index: index + 1,
              code: item.aquacultureBranchCode,
              name: item.participantName,
              participantTeamName: item.participantTeamName,
              quantity: item.total || 0,
            });
          });
        });
        return defineTable(dataTotal, quantity);
      }
      return;
    } catch (err) {
      console.error("getDataExport", err);
    }
  };

  React.useEffect(() => {
    if (isRoot) {
      getDataParticipants();
    }
  }, []);
  /////////***********   End  Function-Management  ***********/////////////////

  return (
    <Fragment>
      <div className="mb-3">
        <DatePickerTools
          getDate={getDataChart}
          components={isRoot && [{
            name: "ParticipantId",
            label: "Cơ sở cung ứng",
            data: participants,
          }]}
        />
      </div>
      <div className="mb-3">
        <h4 style={styleHeader}>Biểu đồ thống kê cung ứng giống</h4>
        {dataTable ? <HighchartsReact highcharts={Highcharts} options={options} /> : <div className="text-info">Không có dữ liệu</div>}
      </div>

      <div className="mb-3">
        <div className="d-flex align-items-center justify-content-between">
          <h4 style={styleHeader}>Bảng biểu cung ứng giống</h4>
          {!props.webview && (dataTable ? <ExportCSV fileName={"ThongKeSanLuongCungUng"} csvData={getDataExport} /> : "")}
        </div>
        {dataTable ? (
          <DataTable className={"table table-data table-hover"} metadata={metadataTable} data={dataTable} uncheckAccessGrant={true} />
        ) : (
          <div className="text-info">Không có dữ liệu</div>
        )}
      </div>
    </Fragment>
  );
};

export default Statistic;
