/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useState } from "react";
import { Provider } from "react-redux";
import { createStore, combineReducers } from "redux";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { CrudButtons } from "./CrudButtons";
import FilterTools from "./FilterTools";
import UpdateModal from "./UpdateModal";
import dtActions from "../../../actions/datatable/datatable.action";
import proxyService from "../../../utilities/services/proxy/proxy.service";
import datatableReducer from "../../../reducers/datatable/datatableReducer";
import cacheService from "../../../utilities/services/cache/cache.service";
import menuService from "../../../utilities/services/menu-management/menuService";
import { LOCAL_STORAGE_USER_INFO, ROOT } from "../../../constants";
const root =
  cacheService.get(LOCAL_STORAGE_USER_INFO)?.role === ROOT ? true : false;

function datatableStore() {
  return createStore(combineReducers({ datatableReducer }));
}
const store = datatableStore();
const DataTable = ({
  metadata,
  data,
  setBack,
  dataSource,
  className,
  colFilterTools,
  colCrudButtons,
  url,
  uncheckAccessGrant,
}) => {
  // const socketState = useSelector(state => state.socketReducer, shallowEqual)
  const dispatch = useCallback((action) => {
    store.dispatch(action);
    return store.getState().datatableReducer;
  }, []);
  console.log(`store.getState().datatableReducer`, store.getState().datatableReducer);
  const serverSideMode = !!metadata.serverSide;
  const cacheFiltering = serverSideMode && metadata.serverSide.cacheFiltering;

  const [isCheckedAll, setIsCheckedAll] = useState(false);
  /* 
  Lưu trạng thái khi dữ liệu bị thay đổi 
  */
  const [hasChangeData, setHasChangeData] = useState();
  // const [dataSourceState, setDataSourceState] = useState();
  /* 
  Lưu toàn bộ dữ liệu của bảng
  */
  const [dataBinding, setDataBinding] = useState([]);
  const [reload, setReload] = useState([]);
  const [accessGrant, setAccessGrant] = useState([]);

  const { pagination, columns, tableStyles } = metadata.table;

  /*
  Chọn toàn bộ dòng trong bảng
  */
  const checkAll = (e) => {
    if (serverSideMode) {
      setIsCheckedAll(e.target.checked);
      setDataBinding(
        dataBinding.map((item) => {
          if (!item?.lock) item.isChecked = e.target.checked;
          return item;
        })
      );
    } else {
      setDataBinding(
        dataBinding.map((item) => {
          if (!item?.lock) item.isChecked = e.target.checked;
          return item;
        })
      );
    }
  };

  const pageChange = useCallback(
    (current, size) => {
      proxyService
        .get(
          metadata.serverSide.api +
          dispatch(dtActions.pageChange(current, size)).query
        )
        .then((result) => {
          store.dispatch(dtActions.updateTotalRows(result.data.totalCount));
          const transformed = metadata.serverSide.transformStrategy(
            result.data.items
          );
          if (metadata.crudButtons.delete.active) {
            setDataBinding(
              transformed.map((x) => {
                return { ...x, isChecked: isCheckedAll };
              })
            );
          } else {
            setDataBinding(transformed);
          }
        });
    },
    [
      dispatch,
      isCheckedAll,
      metadata.crudButtons.delete.active,
      metadata.serverSide,
    ]
  );
  /* 
  Chọn từng dòng trong bảng 
  */
  const singleCheck = (e, row) => {
    if (serverSideMode) {
      setDataBinding(
        dataBinding.map((item) => {
          if (row.id === item.id) {
            item.isChecked = e.target.checked;
          }
          return item;
        })
      );
    } else {
      setDataBinding(
        dataBinding.map((item) => {
          if (row.id === item.id) {
            item.isChecked = e.target.checked;
          }
          return item;
        })
      );
    }
  };
  /* 
  Định dạng ô chọn trong bảng 
  */
  function checkBoxFmt(cell, row) {
    if (!row?.lock)
      return (
        <input
          disabled={!accessGrant.includes("delete")}
          type="checkbox"
          checked={row.isChecked ?? false}
          onChange={(e) => {
            accessGrant.includes("delete") && singleCheck(e, row);
          }}
        />
      );
  }
  /* 
  Hiển thị icon sắp xếp trên 'thead' 
  */
  function getCaret(direction) {
    if (direction === "asc") {
      return (
        <span className="ml10">
          <i className="fas fas fa-sort-amount-up-alt"></i>
        </span>
      );
    }
    if (direction === "desc") {
      return (
        <span className="ml10">
          <i className="fas fas fa-sort-amount-down-alt"></i>
        </span>
      );
    }
    return (
      <span className="ml10">
        <i className="fas fa-arrows-alt-v"></i>
      </span>
    );
  }

  useEffect(() => {
    let DEFAULT_QUERY = "?SkipCount=0&MaxResultCount=25";
    if (url && url?.query) {
      console.log("url",url.query)
      for (const [key, value] of Object.entries(url.query)) {
        if (value !== null && value !== undefined && value !== "") {
          DEFAULT_QUERY += `&${key}=${value}`;
        }
      }
      dispatch({ type: "OPTION_QUERY", payload: url.query });
    }
    console.log("DEFAULT_QUERY", DEFAULT_QUERY);

    if (serverSideMode) {
      let query = "";
      if (cacheFiltering) {
        dispatch(dtActions.updateCacheMode(true));
        const cache = cacheService.get(document.location.pathname);
        query = cache ? cache.query : DEFAULT_QUERY;
      } else {
        query = DEFAULT_QUERY;
      }
      // console.log('query ', query);
      proxyService.get(metadata.serverSide.api + query).then((result) => {
        store.dispatch(dtActions.updateTotalRows(result.data.totalCount));
        const transformed = metadata.serverSide.transformStrategy(
          result.data.items
        );
        if (metadata.crudButtons.delete.active) {
          setDataBinding(
            transformed.map((x) => {
              return { ...x, isChecked: false };
            })
          );
        } else {
          setDataBinding(transformed);
        }
      });
    } else {
      if (metadata?.crudButtons?.delete?.active) {
        setDataBinding(
          data?.map((x) => {
            return { ...x, isChecked: false };
          })
        );
      } else {
        setDataBinding(data);
      }
    }
     return () => {
      dispatch(dtActions.offFilter());
      // dispatch({ type: "OPTION_QUERY", payload: '' });
    }
  }, [
    // eslint-disable-line react-hooks/exhaustive-deps
    cacheFiltering,
    data,
    // dataSource,
    dispatch,
    metadata.crudButtons.delete.active,
    // metadata.serverSide,
    serverSideMode,
  ]);

  //Socket service
  // useEffect(() => {
  //   if (metadata.socket) {
  //     try {
  //       let dataReceive = JSON.parse(socketState.data)
  //       if (typeof dataReceive === "object") {
  //         metadata.socket.map(socket => {
  //           if (dataReceive.Type === socket.type && socket.transformData && typeof socket.transformData === "function") {
  //             let data = socket.transformData(dataBinding, dataReceive.Value)
  //             console.log("data", data)
  //             setDataBinding(data)
  //             store.dispatch(dtActions.updateTotalRows(data.length))
  //           }
  //           return socket;
  //         })
  //       }
  //     } catch (error) { }
  //   }
  // }, [socketState])

  useEffect(() => {
    getAccessGrant();
  }, []);

  const getAccessGrant = async () => {
    // console.log("uncheckAccessGrant", uncheckAccessGrant)
    if (!uncheckAccessGrant)
      if (!root) {
        let res = await menuService.getAccessGrant(window.location.pathname);
        setAccessGrant(res.data);
        console.log("role", res.data);
      } else
        setAccessGrant(["all", "readonly", "add", "edit", "delete", "approve"]);
  };

  return (
    <Provider store={store}>
      <div className={className + " row data-table"}>
        <div className="col-md-12 mb-2">
          {accessGrant && (
            <CrudButtons
              accessGrant={accessGrant}
              className={""}
              metadata={metadata}
              data={dataBinding}
              setHasChangeData={setHasChangeData}
              setBack={setDataBinding}
              reload={reload}
            />
          )}
        </div>
        <div className="col-md-12">
          <div className="card shadow mb-4">
            <div className="card-header pb-1 px-3">
              {metadata.filterTools &&
                metadata.filterTools.component == null ? (
                <FilterTools
                  store={datatableStore}
                  // className={"col-md-9"}
                  metadata={metadata}
                  inputFilterBy={columns.filter((x) => x.filterBy) || []}
                  data={dataBinding}
                  // dataSource={dataSourceState}
                  hasChangeData={hasChangeData}
                  setHasChangeData={setHasChangeData}
                  setBack={setDataBinding}
                  url={url}
                />
              ) : (
                <div className={colFilterTools || "col-md-9"}>
                  {metadata.filterTools?.component()}
                </div>
              )}
            </div>
            <div className="card-body p-3">
              <BootstrapTable
                striped={tableStyles.striped}
                hover={tableStyles.hover}
                condensed={tableStyles.condensed}
                keyField={metadata.table.id}
                data={dataBinding}
                pagination={pagination}
                remote={serverSideMode}
                expandableRow={metadata.table.isExpandableRow}
                expandComponent={metadata.table.expandComponent}
                fetchInfo={{
                  dataTotalSize: serverSideMode
                    ? store.getState().datatableReducer.totalRows
                    : dataBinding?.length,
                }}
                options={
                  serverSideMode
                    ? {
                      page: store.getState().datatableReducer.pagination
                        .current,
                      sizePerPage:
                        store.getState().datatableReducer.pagination.size,
                      sizePerPageList:
                        store.getState().datatableReducer.sizePerPageList,
                      prePage: "<",
                      nextPage: ">",
                      firstPage: "Trang đầu",
                      lastPage: "Trang cuối",
                      className: "pagination-table",
                      noDataText: "Không tìm thấy dữ liệu",
                      onPageChange: (current, size) => {
                        pageChange(current, size);
                      },
                    }
                    : {
                      page: 1,
                      prePage: "<",
                      nextPage: ">",
                      firstPage: "Trang đầu",
                      lastPage: "Trang cuối",
                      className: "pagination-table",
                      noDataText: "Không tìm thấy dữ liệu",
                    }
                }
                tableHeaderClass="mb-0"
                className={tableStyles.sticky ? "sticky-table" : ""}
                trClassName={(row, rowIndex) =>
                  row.isDataSocket ? "bg-primary text-white" : ""
                }
              >
                {metadata.crudButtons.delete.active ? (
                  <TableHeaderColumn
                    dataAlign="center"
                    headerAlign="center"
                    width="40px"
                    dataField="isChecked"
                    dataFormat={checkBoxFmt}
                    formatExtraData="isChecked"
                    tdStyle={{ background: "#fff" }}
                  >
                    <input
                      disabled={!accessGrant.includes("delete")}
                      type="checkbox"
                      checked={
                        dataBinding &&
                        dataBinding.length &&
                        dataBinding.every((x) => x.isChecked)
                      }
                      onChange={(e) => {
                        accessGrant.includes("delete") && checkAll(e);
                      }}
                    />
                  </TableHeaderColumn>
                ) : null}

                {columns.map((col) => {
                  if (
                    typeof col.dataFormatEdit === "function" &&
                    metadata.crudButtons.update &&
                    metadata.crudButtons.update.active
                  ) {
                    return (
                      <TableHeaderColumn
                        caretRender={
                          col.dataSort
                            ? getCaret
                            : () => {
                              return null;
                            }
                        }
                        dataSort={col.dataSort}
                        row={col.row ?? 0}
                        colSpan={col.colSpan ?? 1}
                        rowSpan={col.rowSpan ?? 1}
                        sort={col.sort}
                        width={col.width}
                        hidden={col.hidden}
                        key={col.name}
                        isKey={col.isKey}
                        headerAlign="center"
                        dataField={col.dataField}
                        dataAlign={col.dataAlign}
                        tdStyle={{ background: "#fff" }}
                        dataFormat={(cell, row, enumObject, index) => {
                          return (
                            <UpdateModal
                              disabled={!accessGrant.includes("edit")}
                              text={col.dataFormatEdit(cell, row, enumObject, index)}
                              key={Math.random() * 1000}
                              className={metadata.crudButtons.update.className}
                              classNameModal={
                                metadata.crudButtons.update.classNameModal
                              }
                              uiConfigs={metadata.crudButtons.update.uiConfigs}
                              data={row}
                              dataSource={
                                metadata.crudButtons.update.dataSource
                              }
                              transform2BE={
                                metadata.crudButtons.update.transform2BE
                              }
                              component={metadata.crudButtons.update.component}
                              api={metadata.crudButtons.update.api}
                              headers={metadata.crudButtons.update.headers}
                              handleResponseData={
                                metadata.crudButtons.update.handleResponseData
                              }
                              whenClose={(currentRow) => {
                                const index = dataBinding.findIndex(
                                  (x) => x.id === currentRow.id
                                );
                                const temp = dataBinding;
                                temp[index] = currentRow;
                                const displayStratetry =
                                  typeof metadata.displayStratetry ===
                                    "function"
                                    ? metadata.displayStratetry(temp)
                                    : temp;
                                setDataBinding(displayStratetry);
                                setHasChangeData(true);
                                if (
                                  typeof metadata.crudButtons.update
                                    .callback === "function"
                                ) {
                                  metadata.crudButtons.update.callback(
                                    currentRow
                                  );
                                }
                              }}
                              buttons={metadata.crudButtons.update.buttons}
                            />
                          );
                        }}
                      >
                        {col.name}
                      </TableHeaderColumn>
                    );
                  } else {
                    return (
                      <TableHeaderColumn
                        caretRender={
                          col.dataSort
                            ? getCaret
                            : () => {
                              return null;
                            }
                        }
                        dataSort={col.dataSort}
                        sort={col.sort}
                        width={col.width}
                        hidden={col.hidden}
                        key={col.name}
                        isKey={col.isKey}
                        headerAlign="center"
                        dataFormat={col.dataFormat}
                        dataField={col.dataField}
                        dataAlign={col.dataAlign}
                        row={col.row ?? 0}
                        colSpan={col.colSpan ?? 1}
                        rowSpan={col.rowSpan ?? 1}
                        tdStyle={col?.tdStyle || null}
                        tdAttr={col?.tdAttr || null}
                      >
                        {!col?.isCustomHeader
                          ? col.name
                          : col?.dataFormatHeader()}
                      </TableHeaderColumn>
                    );
                  }
                })}

                {/* Nút 'Sửa' */}
                {metadata.crudButtons.update &&
                  metadata.crudButtons.update.active && (
                    <TableHeaderColumn
                      width={"50px"}
                      className="text-center"
                      // dataAlign={metadata.crudButtons.update.dataAlign}
                      dataAlign="center"
                      dataFormat={(cell, row) => {
                        return (
                          <UpdateModal
                            disabled={!accessGrant.includes("edit")}
                            isShowIcon={true}
                            key={Math.random() * 1000}
                            className={metadata.crudButtons.update.className}
                            classNameModal={
                              metadata.crudButtons.update.classNameModal
                            }
                            uiConfigs={metadata.crudButtons.update.uiConfigs}
                            data={row}
                            dataSource={metadata.crudButtons.update.dataSource}
                            transform2BE={
                              metadata.crudButtons.update.transform2BE
                            }
                            component={metadata.crudButtons.update.component}
                            api={metadata.crudButtons.update.api}
                            headers={metadata.crudButtons.update.headers}
                            handleResponseData={
                              metadata.crudButtons.update.handleResponseData
                            }
                            whenClose={(currentRow) => {
                              const index = dataBinding.findIndex(
                                (x) => x.id === currentRow.id
                              );
                              const temp = dataBinding;
                              temp[index] = currentRow;
                              const displayStratetry =
                                typeof metadata.displayStratetry === "function"
                                  ? metadata.displayStratetry(temp)
                                  : temp;
                              setDataBinding(displayStratetry);
                              setHasChangeData(true);
                              if (
                                typeof metadata.crudButtons.update.callback ===
                                "function"
                              ) {
                                metadata.crudButtons.update.callback(
                                  currentRow
                                );
                              }
                            }}
                            buttons={metadata.crudButtons.update.buttons}
                          />
                        );
                      }}
                    >
                      {metadata.crudButtons.update.uiConfigs
                        ? metadata.crudButtons.update.uiConfigs
                          ?.headerColumeTable
                        : "Sửa"}
                    </TableHeaderColumn>
                  )}
              </BootstrapTable>
            </div>
          </div>
        </div>
      </div>
    </Provider>
  );
};

export default DataTable;
