import proxyService from "../proxy/proxy.service";

export const ShrimpFarmServices = {
  getDataShippingOrder,
  getDataDischargeManagement,
  getDataWorks,
  getDataTraceability,
  getDataChart,
  getProductionByFarmStockingId,
  postTraceabilityByLotId,
};

async function getDataShippingOrder() {
  const url = "/api/app/shrimpHatcheryExports";
  try {
    return await proxyService.get(url);
  } catch (e) {
    return null;
  }
}

async function getDataDischargeManagement() {
  const url = "/api/app/shrimpFarmStockings";
  try {
    return await proxyService.get(url);
  } catch (e) {
    return null;
  }
}

async function getDataWorks(params = null) {
  const url = "/api/app/works";
  try {
    return await proxyService.get(url, null, params);
  } catch (e) {
    return null;
  }
}

async function getDataTraceability(participantId, shrimpFarmStockingId = null) {
  const url = `/api/app/shrimpFarmDiaries/diary`;
  const params = { ParticipantId: participantId, ShrimpFarmStockingId: shrimpFarmStockingId };
  return await proxyService.get(url, null, params);
}

async function getDataChart(from, to, action) {
  let url;
  const params = {
    From: from?.toISOString() || null,
    To: to?.toISOString() || null,
  };
  if (action === "harvests") {
    url = "/api/app/shrimpFarmHarvests/statistic";
  } else {
    //action === disease
    url = "/api/app/shrimpFarmDiseases/statistic";
  }
  try {
    return await proxyService.get(url, null, params);
  } catch (e) {
    return null;
  }
}

function getProductionByFarmStockingId(id = null) {
  const url = `/api/app/farmStockings/${id}/production`;
  return proxyService.get(url);
}

async function postTraceabilityByLotId(id) {
  const url = `/api/app/traceability/${id}/byLotId`;

  return await proxyService.post(url);
};

export default ShrimpFarmServices;
