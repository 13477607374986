import Axios from 'axios';
import proxyService from '../../services/proxy/proxy.service';

export const menuService = {
  getMenuData,
  getAllMenu,
  deleteMenu,
  getAllFeatures,
  getStaticFilterData,
  getAccessGrant
}
const useDummy = false;
async function getMenuData() {
  if (useDummy) {
    return proxyService.getJSON('/assets/datas/home-screen-menu-management.json').then(async result => result);
  }
  const url = '/api/app/menuManagement/menuData';
  const result = await proxyService.get(url);

  return result;
};

async function getStaticFilterData() {
  const url = '/assets/datas/menu-static-filter-data.json';
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const result = await Axios(
    url,
    requestOptions
  );
  return result;
};

async function getAllMenu() {
  return proxyService.get('/api/app/menuManagement').then(async result => result.data);
}

async function getAllFeatures() {
  const params = {Active: true}
  return proxyService.get('/api/app/appFeatures',null, params).then(async result => result.data);
}

async function deleteMenu(menuId) {
  const url = `/api/app/menuManagement/${menuId}`;

  return await proxyService.deleteAPI(url);
}

async function getAccessGrant(stringUrl = "") {
  const url = "/api/app/menuManagement/accessGrant";
  let param = {
      url: stringUrl,
  };
  const result = await proxyService.get(url, null, param);

  return result;
}

export default menuService;