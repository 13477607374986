import proxyService from "../proxy/proxy.service";

export const ParticipantService = {
  getParticipant,
  getParticipantNotAddedToTeam,
  getParticipantFilter,
  get,
  getParticipantType,
  getByIds,
  getListUser,
};
async function getParticipant(params = null) {
  const url = "/api/app/participants/dropdown";
  const result = await proxyService.get(url, null, params);
  return result;
}

async function getParticipantNotAddedToTeam(params = null) {
  const url = "/api/app/participants/notAddedToTeam";
  const result = await proxyService.get(url, null, params);
  return result;
}

async function get(id) {
  const url = "/api/app/participants/" + id;
  const result = await proxyService.get(url);
  return result;
}

async function getParticipantFilter(params = null) {
  const url = "/api/app/participants";
  const result = await proxyService.get(url, null, params);
  return result;
}
async function getParticipantType(params = null) {
  const url = "/api/app/participants/typeParticipant";
  const result = await proxyService.get(url, null, params);
  return result;
}

async function getByIds(params = null) {
  const url = "/api/app/participants/byIds";
  const result = await proxyService.get(url, null, params);
  return result;
}
async function getListUser(id) {
  const url = "/api/app/participant/account/" + id;
  const result = await proxyService.get(url);
  return result;
}

export default ParticipantService;
