import React from "react";

const BlockchainSearchBar = ({ query, handleChangeQuery, handleSubmit }) => {
  const onChangeQuery = (e) => {
    const value = e.target.value;
    handleChangeQuery(value);
  };

  const onSubmit = () => handleSubmit();

  return (
    <div className="input-group mt-2 mb-2">
      <input type="text" className="form-control form-control"  defaultValue={query || ''} placeholder="Nhập transaction ID" onBlur={onChangeQuery} />
      <div className="input-group-append">
        <button className="btn btn-outline-primary btn-sm" type="button" onClick={onSubmit}>
          <i className="fas fa-search"></i>
        </button>
      </div>
    </div>
  );
};

export default BlockchainSearchBar;
