import React from "react";
import Util from "../../../../utilities/services/utils/utils.service";
import { Button } from "reactstrap";
import BlockchainInfo from './../traceability/modal-blockchain-detail/BlockchainInfo';

const ProductionInfo = ({ info, redirect }) => {
  console.log("ProductionInfo", info);
  const { batchCode, shrimpHatcheryCultureTankHouseName, shrimpHatcheryCultureTankName, bioTypeName, totalQuantity, startManufactureDate, endManufactureDate, hatcheryBroodstockName, hatcheryBroodstockSupplier, fatherBreeder, motherBreeder, note, blockchainDate, blockNum, transactionId, verified } = info;
  const SET_COLUMN_4 = "col-4 fw-5";
  const SET_COLUMN_8 = "col-8";
  return (
    <React.Fragment>
      <div className="d-flex flex-column mb-2 text-dark">
        <h6 className="font-weight-bold text-primary">Thông tin chung</h6>
        <div className="pl-2 pr-2">
          <div className="row">
            <div className={SET_COLUMN_4}>Mã lô:</div>
            <div className={SET_COLUMN_8}>{batchCode}</div>
          </div>
          <div className="row">
            <div className={SET_COLUMN_4}>Thuộc trại - bể:</div>
            <div className={SET_COLUMN_8}>
              {shrimpHatcheryCultureTankHouseName} - {shrimpHatcheryCultureTankName}
            </div>
          </div>
          <div className="row">
            <div className={SET_COLUMN_4}>Đối tượng:</div>
            <div className={SET_COLUMN_8}>{bioTypeName}</div>
          </div>
          <div className="row">
            <div className={SET_COLUMN_4}>Số lượng:</div>
            <div className={SET_COLUMN_8}>{Util.FormatCurrency(totalQuantity || 0)} con</div>
          </div>
          <div className="row">
            <div className={SET_COLUMN_4}>Ngày bắt đầu:</div>
            <div className={SET_COLUMN_8}>{Util.yyyymmddFormatTimeString(startManufactureDate)}</div>
          </div>
          <div className="row">
            <div className={SET_COLUMN_4}>Ngày kết thúc:</div>
            <div className={SET_COLUMN_8}>{Util.yyyymmddFormatTimeString(endManufactureDate)}</div>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column mb-2 text-dark">
        <h6 className="font-weight-bold text-primary">
          <span>Thông tin tôm bố mẹ</span>
        </h6>
        <div className="pl-2 pr-2">
          <div className="row">
            <div className={SET_COLUMN_4}>Lô tôm bố mẹ:</div>
            <div className={SET_COLUMN_8}>
              {hatcheryBroodstockName}
              <Button onClick={() => redirect(`/diaries?type=broodstockId&id=${info?.hatcheryBroodstockId}&showInfo=true`, true)} color="link" className={"p-0 ml-2 text-center"}>
                (<i className="fas fa-info-circle" /> Xem nhật ký)
              </Button>
            </div>
          </div>
          <div className="row">
            <div className={SET_COLUMN_4}>Nhà cung cấp:</div>
            <div className={SET_COLUMN_8}>{hatcheryBroodstockSupplier}</div>
          </div>
          <div className="row">
            <div className={SET_COLUMN_4}>Mã tôm bố - mẹ:</div>
            <div className={SET_COLUMN_8}>
              {fatherBreeder} - {motherBreeder}
            </div>
          </div>
          {note && (
            <div className="row">
              <div className={SET_COLUMN_4}>Ghi chú:</div>
              <div className={SET_COLUMN_8}>
                <span className="diaries-note" dangerouslySetInnerHTML={{ __html: note }} />
              </div>
            </div>
          )}
        </div>
      </div>


      {transactionId && 
        <div className="d-flex flex-column mb-2 text-dark">
          <h6 className="font-weight-bold text-primary">Thông tin Blockchain</h6>
          <div className="pl-2 pr-2">
            <BlockchainInfo dataBlock={{transactionId, blockNum, verified }} formConfigs={{ layout: "4 fw-5 |8", searchBar: "hidden" }} queryBlock={() => {
              return (
                <Button style={{ overflowWrap: "anywhere" }} onClick={() => redirect(`/traceability?type=blockchain&queryBlockchain=${transactionId}&showInfo=true`, true)} color="link" className={"p-0 text-left"}>
                    {transactionId}
                </Button>
              )
            }}/>
          </div>
      </div>}
      <h6 className="font-weight-bold text-primary">Nhật ký sản xuất</h6>
    </React.Fragment>
  );
};

export default ProductionInfo;
