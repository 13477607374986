import React from "react";
// import Select from "react-select";

export const RenderRow = ({ rowData, rowIndex, onChangeRow, webview }) => {
  //Change cell is based on column_index, delete row is based on row_index
  const handleChangeCell = (cell_value, index) => {
    rowData[index].value = cell_value;
    onChangeRow(rowData, rowIndex);
  };

  const handleRemoveRow = () => {
    onChangeRow(null, rowIndex, "remove");
  };

  return (
    <>
      <tr>
        {[...rowData].map((cell, col_index) => {
          //     <td key={cell.id}>
          //       <Select menuPosition="fixed" name="A" options={col_index === 1 ? dropdown[0] : dropdown[1]} defaultValue={cell.value} placeholder="Chọn" onChange={(selectedOption) => handleChangeCell(selectedOption, col_index)} />
          //     </td>
          return (
            <td key={cell?.id || Math.random() * 2} contentEditable={!webview} suppressContentEditableWarning={!webview} onBlur={(e) => handleChangeCell(e.target.textContent, col_index)}>
              {cell?.value?.label || cell?.value}
            </td>
          );
        })}
        {!webview && (
          <td>
            <button className="btn btn-sm btn-danger" type="button" onClick={handleRemoveRow}>
              <i className="fas fa-trash-alt"></i>
            </button>
          </td>
        )}
      </tr>
    </>
  );
};
