import React from "react";
import { history } from "../../../../utilities/helpers/history";
import Util from "../../../../utilities/services/utils/utils.service";
import { WebviewTypes } from "../type";
import QRCode from "qrcode.react";
import logoLoginImg from "../../../../assets/images/icon-blockchain.png";
import { useLocation } from "react-router-dom";
import ModalBlockchain from "./modal-blockchain-detail/ModalBlockchain";
import ModalParticipantDetail from "./modal-participant-detail/ModalParticipantDetail";
import { Button } from "reactstrap";
import BlockchainInfo from "./modal-blockchain-detail/BlockchainInfo";

const TraceabilityProcessingFactory = ({ title, value, showDetail, showImage, type }) => {
  const search = useLocation().search;
  const participantGroupsId = title === "farmGroups" ? [...new Set(value.map((x) => x.groupId))].join(",") : "";
  // console.log(title, ":", value, typeof value);
  const SET_COLUMN_4 = "col-4 fw-5";
  const SET_COLUMN_5 = "col-5 fw-5";
  const SET_COLUMN_7 = "col-7";

  const checkVerificationStatusBlockchain = async (id, api) => {
    try {
      const response = await Util.getVerificationStatusInBlockchain(id, api);
      if (response && response.data) {
        return response.data
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleRedirectWebview = (path = null, goback = "") => {
    if (path) {
      history.push(path + `&goback=${goback}`);
    }
  };

  return (
    <React.Fragment>
      {title === "hatcherys" &&
        value.map((v, index) => (
          <React.Fragment key={index}>
            <li className="list-group-item text-center title-content">
              <p className="fw-6">
                {v.name} {!v.isForeign ? <i className="fas fa-check-circle text-success" /> : ""}
              </p>
              <p className="fw-4">{v.address}</p>
              {<ModalParticipantDetail participantId={v.id} />}
            </li>
          </React.Fragment>
        ))}

      {title === "farmGroups" && (
        <React.Fragment>
          {value && value.length ? (
            <li className="list-group-item text-center title-content">
              {value.map((v, index) => {
                return (
                  <React.Fragment key={index}>
                    <p className="fw-6">{v?.groupName || ""}</p>
                    <p>{v?.groupNote}</p>
                    <p className="fw-4">{v?.address || ""}</p>
                  </React.Fragment>
                );
              })}
              <button className="btn btn-primary bg-info  w-50" type="button" onClick={() => history.push(`/farm-harvest-detail?participantGroupsId=${participantGroupsId}${!value[0]?.transportId.includes("00000000-0000-0000-0000-000000000000") ? "&transportId=" + value[0].transportId : ""}`)}>
                <i className="fas fa-users"></i>&nbsp; Truy xuất nhóm
              </button>
            </li>
          ) : null}
        </React.Fragment>
      )}

      {title === "transport" && (
        <>
          <li className="list-group-item text-center title-content">
            <p className="fw-6"> {value.name ? "Cơ sở thu mua - vận chuyển " + value.name : ""} </p>
            <p className="fw-4">{value.address || ""}</p>
            <ModalParticipantDetail participantId={value.id} />
          </li>

          <li className="list-group-item ">
            <div className="d-flex align-items-center justify-content-between" style={{ position: "relative" }}>
              <label className="fw-5 mb-0">Vận chuyển ngày {Util.yyyymmddFormatTimeString(value?.purchasingTransportDto?.transportDate)}</label>
              <div className="d-flex align-items-center">{value?.purchasingTransportDto?.files?.length ? showImage(value.purchasingTransportDto.files) : null}</div>
            </div>
            <div className="d-flex flex-column pl-2">
              {value?.purchasingTransportDto?.vehicleName && (
                <div className="row">
                  <div className={SET_COLUMN_5}>Phương tiện:</div>
                  <div className={SET_COLUMN_7}>{value.purchasingTransportDto?.vehicleName}</div>
                </div>
              )}
              {value?.purchasingTransportDto?.workDetailIds &&
                value?.purchasingTransportDto?.workDetailIds.map((w, i) => {
                  return (
                    <div className="row" key={i}>
                      <div className={SET_COLUMN_5}>{w.workDetailName}:</div>
                      <div className={SET_COLUMN_7}>{w.note ? w.note : ""}</div>
                    </div>
                  );
                })}
            </div>
          </li>

          <li className="list-group-item ">
            <React.Fragment>
              {type === WebviewTypes.transportLotId ? (
                <React.Fragment>
                  <div className="d-flex align-items-center justify-content-between" style={{ position: "relative" }}>
                    <label className="fw-5 mb-0">Thời gian - công việc</label>
                    <div className="d-flex align-items-center">{value?.purchasingTransportDto?.files?.length ? showImage(value.purchasingTransportDto.files) : null}</div>
                  </div>
                  <div role="diaries" aria-labelledby="" tabIndex="0">
                    <table id="table-diary" className="table-bordered table-condensed text-center text-dark w-100" style={{ fontSize: 14 }}>
                      <thead>
                        <tr>
                          <th>Công việc</th>
                          <th>Thời gian</th>
                          <th colSpan="3">T° bảo quản (°C)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-left">Bắt đầu thu hoạch</td>
                          <td>{value?.startHarvest?.time}</td>
                          <td>{value?.startHarvest?.tempL1}</td>
                          <td>{value?.startHarvest?.tempL2}</td>
                          <td>{value?.startHarvest?.tempL3}</td>
                        </tr>
                        <tr>
                          <td className="text-left">Kết thúc thu hoạch</td>
                          <td>{value?.endHarvest?.time}</td>
                          <td>{value?.endHarvest?.tempL1}</td>
                          <td>{value?.endHarvest?.tempL2}</td>
                          <td>{value?.endHarvest?.tempL3}</td>
                        </tr>
                        <tr>
                          <td className="text-left">Bắt đầu thu mua</td>
                          <td>{value?.startPurchase?.time}</td>
                          <td>{value?.startPurchase?.tempL1}</td>
                          <td>{value?.startPurchase?.tempL2}</td>
                          <td>{value?.startPurchase?.tempL3}</td>
                        </tr>
                        <tr>
                          <td className="text-left">Kết thúc thu mua</td>
                          <td>{value?.endPurchase?.time}</td>
                          <td>{value?.endPurchase?.tempL1}</td>
                          <td>{value?.endPurchase?.tempL2}</td>
                          <td>{value?.endPurchase?.tempL3}</td>
                        </tr>
                        <tr>
                          <td className="text-left">Bắt đầu vận chuyển</td>
                          <td>{value.startTransport?.time}</td>
                          <td>{value?.startTransport?.tempL1}</td>
                          <td>{value?.startTransport?.tempL2}</td>
                          <td>{value?.startTransport?.tempL3}</td>
                        </tr>
                        <tr>
                          <td className="text-left">Đến nhà máy chế biến</td>
                          <td>{value.endTransport?.time}</td>
                          <td>{value?.endTransport?.tempL1}</td>
                          <td>{value?.endTransport?.tempL2}</td>
                          <td>{value?.endTransport?.tempL3}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {value?.lotTransport?.length > 1 ? (
                    <React.Fragment>
                      <div className="d-flex align-items-center justify-content-between" style={{ position: "relative" }}>
                        <label className="fw-5 mb-0">Lô nguyên liệu: {value.lotCode}</label>
                        <div className="d-flex align-items-center">{value?.purchasingTransportDto?.files?.length ? showImage(value.purchasingTransportDto.files) : null}</div>
                      </div>
                      {value.lotTransport.map((x, index) => {
                        return (
                          <React.Fragment key={index}>
                            <div className="d-flex align-items-center justify-content-between" style={{ position: "relative" }}>
                              <label className="fw-5 mb-0">{x.lotName}</label>
                              <div className="d-flex align-items-center">{value?.purchasingTransportDto?.files?.length ? showImage(value.purchasingTransportDto.files) : null}</div>
                            </div>
                            <div role="diaries" aria-labelledby="" tabIndex="0">
                              <table id="table-diary" className="table-bordered table-condensed text-center text-dark w-100" style={{ fontSize: 14 }}>
                                <thead>
                                  <tr>
                                    <th>Công việc</th>
                                    <th>Thời gian</th>
                                    <th colSpan="3">T° bảo quản (°C)</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="text-left">Kết thúc thu mua</td>
                                    <td>{x?.endPurchase?.time}</td>
                                    <td>{x?.endPurchase?.tempL1}</td>
                                    <td>{x?.endPurchase?.tempL2}</td>
                                    <td>{x?.endPurchase?.tempL3}</td>
                                  </tr>
                                  <tr>
                                    <td className="text-left">Bắt đầu vận chuyển</td>
                                    <td>{x.startTransport?.time}</td>
                                    <td>{x?.startTransport?.tempL1}</td>
                                    <td>{x?.startTransport?.tempL2}</td>
                                    <td>{x?.startTransport?.tempL3}</td>
                                  </tr>
                                  <tr>
                                    <td className="text-left">Đến nhà máy chế biến</td>
                                    <td>{x.endTransport?.time}</td>
                                    <td>{x?.endTransport?.tempL1}</td>
                                    <td>{x?.endTransport?.tempL2}</td>
                                    <td>{x?.endTransport?.tempL3}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </React.Fragment>
                  ) : value?.lotTransport?.length === 1 ? (
                    value.lotTransport.map((x, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div className="d-flex align-items-center justify-content-between" style={{ position: "relative" }}>
                            <label className="fw-5 mb-0">{value.lotCode}</label>
                            <div className="d-flex align-items-center">{value?.purchasingTransportDto?.files?.length ? showImage(value.purchasingTransportDto.files) : null}</div>
                          </div>
                          <div role="diaries" aria-labelledby="" tabIndex="0">
                            <table id="table-diary" className="table-bordered table-condensed text-center text-dark w-100" style={{ fontSize: 14 }}>
                              <thead>
                                <tr>
                                  <th>Công việc</th>
                                  <th>Thời gian</th>
                                  <th colSpan="3">T° bảo quản (°C)</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="text-left">Kết thúc thu mua</td>
                                  <td>{x?.endPurchase?.time}</td>
                                  <td>{x?.endPurchase?.tempL1}</td>
                                  <td>{x?.endPurchase?.tempL2}</td>
                                  <td>{x?.endPurchase?.tempL3}</td>
                                </tr>
                                <tr>
                                  <td className="text-left">Bắt đầu vận chuyển</td>
                                  <td>{x.startTransport?.time}</td>
                                  <td>{x?.startTransport?.tempL1}</td>
                                  <td>{x?.startTransport?.tempL2}</td>
                                  <td>{x?.startTransport?.tempL3}</td>
                                </tr>
                                <tr>
                                  <td className="text-left">Đến nhà máy chế biến</td>
                                  <td>{x.endTransport?.time}</td>
                                  <td>{x?.endTransport?.tempL1}</td>
                                  <td>{x?.endTransport?.tempL2}</td>
                                  <td>{x?.endTransport?.tempL3}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </React.Fragment>
                      );
                    })
                  ) : null}
                </React.Fragment>
              )}
            </React.Fragment>
          </li>
          {value?.purchasingTransportDto?.transactionId && (
            <li className="list-group-item ">
              <div className="d-flex flex-column mb-2 text-dark">
                <h6 className="fw-5 text-dark">Thông tin Blockchain</h6>
                <div className="pl-2 pr-2">
                  <BlockchainInfo
                    // id={value.purchasingTransportDto.transactionId}
                    dataBlock={{ transactionId: value.purchasingTransportDto.transactionId, blockNum: value.purchasingTransportDto.blockNum, verified: value.purchasingTransportDto.verified }}
                    formConfigs={{ layout: "4 fw-5 |8", searchBar: "hidden"}}
                    queryBlock={() => {
                      return (
                        <Button style={{ overflowWrap: "anywhere" }} onClick={() => handleRedirectWebview(`/traceability?type=blockchain&queryBlockchain=${value.purchasingTransportDto.transactionId}&showInfo=true`, true)} color="link" className={"p-0 text-left"}>
                          {value.purchasingTransportDto.transactionId}
                        </Button>
                      );
                    }}
                  />
                </div>
              </div>
            </li>
          )}
        </>
      )}

      {title === "processing" && (
        <>
          <li className="list-group-item text-center title-content">
            <p className="fw-6">
              {value.name} {!value.isForeign ? <i className="fas fa-check-circle text-success" /> : ""}
            </p>
            <p className="fw-4">{value.address}</p>
            <ModalParticipantDetail participantId={value.id} />
          </li>
          <li className="list-group-item ">
            <div className="d-flex align-items-center justify-content-between" style={{ position: "relative" }}>
              <label className="fw-5 mb-0">Thông tin sản phẩm</label>
              <div className="d-flex align-items-center">{value.lotInfo?.files?.length ? showImage(value.lotInfo.files) : null}</div>
            </div>
            <div className="d-flex flex-column pl-2">
              <div className="row">
                <div className={SET_COLUMN_4}>Lô sản xuất:</div>
                <div className={SET_COLUMN_7}>{value.lotCode}</div>
              </div>
              <div className="row">
                <div className={SET_COLUMN_4}>Ngày sản xuất:</div>
                <div className={SET_COLUMN_7}>{Util.yyyymmddFormatTimeString(value.lotInfo.processingDate)}</div>
              </div>
              {value.lotInfo.packagingLot && (
                <div className="row">
                  <div className={SET_COLUMN_4}>Mã lô bao bì:</div>
                  <div className={SET_COLUMN_7}>{value.lotInfo.packagingLot}</div>
                </div>
              )}
              {value.lotInfo.lotProduct && (
                <div className="row">
                  <div className={SET_COLUMN_4}>Mã sản phẩm:</div>
                  <div className={SET_COLUMN_7}>{value.lotInfo.lotProduct}</div>
                </div>
              )}

              <span>
                <div role="diaries" aria-labelledby="" tabIndex="0">
                  <table id="table-diary" className="table-bordered table-condensed text-center text-dark w-100" style={{ fontSize: 14 }}>
                    <thead>
                      <tr>
                        <th>Sản phẩm</th>
                        <th>Kích cỡ</th>
                        <th>Trọng lượng</th>
                        <th>Khách hàng</th>
                      </tr>
                    </thead>
                    <tbody>
                      {value?.lotInfo?.productsDto?.length
                        ? value.lotInfo.productsDto.map((p, i) => {
                          return (
                            <tr key={i}>
                              <td>{p.productName}</td>
                              <td>{p.size} con/kg</td>
                              <td>{p.weight} kg</td>
                              <td>{p.customer}</td>
                            </tr>
                          );
                        })
                        : null}
                    </tbody>
                  </table>
                </div>
              </span>
            </div>
          </li>
          <li className="list-group-item ">
            <label className="fw-5 mb-0">Quy trình sản xuất</label>
            <div className="d-flex flex-column pl-2">
              {value?.lotInfo?.jobs?.length
                ? value.lotInfo.jobs.map((j, index) => {
                  return (
                    <div className="row" key={index}>
                      <div className={SET_COLUMN_5}>{j.name}:</div>
                      <div className={SET_COLUMN_7}>{j.note}</div>
                    </div>
                  );
                })
                : null}
            </div>
            {/* <div className="text-right">{showDetail()}</div> */}
          </li>
          {value?.lotInfo?.transactionId && (
            <li className="list-group-item ">
              <div className="d-flex flex-column mb-2 text-dark">
                <h6 className="fw-5 text-dark">Thông tin Blockchain</h6>
                <div className="pl-2 pr-2">
                  <BlockchainInfo
                    // id={value.lotInfo.transactionId}
                    dataBlock={{ transactionId: value.lotInfo.transactionId, blockNum: value.lotInfo.blockNum, verified: value.lotInfo.verified }}
                    formConfigs={{ layout: "4 fw-5 |8", searchBar: "hidden"}}
                    queryBlock={() => {
                      return (
                        <Button style={{ overflowWrap: "anywhere" }} onClick={() => handleRedirectWebview(`/traceability?type=blockchain&queryBlockchain=${value.lotInfo.transactionId}&showInfo=true`, true)} color="link" className={"p-0 text-left"}>
                          {value.lotInfo.transactionId}
                        </Button>
                      );
                    }}
                  />
                </div>
              </div>
            </li>
          )}

          {/* <li className="list-group-item">
            <label className="fw-5 mb-0">Các giấy chứng nhận và chứng chỉ</label>
            <div className="text-right">{showDetail()}</div>
          </li> */}
        </>
      )}

      {title === "blockchain" && (
        <React.Fragment>
          <li className="list-group-item title-content">
            {value.transactionId ? (
              <div className="d-flex flex-column">
                <div className="text-center mb-3">
                  <img src={logoLoginImg} className="w-25" alt="logo" />
                  <h4 className="fw-5 mt-2">TÔM SINH THÁI CÀ MAU</h4>
                </div>
                <div className="text-center">
                  <QRCode size={290} value={window.location.origin + "/traceability" + search} />
                </div>
                {value?.lotProduct && <h3 className="fw-5 text-center mt-2 mb-2">{value.lotProduct}</h3>}

                {/* <div className="text-center">
                  <div className="fw-5">Transaction ID:</div>
                  <span style={{ overflowWrap: "break-word" }}>
                    {value.transactionId}
                    {value.transactionId.match(/.{1,22}/g).map((v, i) => (
                    <React.Fragment key={i}>
                      {v}
                      <br />
                    </React.Fragment>
                  ))}
                  </span>
                </div> */}
                {/* {<ModalBlockchain transactionId={value.transactionId} />} */}
              </div>
            ) : (
              <div className="text-info text-center">Chưa có thông tin!</div>
            )}
          </li>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default TraceabilityProcessingFactory;
