import React, { useState } from "react";
import { useDispatch } from "react-redux";
import loginBackground from "../../assets/images/loginBackground.png";
import loginBackgroundTrans from "../../assets/images/loginBackgroundTransparent.png";
import loginForm from "../../assets/images/loginForm.png";
import loginIcon from "../../assets/images/login-icon-txng.svg";
import { history } from "../../utilities/helpers/index";
import { loginActions } from "../../actions/login-actions";
import tokenService from "../../utilities/services/token/token.service";
import authenticateService from "../../utilities/services/authenticate/authenticate.service";
import accountManagementService from "../../utilities/services/user/info/accountManagement.service";
import { cacheService } from "../../utilities/services";
import { LOCAL_STORAGE_USER_INFO, LOCAL_STORAGE_USER_PARTICIPANTID } from "../../constants/index";
import loadingAction from "../../actions/loading/loading.action";
export class LoginResponse {
  accessToken;
  encryptedAccessToken;
  expireInSeconds;
  userId;
}
const Login = () => {
  const [inputs, setInputs] = useState({
    username: "",
    password: "",
  });

  const [submitted, setSubmitted] = useState(false);
  const [invalidPass, setInvalidPass] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const { username, password } = inputs;
  const dispatch = useDispatch();
  const sidebarBackground = {
    backgroundImage: "url(" + loginBackground + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    minHeight: "100vh",
    minWidth: "100vw",
    position: "relative",
    overflow: "hidden",
  };

  const LoginBg = {
    backgroundImage: "url(" + loginBackgroundTrans + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
  };
  const LoginForm = {
    backgroundImage: "url(" + loginForm + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
  };
  function base64ToArray(base64String) {
    var padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    var base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
    var rawData = window.atob(base64);

    return rawData;
  }
  const inputStyle = {
    borderRadius: "0 .35rem .35rem 0",
  };

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));

    console.log("name", name);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    if (username && password) {
      authenticateService
        .authenticate(username, password)
        .then((result) => {
          if (result === "Accepted") setAccepted(true);
          tokenService.storeToken(result.accessToken);

          const tokenDecode = decodeURIComponent(
            // escape(window.atob(result.accessToken?.split(".")[1]))
            escape(base64ToArray(result.accessToken?.split(".")[1]))

            // atob(result.accessToken?.split(".")[1])
          );
          console.log("handleSubmit", result);
          dispatch(loginActions.login(tokenDecode));
          let tokenJson = JSON.parse(tokenDecode);
          cacheService.set(LOCAL_STORAGE_USER_INFO, { ...tokenJson, password: password });
          cacheService.set(LOCAL_STORAGE_USER_PARTICIPANTID, result.participantId);
          cacheService.set(LOCAL_STORAGE_USER_PARTICIPANTID, result.participantId);
          cacheService.set("UserRole", result.roles);
          let [setRole] = result.roles;
          console.log("sdasdas", setRole, typeof setRole);
          cacheService.set("isRoot", setRole === "ROOT" || setRole === "CHUYEN_VIEN" || setRole === "ADMIN" ? true : false);
          cacheService.set("RoleIds", result.roleIds);
          console.log("token", JSON.parse(tokenDecode));
          // history.push("/home");
          window.location.href = "/home";
        })
        .catch((err) => {
          console.log("err", err);
          dispatch(loginActions.loginFailed(err));
          dispatch(loadingAction.close());
          setInvalidPass(true);
        });
    }
  }

  return (
    <div style={sidebarBackground}>
      <div className="login-content" style={LoginBg}>
        <div className="login-header">
          <div className="content-header">
            <div className="col-lg-12  left-header-content">
              <img src={loginIcon} alt="" />
              <h3
                className="text-uppercase fw-7"
                style={{
                  letterSpacing: ".5px",
                }}
              >
                TRUY XUẤT NGUỒN GỐC TÔM SINH THÁI
              </h3>
            </div>
          </div>
        </div>
        <div className="login-content-body" style={LoginForm}>
          {/* <p className="login-title">Đăng nhập hệ thống</p> */}
          <h5 className="fw-5 text-uppercase text-center" style={{ marginBottom: "1rem", color: "#406b46" }}>
            Đăng nhập hệ thống
          </h5>
          <form name="form" onSubmit={handleSubmit}>
            <div className="form-group">
              <div className="login-group-input">
                <i className="login-icon-user ti-user"></i>
                <input
                  placeholder="Tên Đăng nhập"
                  style={inputStyle}
                  type="text"
                  name="username"
                  value={username}
                  onChange={handleChange}
                  className={"form-control" + (submitted && !username ? " is-invalid" : "")}
                />
              </div>
              <div className="login-error">{submitted && !username && <div>Tên đăng nhập là bất buộc</div>}</div>
            </div>
            <div className="form-group">
              <div className="login-group-input">
                <i className="login-icon-user  ti-lock"></i>
                <input
                  autoComplete="true"
                  placeholder="Mật khẩu"
                  style={inputStyle}
                  type="password"
                  name="password"
                  value={password}
                  onChange={handleChange}
                  className={"form-control" + (submitted && !password ? " is-invalid" : "")}
                />
              </div>
              <div className="login-error">
                {submitted && !password && <div>Mật khẩu là bất buộc</div>}
                {submitted && invalidPass && (
                  <div>{accepted ? "Thiết bị chưa được cấp quyền truy cập hệ thống" : "Tên đăng nhập hoặc mật khẩu không đúng"}</div>
                )}
              </div>
            </div>
            <div className="login-group-btn form-group">
              <button className="btn-login btn btn-light fw-5" style={{ color: "#406b46" }}>
                Đăng nhập
              </button>
            </div>
            <div className="text-center">
              <small className="forgot-password mb-0 cursor-pointer font-italic" style={{ color: "#406b46" }}>
                Quên mật khẩu
              </small>
            </div>
          </form>
        </div>
      </div>

      {/* <div className="login-footer">
        <div className="content-footer">
          <div className="col-lg-12">
            <p className="m-0">
              © 2021 Trung Tâm Tin Học - Trường Đại Học Khoa Học Tự Nhiên{" "}
            </p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Login;
