import React from "react";
import { useLocation } from "react-router-dom";
import StatisticHatchery from "./../../shrimp-hatchery/statistics/Statistic";
import StatisticHarvests from "./../../shrimp-farm/statistics/StatisticHarvests";
import StatisticStocking from "./../../shrimp-farm/statistics/StatisticStocking";
import StatisticDiseases from "./../../shrimp-farm/statistics/StatisticDiseases";
import StatisticProcessing from "../../processing-factory/statistics/StatisticProductionOutputByGroup";
import StatisticPurchasing from "../../purchasing-transport/transport-statistic/StatisticPurchasing";

const Statistic = () => {
  const search = useLocation().search;
  const screen = new URLSearchParams(search).get("screen");
  const participantId = new URLSearchParams(search).get("participantId");

  const transferComponent = () => {
    switch (screen) {
      case "hatchery": {
        return <StatisticHatchery id={participantId} webview={true}/>;
      }
      case "farm-harvests": {
        return <StatisticHarvests id={participantId} webview={true}/>;
      }
      case "farm-stocking": {
        return <StatisticStocking id={participantId} webview={true}/>;
      }
      case "farm-disease": {
        return <StatisticDiseases id={participantId} webview={true}/>;
      }
      case "processing": {
        return <StatisticProcessing id={participantId} webview={true}/>;
      }
      case "purchasing": {
        return <StatisticPurchasing id={participantId} webview={true}/>;
      }
      default:
        break;
    }
  };

  return <div>{transferComponent()}</div>;
};

export default Statistic;
