import proxyService from '../proxy/proxy.service';
import cryptoJS from 'crypto-js';
import Util from '../utils/utils.service';
import { AES_KEY } from '../../../constants/index';
const authenticateService = {
  authenticate,
  encrypt
}
const useDummy = false;
async function authenticate(username, password) {
  if (useDummy) {
    return proxyService.getJSON('/assets/dummy/authenticate.json').then(async result => result.data.result);
  }
  const iv = Util.generateIV();
  const result = await proxyService.post('/api/TokenAuth/Authenticate', {
    username,
    password: encrypt(password, iv)
  }, { iv });
  if (result.status === 202)
    return result.statusText;
  return result.data.result;
}

function encrypt(password, iv) {
  return cryptoJS.AES.encrypt(password, cryptoJS.enc.Utf8.parse(AES_KEY), { iv: cryptoJS.enc.Utf8.parse(iv) }).toString();
}

export default authenticateService