import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import EM from "../error-message/errorMessage";
import "../../../assets/css/controls/textbox.scss";
import { Fragment } from "react";
import "../../../assets/css/controls/dropdown.scss";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { Button, Modal, ModalFooter, ModalBody, ModalHeader } from "reactstrap";

const CKEditorField = ({
  label,
  configs,
  rules,
  name,
  defaultValue,
  menuPlacement,
  options,
  change,
  placeholder,
  className,
  components,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const toggle = () => setOpenModal(!openModal);

  const handleImageUploadBefore = (files, info, uploadHandler) => {
    if (files[0].size >= 4 * 1024 * 1024) {
      setOpenModal(true);
      return null;
    } else {
      resizeImage(files, uploadHandler);
    }
  };

  function resizeImage(files, uploadHandler) {
    const uploadFile = files[0];
    const img = document.createElement("img");
    const canvas = document.createElement("canvas");
    const reader = new FileReader();

    reader.onload = function (e) {
      img.src = e.target.result;
      img.onload = function () {
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        const MAX_WIDTH = 1024;
        const MAX_HEIGHT = 1024;
        // const MAX_HEIGHT = 640;

        //  const MAX_WIDTH = 800;
        //  const MAX_HEIGHT = 500;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }

        canvas.width = width;
        canvas.height = height;

        ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          function (blob) {
            uploadHandler([new File([blob], uploadFile.name)]);
          },
          uploadFile.type,
          1
        );
      };
    };

    reader.readAsDataURL(uploadFile);
  }

  const methods = useFormContext();
  const [nameState, setNameState] = useState();
  const [labelState, setLabelState] = useState();
  const [rulesState, setRulesState] = useState({});
  const [defaultValueState, setDefaultValueState] = useState("");
  const [directionState, setDirectionState] = useState("");
  const [layoutState, setLayoutState] = useState("");
  const [placeholderState, setPlaceholderState] = useState("");
  const [t] = useTranslation("common");
  const editorOptions = {
    buttonList: [
      [
        "undo",
        "redo",
        "font",
        "fontSize",
        "formatBlock",
        "paragraphStyle",
        "blockquote",
      ],
      ["bold", "underline", "italic", "strike", "subscript", "superscript"],
      ["fontColor", "hiliteColor", "textStyle"],
      ["removeFormat"],
      ["outdent", "indent"],
      ["align", "horizontalRule", "list", "lineHeight"],
      ["fullScreen", "showBlocks", "codeView", "preview"],
      ["table", "image", "video", "audio", "link"],
    ],
  };
  useEffect(() => {
    setNameState(name);
    setRulesState(rules || {});
    setLabelState(label ? t(label) : "");
    setDefaultValueState(defaultValue || "");
    setPlaceholderState(placeholder || "");
    setLayoutState(configs.layout || "5|5");
    setDirectionState(configs.direction || "vertical");
  }, [
    change,
    components,
    configs.control,
    configs.direction,
    configs.errors,
    configs.layout,
    defaultValue,
    label,
    menuPlacement,
    name,
    options,
    placeholder,
    rules,
    t,
  ]);

  return (
    <Fragment>
      {directionState === "horizontal" ? (
        <div className={"row form-group " + className}>
          <label
            className={"col-md-" + layoutState.split("|")[0]}
            required={rulesState.required}
          >
            {t(labelState)}
          </label>
          <div className={"col-md-" + layoutState.split("|")[1]}>
            {layoutState && (
              <Controller
                name={nameState}
                defaultValue={defaultValueState}
                render={({ onChange, value }) => {
                  return (
                    <SunEditor
                      width="100%"
                      height={configs.height || "350px"}
                      setOptions={editorOptions}
                      onChange={(dataChaged) => {
                        const changeEvent = {
                          target: {
                            value: dataChaged,
                          },
                        };
                        onChange(changeEvent.target.value);
                      }}
                      setContents={value}
                      placeholder={t(placeholderState)}
                    />
                  );
                }}
                rules={rulesState}
                control={methods.control}
              />
            )}
            {openModal && (
              <Fragment>
                <Modal isOpen={openModal} className="remove-modal">
                  <ModalHeader>Thông báo</ModalHeader>
                  <ModalBody>
                    Tiệp tin hình ảnh vượt quá 4MB, vui lòng chọn lại hình
                    ảnh!!!
                  </ModalBody>
                  <ModalFooter>
                    <Button onClick={toggle}>
                      <i className="fas fa-times mr5"></i> Đóng
                    </Button>
                  </ModalFooter>
                </Modal>
              </Fragment>
            )}
            {nameState && methods.errors && (
              <EM errors={methods.errors[nameState]} />
            )}
          </div>
        </div>
      ) : (
        <div className="form-group">
          <label className="" required={rulesState.required}>
            {t(labelState)}
          </label>
          <div className="">
            {layoutState && (
              <Controller
                name={nameState}
                defaultValue={defaultValueState}
                render={({ onChange, value }) => {
                  return (
                    <SunEditor
                      width="100%"
                      height={configs.height || "350px"}
                      setOptions={editorOptions}
                      onChange={(dataChaged) => {
                        const changeEvent = {
                          target: {
                            value: dataChaged,
                          },
                        };
                        onChange(changeEvent.target.value);
                      }}
                      onImageUploadBefore={handleImageUploadBefore}
                      setContents={value}
                      placeholder={t(placeholderState)}
                    />
                  );
                }}
                rules={rulesState}
                control={methods.control}
              />
            )}
            {openModal && (
              <Fragment>
                <Modal isOpen={openModal} className="remove-modal">
                  <ModalHeader>Thông báo</ModalHeader>
                  <ModalBody>
                    Tiệp tin hình ảnh vượt quá 4MB, vui lòng chọn lại hình
                    ảnh!!!
                  </ModalBody>
                  <ModalFooter>
                    <Button onClick={toggle}>
                      <i className="fas fa-times mr5"></i> Đóng
                    </Button>
                  </ModalFooter>
                </Modal>
              </Fragment>
            )}
            {nameState && methods.errors && (
              <EM errors={methods.errors[nameState]} />
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};
export default CKEditorField;
