import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { LOCAL_STORAGE_TOKEN } from '../../constants';
import { cacheService } from '../../utilities/services';



export const PrivateRoute = ({ component: Component, ...rest }) => (


    <Route {...rest} render={props => {
        const currentUser = cacheService.get(LOCAL_STORAGE_TOKEN);
        if (!currentUser) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/login/', state: { from: props.location } }} />
        }
        // authorised so return component
        return <Component {...props} />
    }} />
)

export default PrivateRoute;