import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import "./index.scss";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css";
import "./assets/css/sb-admin-2.min.css";
import "./assets/css/themify-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/css/layout.scss";
import "./assets/css/sidebar.scss";
import "./assets/css/dropdown-selection-box.scss";
import "./assets/css/menu-management.scss";
import "./assets/css/data-table.scss";
import "./assets/css/common.scss";
import "./assets/css/controls/snack-bar.scss";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "react-datepicker/dist/react-datepicker.css";
import "./assets/css/accordion.scss";
import "./assets/css/home.scss";
import "../node_modules/lightgallery.js/dist/css/lightgallery.min.css";
import common_en from "./i18n/en.json";
import common_vn from "./i18n/vn.json";
//
//
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./prototypes";
// init multi language
i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "vn", // language to use
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    vn: {
      common: common_vn,
    },
  },
});

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>,
  document.getElementById("root")
);

//config show log in dev environment
if (process.env.REACT_APP_TARGET_ENV === "prod") {
  console.log = () => { };
  console.warn = () => { };
  console.error = () => { };
}

serviceWorker.unregister();
