import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Select from "react-select";
//Services
import shrimpHatcheryServices from "../../../../utilities/services/shrimp-hatchery";
import { history } from "./../../../../utilities/helpers/history";
//Commons
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import { Table } from "../../../../commons/components/dynamic-table/Table";
//CSS
import "../../shrimp-hatchery/commons/css/index.scss";
import { ShrimpFarmServices } from "../../../../utilities/services/shrimp-farm/shrimpFarmServices";
//Type
import { WebviewTypes } from "../type";
import ProductionInfo from "./ProductionInfo";
import Util from "../../../../utilities/services/utils/utils.service";
import { Button } from "reactstrap";
import BlockchainInfo from './../traceability/modal-blockchain-detail/BlockchainInfo';

const DiariesWv = () => {
  const search = useLocation().search;
  const type = new URLSearchParams(search).get("type");
  const goback = new URLSearchParams(search).get("goback");
  const [diary, setDiary] = useState(null);
  const [reportTemplate, setReportTemplate] = useState(null);
  const [productions, setProductions] = useState(null);
  const [broodstock, setBroodstock] = useState(null);
  const productionIndex = useRef(0);
  
  
  const getParamId = () => {
    const id = new URLSearchParams(search).get("id");
    if (type === WebviewTypes.productionId) {
      getDiaryByProduction(id);
    } else if (type === WebviewTypes.exportId) {
      getHatcheryExports(id);
    } else if (type === WebviewTypes.farmStockingId) {
      getProduction(id);
    } else if (type === WebviewTypes.broodstockId) {
      getDiaryByBroodstock(id);
      const showInfo = new URLSearchParams(search).get("showInfo");
      if (showInfo === "true") {
        getBroodstockDetail(id);
      }
    } else {
      return;
    }
  };

  const getDiaryByBroodstock = async (broodstock_id = null) => {
    try {
      const response = await shrimpHatcheryServices.getDiariesByBroodstock(broodstock_id);
      console.log("getDiaryByBroodstock", response);
      if (response) {
        const value = typeof response.data === "object" ? response.data : {};
        const templateId = value.templateReportId;
        const tbody = value.content;
        setDiary(value);
        getReportTemplate(templateId, tbody);
      }
    } catch (err) {
      console.error("getDiaryByBroodstock", err);
    }
  };

  const getDiaryByProduction = async (production_id = null) => {
    try {
      const response = await shrimpHatcheryServices.getDiariesByProduction(production_id);
      if (response) {
        const value = typeof response.data === "object" ? response.data : {};
        const templateId = value.templateReportId;
        const tbody = value.content;
        setDiary(value);
        getReportTemplate(templateId, tbody);
      }
      return getInfoDetailProduction(production_id);
    } catch (err) {
      console.error("getDiaryByProduction", err);
    }
  };

  const getReportTemplate = async (template_id = null, res_tbody = "") => {
    try {
      const response = await shrimpHatcheryServices.getReportTemplate(template_id);
      console.log("getReportTemplate", response);
      let thead, cells, tbody;
      if (response) {
        const value = typeof response.data === "object" ? response.data : {};
        thead = JSON.parse(value.content) || {};
        cells = JSON.parse(value.script) || {};
        tbody = JSON.parse(res_tbody) || {};
        setReportTemplate({ ...thead, ...tbody, ...cells });
      }
    } catch (err) {
      console.error("getReportTemplate", err);
    }
  };

  const getHatcheryExports = async (id = null) => {
    try {
      const response = await shrimpHatcheryServices.getExportsDetail(id);
      let prod = [];
      console.log("getHatcheryExports", response);
      if (response) {
        const value = typeof response.data === "object" ? response.data : {};
        prod = value.exportDetails.map((p) => ({
          value: p.shrimpHatcheryProductionId,
          label: p.shrimpHatcheryProductionName || "",
        }));
        if (prod && prod.length) {
          let prodDetail = await getDiaryByProduction(prod[productionIndex.current].value);
          prod[productionIndex.current] = {
            ...prod[productionIndex.current],
            ...prodDetail,
          };
        }
        setProductions(prod);
      }
    } catch (err) {
      console.error("getHatcheryExports", err);
    }
  };

  const getProduction = async (id = null) => {
    try {
      const response = await ShrimpFarmServices.getProductionByFarmStockingId(id);
      let prod;
      console.log("getProduction", response);
      if (response) {
        const value = typeof response.data === "object" && response.data.length ? response.data : [];
        prod = value.map((p) => ({ value: p.id, label: p.batchCode || "" }));
        if (prod && prod.length) {
          let prodDetail = await getDiaryByProduction(prod[productionIndex.current].value);
          prod[productionIndex.current] = {
            ...prod[productionIndex.current],
            ...prodDetail,
          };
        }
        setProductions(prod);
      }
    } catch (err) {
      console.error("getProduction", err);
    }
  };

  const getInfoDetailProduction = async (id = null) => {
    try {
      const response = await shrimpHatcheryServices.getInfoDetailProduction(id);
      console.log("getInfoDetailProduction", response);
      if (response) {
        return typeof response.data === "object" ? response.data : {};
      } else {
        return {};
      }
    } catch (err) {
      console.error("getInfoDetailProduction", err);
    }
  };

  const getBroodstockDetail = async (id = null) => {
    try {
      const response = await shrimpHatcheryServices.getBroodstockDetail(id);
      console.log("getProduction", response);
      if (response) {
        const value = typeof response.data === "object" ? response.data : {};
        setBroodstock(value);
      }
    } catch (err) {
      console.error("getProduction", err);
    }
  };

  const handleChangeProduction = async (e) => {
    let prodId = e.value;
    productionIndex.current = e.index;
    let prodDetail = await getDiaryByProduction(prodId);
    let newProductions = [...productions];
    newProductions[e.index] = { ...newProductions[e.index], ...prodDetail };
    setProductions(newProductions);
  };

  const PhotoItem = ({ image, thumb, group }) => (
    <div style={{ maxWidth: "150px", width: "100px", padding: "5px" }}>
      <LightgalleryItem group={group} src={image} thumb={thumb}>
        <img src={image} style={{ width: "100%", height: "80px", objectFit: "cover" }} alt={group} />
      </LightgalleryItem>
    </div>
  );

  const FileItem = (f) => {
    const url = process.env.REACT_APP_BASEURL + f.file.path;

    const embedURL = `${f.file.type !== "application/pdf" ? `https://docs.google.com/viewerng/viewer?embedded=false&url=${url}` : url}`;
    return (
      <div>
        <a href={embedURL} target="_blank" rel="noopener noreferrer">
          <i className="fa fa-link"></i>&nbsp;
          {f.file.name}
        </a>
      </div>
    );
  };

  const handleRedirectWebview = (path = null, goback = "") => {
    if (path) {
      history.push(path + `&goback=${goback}`);
    }
  };

  const renderTitleHTML = (type_url) => {
    try {
      if (type_url === WebviewTypes.exportId || type_url === WebviewTypes.farmStockingId) {
        return (
          <>
            {productions && (
              <>
                {productions.length > 1 ? (
                  <div className="form-row col-md-12 col-auto p-0 align-items-center">
                    <span className="text-dark col-auto">
                      <span className="fw-5">Lô sản xuất: &nbsp;</span>
                    </span>
                    <div className="col-6">
                      <Select
                        className="diary-select"
                        label="Lô sản xuất"
                        name="production"
                        options={productions.map((p, key) => ({
                          ...p,
                          index: key,
                        }))}
                        defaultValue={productions[productionIndex.current]}
                        placeholder="Chọn"
                        onChange={handleChangeProduction}
                      />
                    </div>
                  </div>
                ) : null}
                <ProductionInfo info={productions[productionIndex.current]} redirect={handleRedirectWebview} />
              </>
            )}
          </>
        );
      }
      if (type === WebviewTypes.broodstockId && broodstock) {
        const showInfo = new URLSearchParams(search).get("showInfo");
        if (showInfo === "true") {
          const { batchCode, participantName, genesis, feminineQuantity, masculineQuantity, status, endManufactureDate, inputDate, description, blockchainDate, blockNum, transactionId, verified } = broodstock;
          const SET_COLUMN_4 = "col-4 fw-5";
          const SET_COLUMN_8 = "col-8";
          return (
            <React.Fragment>
              <div className="d-flex flex-column mb-2 text-dark">
                <h6 className="font-weight-bold text-primary">Thông tin chung</h6>
                <div className="pl-2 pr-2">
                  <div className="row">
                    <div className={SET_COLUMN_4}>Cơ sở:</div>
                    <div className={SET_COLUMN_8}>{participantName}</div>
                  </div>
                  <div className="row">
                    <div className={SET_COLUMN_4}>Mã lô:</div>
                    <div className={SET_COLUMN_8}>{batchCode}</div>
                  </div>
                  <div className="row">
                    <div className={SET_COLUMN_4}>Ngày nhập:</div>
                    <div className={SET_COLUMN_8}>{Util.yyyymmddFormatTimeString(inputDate)}</div>
                  </div>
                  <div className="row">
                    <div className={SET_COLUMN_4}>Nhà cung cấp:</div>
                    <div className={SET_COLUMN_8}>{genesis && " (" + genesis + ")"}</div>
                  </div>
                  <div className="row">
                    <div className={SET_COLUMN_4}>Số lượng:</div>
                    <div className={SET_COLUMN_8}>{feminineQuantity + " cái - " + masculineQuantity + " đực"}</div>
                  </div>
                  <div className="row">
                    <div className={SET_COLUMN_4}>Tình trạng:</div>
                    <div className={SET_COLUMN_8}>{status ? "Đang sản xuất" : "Đã sản xuất"}</div>
                  </div>
                  {status && (
                    <div className="row">
                      <div className={SET_COLUMN_4}>Ngày kết thúc:</div>
                      <div className={SET_COLUMN_8}> {Util.yyyymmddFormatTimeString(endManufactureDate)}</div>
                    </div>
                  )}
                  <div>
                    {description && (
                      <div className="row">
                        <div className={SET_COLUMN_4}>Mô tả/ ghi chú:</div>
                        <div className={SET_COLUMN_8}>{description && <span className="diaries-note" dangerouslySetInnerHTML={{ __html: description }} />}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {transactionId && 
              <div className="d-flex flex-column mb-2 text-dark">
                <h6 className="font-weight-bold text-primary">Thông tin Blockchain</h6>
                <div className="pl-2 pr-2">
                  <BlockchainInfo dataBlock={{transactionId, blockNum, verified }} formConfigs={{ layout: "4 fw-5 |8", searchBar: "hidden" }} queryBlock={() => {
                    return (
                      <Button style={{ overflowWrap: "anywhere" }} onClick={() => handleRedirectWebview(`/traceability?type=blockchain&queryBlockchain=${transactionId}&showInfo=true`, true)} color="link" className={"p-0 text-left"}>
                          {transactionId}
                      </Button>
                    )
                  }}/>
                </div>
              </div>}

              <h6 className="font-weight-bold text-primary">Nhật ký sản xuất</h6>
            </React.Fragment>
          );
        }
      }
    } catch (err) {
      console.error("renderTitleHTML", err);
    }
  };

  useEffect(() => {
    getParamId();
  }, [type]);

  return (
    // hide-ofl-x
    <div className="content" style={{ width: "100%", height: "100%", overflow: "hidden" }}>
      {renderTitleHTML(type)}
      {reportTemplate && reportTemplate?.rows?.data?.length ? (
        <div className="mt-3">
          <Table dataCallBack={() => {}} webview={true} template={reportTemplate} />
        </div>
      ) : null}

      {diary && diary?.files?.length ? (
        <>
          <h6 className="font-weight-bold text-primary mt-3">Hình ảnh - Tệp tin đính kèm</h6>
          <div className="row mb-3">
            <div className="col-md-auto">
              {[...diary.files].map((f, i) => {
                if (f.type !== "image/jpeg") {
                  return <FileItem key={i} file={f} />;
                }
              })}
            </div>
            <div className="col-md-auto">
              <div className="col col-complementary d-flex flex-wrap overflow-auto pr-0 pl-0" style={{ maxHeight: "150px" }}>
                <LightgalleryProvider>
                  {[...diary.files].map((link, i) => {
                    if (link.type === "image/jpeg") {
                      return <PhotoItem key={i} image={process.env.REACT_APP_BASEURL + link.path} group="group2" />;
                    }
                  })}
                </LightgalleryProvider>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {goback === "true" ? (
        <div className="backTop cd-top--is-visible cd-top--fade-out">
          <i className="fa fa-angle-left" onClick={() => window.history.back()}></i>
        </div>
      ) : null}
    </div>
  );
};

export default DiariesWv;
