import React, { useState, Fragment } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from "reactstrap";
import BlockchainInfo from "./BlockchainInfo";

const ModalBlockchain = ({ id, setDefaultState, whenClose, hiddenToggle, icon, text, className }) => {
  const [modal, setModal] = useState(setDefaultState || false);

  const toggle = () => {
    setModal(!modal);
    if (typeof whenClose === "function") {
      whenClose(false);
    }
  };

  return (
    <Fragment>
      <Button style={{ overflowWrap: "anywhere" }} onClick={toggle} color="link" className={"p-0 d-flex flex-column align-items-center w-100 " + className} hidden={hiddenToggle}>
        <i style={{ fontSize: 20 }} className={`${icon ? icon + " text-danger " : "fas fa-info-circle"}`} />
        <span>{text ? text : "Xem chi tiết"}</span>
      </Button>

      <Modal isOpen={modal} toggle={toggle} className={"table-modal modal-lg "} backdrop={"static"}>
        <Form>
          <ModalHeader className="top-modal-header" toggle={toggle}>
            Thông tin chi tiết block
          </ModalHeader>

          <ModalBody className="p-3">
            <BlockchainInfo id={id} formConfigs={{layout: "2|10", status : 'hidden'}} />
          </ModalBody>

          <ModalFooter>
            <Button color="secondary" onClick={toggle} className="fn-w-600">
              <i className="fas fa-times mr5"></i>
              Đóng
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default ModalBlockchain;
