export const WebviewTypes = {
  //CƠ SỞ SẢN XUẤT GIỐNG:
  // + Render nhật ký sản xuất giống với ID lô giống
  productionId: "productionId", //params: /diaries?type=&id=
  broodstockId: "broodstockId",

  //CƠ SỞ NUÔI
  // + Truy xuất nhật ký sản xuất giống với ID xuất tôm giống của cơ sở SX, bằng button TXNG, tại chức năng quản lý nhập
  exportId: "exportId", //params: /diaries?type=&id=

  //CƠ SỞ THU MUA VẬN CHUYỂN
  // + Render nhật ký sản xuất giống với ID thả giống của cơ sở nuôi, bằng link "Xem chi tiết>>"
  farmStockingId: "farmStockingId", //params: /diaries?type=&id=

  // + Truy xuất nguồn gốc với ID cơ sở nuôi + ngày thu mua, bằng button TXNG, tại chức năng quản lý thu mua
  farmId: "farmId", //params: /traceability?type=&id=date=    //id: participantID

  //CƠ SỞ CHẾ BIẾN
  // + Truy xuất nguồn gốc với ID lô + ngày vận chuyển, bằng button TXNG, tại chức năng quản lý lô nhập tôm nguyên liệu
  transportLotId: "transportLotId", //params: /traceability?type=&id=date=

  // + Truy xuất nguồn gốc với ID lô + ngày sản xuất nhà máy, bằng button TXNG, tại chức năng quản lý lô sản xuất
  lotId: "lotId", //params: /traceability?type=&id=date=

  // + Truy xuất thông tin blockchain
  blockchain: "blockchain",

  ///Statistic
  //Cơ sở sản xuất giống
  hatcheryOutput: "hatchery",
  //Cơ sở nuôi
  farmHarvest: "harvests",
  farmStocking: "stocking",
  farmDisease: "farmDisease",
  //Cơ sở thu mua
  purchasing: "purchasing",
  //Cơ sở nhà máy chế biển
  productionOutput: "processing",
  productionOutputByGroup: "processing",
};
