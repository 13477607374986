import proxyService from "../proxy/proxy.service";

export const blockChainService = {
  getBlockData,
};

let params = null;
let header = null;

function getBlockData(limit) {
  const url = "/api/app/blockChainInfo/block";
  params = {
    limit: limit,
  };
  return proxyService.get(url, header, params);
}

export default blockChainService;
