export const cacheService = {
    get,
    set,
    remove
}

function get(key) {
    const stringValue = localStorage.getItem(key);
    try {
        return JSON.parse(stringValue);
    } catch (e) {
        return stringValue;
    }
}

function set(key, value) {
    let stringValue;

    switch (typeof value) {
        case 'string':
        case 'number':
        case 'boolean':
            stringValue = value.toString();
            break;

        case 'object':
            stringValue = JSON.stringify(value);
            break;

        default:
            return;
    }

    localStorage.setItem(key, stringValue);
}

function remove(key) {
    localStorage.removeItem(key);
}

export default cacheService;