import { lazy } from "react";

const DashBoard = lazy(() => import("../../pages/users/home/DashBoard"));
const WorkingSchedule = lazy(() =>
  import("../../pages/users/working-schedule/WorkingSchedule")
);
const Menu = lazy(() =>
  import("../../pages/users/system-management/menu/Menu")
);
// const Role = lazy(() => import('../../pages/users/system-management/Role'));
const Department = lazy(() =>
  import("../../pages/users/system-management/department/Department")
);
const AccountManagement = lazy(() =>
  import("../../pages/users/user-info/AccountManagement")
);
const UserRole = lazy(() => import("../../pages/users/user-info/UserRole"));
const NewsManagement = lazy(() =>
  import(
    "../../pages/users/contents-management/category-management/NewsManagement"
  )
);
const ContentsManagement = lazy(() =>
  import(
    "../../pages/users/contents-management/article-management/ContentsManagement"
  )
);
const GeoDistrict = lazy(() =>
  import("../../pages/users/administrative-unit/geo-district/GeoDistrict")
);
const GeoCommune = lazy(() =>
  import("../../pages/users/administrative-unit/geo-commune/GeoCommune")
);
const GeoProvince = lazy(() =>
  import("../../pages/users/administrative-unit/geo-province/GeoProvince")
);
const ChangePassword = lazy(() =>
  import("../../pages/users/user-info/ChangePassword")
);
const FieldList = lazy(() => import("../../pages/users/field-list/FieldList"));

const UserLog = lazy(() => import("../../pages/users/user-logs/UserLogs"));
const ConnectedDevices = lazy(() =>
  import(
    "../../pages/users/connected-devices-management/ConnectedDevicesManagement"
  )
);

const Work = lazy(() => import("../../pages/users/categories/work/Work"));
const WorkDefine = lazy(() =>
  import("../../pages/users/categories/work-define/WorkDefine")
);
const Certification = lazy(() =>
  import("../../pages/users/categories/certification/Certification")
);
const Disease = lazy(() =>
  import("../../pages/users/categories/disease/Disease")
);
const Medicine = lazy(() =>
  import("../../pages/users/categories/medicine/Medicine")
);
const ReportTemplate = lazy(() =>
  import("../../pages/users/categories/reporttemplate/ReportTemplate")
);
const EmergencyNotification = lazy(() =>
  import(
    "../../pages/users/notifications/emergency-notification/EmergencyNotification"
  )
);

//cơ sở
const Participant = lazy(() =>
  import("../../pages/users/participant/manage/Participant")
);
const ParticipantLogs = lazy(() =>
  import("../../pages/users/participant/participant-logs/ParticipantLogs")
);
const ParticipantGroup = lazy(() =>
  import("../../pages/users/participant/group/ParticipantGroup")
);
const ParticipantTeam = lazy(() =>
  import("../../pages/users/participant/team/ParticipantTeam")
);
const ParticipantInfo = lazy(() =>
  import("../../pages/users/participant/info/MainInfo")
);
//Giá tôm
//const PricingSeed = lazy(() => import("../../pages/users/pricing/PricingV2"));
const PricingCommercial = lazy(() =>
  import("../../pages/users/pricing/PricingV2")
);

//Cơ sở giống
const ShrimpHatcheryCultureTanks = lazy(() =>
  import("../../pages/users/shrimp-hatchery/culture-tank/CultureTanks")
);
const ShrimpHatcheryTankHouse = lazy(() =>
  import("../../pages/users/shrimp-hatchery/tank-house/TankHouse")
);
const ShrimpHatcheryProductions = lazy(() =>
  import("../../pages/users/shrimp-hatchery/productions/Productions")
);
const ShrimpHatcheryBroodstock = lazy(() =>
  import("../../pages/users/shrimp-hatchery/broodstock/Broodstock")
);
const ShrimpHatcheryDiseases = lazy(() =>
  import("../../pages/users/shrimp-hatchery/diseases/Diseases")
);
const ShrimpHatcheryExports = lazy(() =>
  import("../../pages/users/shrimp-hatchery/exports/Exports")
);
const ShrimpHatcheryStatistic = lazy(() =>
  import("../../pages/users/shrimp-hatchery/statistics/Statistic")
);
const ShrimpHatcheryStage = lazy(() =>
  import("../../pages/users/categories/stage/Stage")
);
const OverviewHatchery = lazy(() =>
  import("../../pages/users/shrimp-hatchery/overview/OverviewHatchery")
);

const DiaryTemplates = lazy(() =>
  import("../../pages/users/shrimp-hatchery/diary-templates/DiaryTemplates")
);

//Cơ sở nuôi
const ShrimpFarmHarvests = lazy(() =>
  import("../../pages/users/shrimp-farm/harvest/Harvests")
);
const ShrimpFarmStatisticDiseases = lazy(() =>
  import("../../pages/users/shrimp-farm/statistics/StatisticDiseases")
);
const ShrimpFarmStatisticStocking = lazy(() =>
  import("../../pages/users/shrimp-farm/statistics/StatisticStocking")
);
const ShrimpFarmStatisticHarvests = lazy(() =>
  import("../../pages/users/shrimp-farm/statistics/StatisticHarvests")
);
const ShrimpFarmJournal = lazy(() =>
  import("../../pages/users/shrimp-farm/journal/Journal")
);

//Cơ sở thu mua vận chuyển - Trân
const PurchasingTransport = lazy(() =>
  import("../../pages/users/purchasing-transport/transport-monitor/index")
);
const PurchasingTransportManager = lazy(() =>
  import("../../pages/users/purchasing-transport/transport-manage/Transport")
);
const PurchasingStatistical = lazy(() =>
  import(
    "../../pages/users/purchasing-transport/transport-statistic/StatisticPurchasing"
  )
);

//Nhà máy chế biến - Trân
const ProcessingManagement = lazy(() =>
  import("../../pages/users/processing-factory/management/Management")
);
const ProcessingProduction = lazy(() =>
  import("../../pages/users/processing-factory/production/Production")
);
const ProcessingStatistic = lazy(() =>
  import(
    "../../pages/users/processing-factory/statistics/StatisticProductionOutputByGroup"
  )
);

///
const BlockchainMonitor = lazy(() =>
  import("../../pages/users/blockchain-system/monitor")
);
const BlockchainNetwork = lazy(() =>
  import("../../pages/users/blockchain-system/node-networks")
);
const BlockchainNumId = lazy(() =>
  import("../../pages/users/blockchain-system/block-num")
);
const BlockchainTrans = lazy(() =>
  import("../../pages/users/blockchain-system/transactions")
);

////
export const CONFIGROUTES = [
  { url: "/home", component: DashBoard },
  { url: "/workings", component: WorkingSchedule },
  { url: "/articles/news-group", component: NewsManagement },
  { url: "/articles/news", component: ContentsManagement },
  { url: "/users/roles", component: UserRole },
  { url: "/users/account", component: AccountManagement },
  { url: "/categories/province", component: GeoProvince },
  { url: "/categories/district", component: GeoDistrict },
  { url: "/categories/commune", component: GeoCommune },
  { url: "/categories/fieldlist", component: FieldList },
  { url: "/systems/menu", component: Menu },
  { url: "/systems/department", component: Department },
  { url: "/change-password", component: ChangePassword },
  //
  { url: "/user-logs", component: UserLog },
  { url: "/categories/connected-devices", component: ConnectedDevices },
  { url: "/categories/work", component: Work },
  { url: "/categories/work-define", component: WorkDefine },
  { url: "/categories/report-template", component: ReportTemplate },
  { url: "/categories/certification", component: Certification },
  { url: "/categories/disease", component: Disease },
  { url: "/categories/medicine", component: Medicine },
  { url: "/categories/stage", component: ShrimpHatcheryStage },

  { url: "/emergency-notification", component: EmergencyNotification },

  //cơ sở
  { url: "/participant/management", component: Participant },

  { url: "/participant/log", component: ParticipantLogs },
  { url: "/participant/group", component: ParticipantGroup },
  { url: "/participant/team", component: ParticipantTeam },
  { url: "/participant/info", component: ParticipantInfo },

  //Giá tôm
  // { url: "/pricing/commercial-shrimp", component: PricingCommercial },
  // { url: "/pricing/shrimp-seed", component: PricingSeed },
  { url: `/pricing/:code`, component: PricingCommercial },

  //Cơ sở sản xuất giống
  {
    url: "/shrimp-hatchery/culture-tanks",
    component: ShrimpHatcheryCultureTanks,
  },
  { url: "/shrimp-hatchery/tank-house", component: ShrimpHatcheryTankHouse },
  { url: "/shrimp-hatchery/productions", component: ShrimpHatcheryProductions },
  { url: "/shrimp-hatchery/broodstock", component: ShrimpHatcheryBroodstock },
  { url: "/shrimp-hatchery/diseases", component: ShrimpHatcheryDiseases },
  { url: "/shrimp-hatchery/exports", component: ShrimpHatcheryExports },
  { url: "/shrimp-hatchery/statistic", component: ShrimpHatcheryStatistic },
  { url: "/shrimp-hatchery/overview", component: OverviewHatchery },
  { url: "/shrimp-hatchery/diary-templates", component: DiaryTemplates },

  //Cơ sở nuôi tôm
  { url: "/shrimp-farm/harvests", component: ShrimpFarmHarvests },
  { url: "/shrimp-transport/harvests", component: ShrimpFarmHarvests },
  {
    url: "/shrimp-farm/harvests-statistic",
    component: ShrimpFarmStatisticHarvests,
  },
  {
    url: "/shrimp-farm/stocking-statistic",
    component: ShrimpFarmStatisticStocking,
  },
  {
    url: "/shrimp-farm/diseases-statistic",
    component: ShrimpFarmStatisticDiseases,
  },
  { url: "/shrimp-farm/journal", component: ShrimpFarmJournal },

  //Cơ sở thu mua, vận chuyển - Trân
  { url: "/purchasing-transport/index", component: PurchasingTransport },
  {
    url: "/purchasing-transport/transport",
    component: PurchasingTransportManager,
  },
  {
    url: "/purchasing-transport/statistic",
    component: PurchasingStatistical,
  },

  //Nhà máy chế biến - Trân
  { url: "/processing-factory/management", component: ProcessingManagement },
  { url: "/processing-factory/production", component: ProcessingProduction },
  { url: "/processing-factory/statistic/:id", component: ProcessingStatistic },

  //Blockchain management
  { url: "/blockchain-system/monitor", component: BlockchainMonitor },
  { url: "/blockchain-system/network", component: BlockchainNetwork },
  { url: "/blockchain-system/numId", component: BlockchainNumId },
  { url: "/blockchain-system/transId", component: BlockchainTrans },
];

export const GET_ROUTES_SUCCESS = "GET_ROUTES_SUCCESS";
export const GET_ROUTES_LOADING = "GET_ROUTES_LOADING";
export const GET_ROUTES_ERROR = "GET_ROUTES_ERROR";
