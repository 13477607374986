import { useLocation } from "react-router-dom";
import React, { useState, useEffect, Fragment } from "react";
import { history } from "../../../../utilities/helpers";
//css
import "../css/index.scss";
//Services
import farmHarvestService from "../../../../utilities/services/shrimp-farm/FarmHarvestService";
import RemoveVietnameseTones from "../../../../commons/components/vietnamese-tone-replace/vietnamese-tone-rm";
import { WebviewTypes } from "../type";
export default (props) => {
  const urlSearch = useLocation().search;
  const goback = new URLSearchParams(urlSearch).get("goback");
  const [inputs, setInputs] = useState({
    products: [],
    originalProducts: [],
    searchString: "",
  });
  // console.log(inputs);
  const search = (event) => {
    try {
      event.preventDefault();
      if (event.target.value) {
        let filtered = [];
        inputs.originalProducts.forEach(group => {
          let filChild = group.farms.filter((item) => {
            return (
              RemoveVietnameseTones.removeVietnameseTones((item.participantName || "").toLowerCase()).includes(RemoveVietnameseTones.removeVietnameseTones(event.target.value.toLowerCase())) ||
              RemoveVietnameseTones.removeVietnameseTones((item.aquacultureBranchCode || "").toLowerCase()).includes(RemoveVietnameseTones.removeVietnameseTones(event.target.value.toLowerCase())) ||
              RemoveVietnameseTones.removeVietnameseTones((item.size || "").toLowerCase()).includes(RemoveVietnameseTones.removeVietnameseTones(event.target.value.toLowerCase()))
            );
          });
          filtered.push({ ...group, farms: filChild });
        });
        setInputs({
          ...inputs,
          searchString: event.target.value,
          products: filtered,
        });
      } else {
        setInputs({
          ...inputs,
          products: inputs.originalProducts,
          searchString: "",
        });
      }
    } catch (error) {
      console.error("search", error);
    }
  };

  const getFarmHarvestDetail = async () => {
    let params = {
      FarmHarvestId: new URLSearchParams(urlSearch).get("farmHarvestId"),
      ParticipantGroupsId: new URLSearchParams(urlSearch).get("participantGroupsId"),
      HarvestDate: new URLSearchParams(urlSearch).get("harvestDate"),
      TransportId: new URLSearchParams(urlSearch).get("transportId"),
    };
    const response = await farmHarvestService.getDetail(params);
    if (response && response.data) {
      // let array = response.data.map(item => { return [...item.farms] });
      // array = array.flat();
      console.log("getFarmHarvestDetail", response.data);

      setInputs({
        searchString: "",
        products: response.data,
        originalProducts: response.data,
      });
    }
  };

  useEffect(() => {
    getFarmHarvestDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <div style={{ maxWidth: "500px", width: "100%", margin: "0 auto" }}>
        <div className="input-group">
          <input type="text" className="form-control form-control" value={inputs.searchString || ""} placeholder="Tìm theo tên, mã số" onChange={(e) => search(e)} />
          <div className="input-group-append">
            <button className="btn btn-outline-primary btn-sm" type="button" onClick={getFarmHarvestDetail}>
              <i className="fas fa-redo"></i>
            </button>
          </div>
        </div>
        <h5 className="fw-5 d-block pt-2 pb-2 mb-0" style={{ color: '#347ba3' }}>Danh sách thu mua</h5>

        <div>
          <table className="table table-striped" style={{ fontSize: '13.5px', color: 'black' }}>
            <thead style={{ background: "#cad6d6" }}>
              <tr>
                <th className="align-vertical-center" scope="col">
                  STT
                </th>
                <th className="align-vertical-center" scope="col" >
                  Tên cơ sở
                </th>
                {/* <th className="align-vertical-center" scope="col">
                  SĐT
                </th> */}
                <th className="align-vertical-center" scope="col">
                  T.Lượng
                </th>
                <th className="align-vertical-center" scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {inputs.products &&
                inputs.products.map((item1, index1) => {
                  return (
                    <Fragment key={item1.groupId}>
                      <tr key={index1} className="text-left" style={{ backgroundColor: "#D0D9D8" }}>
                        <td className="pl-3 pt-2 pb-2 font-weight-bold" colSpan={7}>{item1.groupName}</td>
                      </tr>
                      {item1.farms && item1.farms.map((item, index) => {
                        return (
                          <tr key={index} className="text-center" onClick={() => history.push(`/traceability?type=${WebviewTypes.farmId}&id=${item.participantId}&date=${item.harvestDate}&goback=true`)}>
                            <td className="vertical-center p-2" scope="row">
                              {index + 1}
                            </td>
                            <td className="vertical-center text-left" style={{ width: "60%" }}>
                              <div className="">{item.participantName}</div>
                              <small className="small"><i>{item.aquacultureBranchCode}</i></small>
                            </td>
                            {/* <td className="vertical-center">{item.aquacultureBranchCode || ""}</td> */}
                            <td className="vertical-center" style={{ width: "20%" }}>{item.total} kg</td>
                            <td className="vertical-center cursor-pointer">
                              <i className="fas fa-chevron-right text-primary mr-2"></i>
                            </td>
                          </tr>
                        )
                      })}

                    </Fragment>
                  );
                })}
            </tbody>
          </table>
        </div>
        {goback === "hidden"? '' :  <div className="backTop cd-top--is-visible cd-top--fade-out">
          <i className="fa fa-angle-left" onClick={() => window.history.back()}></i>
        </div>}
      </div>
    </Fragment >
  );
};
