import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import EM from '../error-message/errorMessage';
import '../../../assets/css/controls/textbox.scss';
import { Fragment } from 'react';
import '../../../assets/css/controls/dropdown.scss';

const FileUpload = ({ label, configs, rules,
  name, defaultValue, menuPlacement,
  options, change, placeholder,
  className, components, disabled, textValue }) => {
  const methods = useFormContext();
  const [nameState, setNameState] = useState();
  const [labelState, setLabelState] = useState();
  const [rulesState, setRulesState] = useState({});
  const [defaultValueState, setDefaultValueState] = useState('');
  const [directionState, setDirectionState] = useState('');
  const [layoutState, setLayoutState] = useState('');
  const [placeholderState, setPlaceholderState] = useState('');
  const [valueView, setValueView] = useState('');
  const [t] = useTranslation('common');

  useEffect(() => {
    setNameState(name);
    setRulesState(rules || {});
    setLabelState(label ? t(label) : '');
    setDefaultValueState(defaultValue || '');
    setPlaceholderState(placeholder || '');
    setLayoutState(configs.layout || '5|5');
    setDirectionState(configs.direction || 'vertical');
    setValueView(textValue || "");
  }, [change, components, configs.control,
    configs.direction, configs.errors, configs.layout,
    defaultValue, label, menuPlacement,
    name, options, placeholder,
    rules, textValue, t])

  const renderDeleteFile = (onChange, direction) => {
    return (
      <label className={'custom-delete-file ' + (direction !== 'horizontal' ? 'margin-delete-file' : '')} type="button" onClick={() => {
        setValueView('');
        document.getElementsByClassName('custom-file-input')[0].value = '';
        onChange(new File([""], "deleteFile"));
      }}>
      </label>
    )
  }

  return (
    <Fragment>
      {directionState === 'horizontal' ?
        <div className={'align-items-center row form-group ' + className + (disabled ? ' item-disabled' : '')}>
          <label className={'mb-0 col-md-' + layoutState.split('|')[0]} required={rulesState.required}>{t(labelState)}</label>
          <div className={'col-md-' + layoutState.split('|')[1]} >
            {layoutState &&
              <Controller
                name={nameState}
                defaultValue={defaultValueState}
                render={({ onChange, value }) => {
                  return <div><input
                    type='file'
                    className='custom-file-input form-control-file'
                    defaultValue={value}
                    onChange={(event) => {
                      const changeEvent = {
                        target: {
                          value: event.target.files[0]
                        }
                      }
                      if (changeEvent.target.value) {
                        onChange(changeEvent.target.value);
                        changeEvent.target.value ? setValueView(changeEvent.target.value.name) : setValueView(value.name);
                      }
                    }}
                    data={value}
                    placeholder={t(placeholderState)}
                  />
                    <label className='custom-file-label margin-attachment-label truncate-text'>
                      {(typeof value === 'object' && value !== null ? value.name === 'deleteFile' ? '' : value.name : value)
                        ||
                        (valueView !== '' && valueView !== 'deleteFile' ? valueView : '')}
                    </label>
                    {(value && value.name !== 'deleteFile') || (!!valueView && valueView !== 'deleteFile') ? renderDeleteFile(onChange, directionState) : ''}
                  </div>
                }}
                rules={rulesState}
                control={methods.control}
              />
            }
            {nameState && methods.errors && <EM errors={methods.errors[nameState]} />}
          </div>
        </div>
        :
        <div className={'form-group ' + (disabled ? 'item-disabled' : '')}>
          <label className='' required={rulesState.required}>{t(labelState)}</label>
          <div className={className} >
            {layoutState &&
              <Controller
                name={nameState}
                defaultValue={defaultValueState}
                render={({ onChange, value }) => {
                  return <div><input
                    type='file'
                    className='custom-file-input form-control-file'
                    defaultValue={value}
                    onChange={(event) => {
                      const changeEvent = {
                        target: {
                          value: event.target.files[0]
                        }
                      }
                      if (changeEvent.target.value) {
                        onChange(changeEvent.target.value);
                        changeEvent.target.value ? setValueView(changeEvent.target.value.name) : setValueView(value.name);
                      }
                    }}
                    data={value}
                    placeholder={t(placeholderState)}
                  />
                    <label className='custom-file-label truncate-text'>
                      {
                        (typeof value === 'object' && value !== null ? value.name === 'deleteFile' ? '' : value.name : value)
                        ||
                        (valueView !== '' && valueView !== 'deleteFile' ? valueView : '')
                      }</label>
                    {(value && value.name !== 'deleteFile') || (valueView && valueView !== 'deleteFile') ? renderDeleteFile(onChange, directionState) : ''}
                  </div>
                }}
                rules={rulesState}
                control={methods.control}
              />
            }
            {nameState && methods.errors && <EM errors={methods.errors[nameState]} />}
          </div>
        </div>
      }
    </Fragment>
  )
}
export default FileUpload;