import React from 'react';
import { Ring } from 'react-awesome-spinners';
import { useSelector } from 'react-redux';

const Loading = () => {
  const loading = useSelector(state => state.loadingReducer);
  return (
    <div className={loading.isLoading ? 'overlay' : ''}>
      {loading.isLoading && (
        <div className='loading-indicator'>
          <Ring />
        </div>
      )}
    </div>
  );
}
export default Loading;

