import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import EM from '../error-message/errorMessage';
import '../../../assets/css/controls/textbox.scss';
import { Fragment } from 'react';
import Select from 'react-select';
import '../../../assets/css/controls/dropdown.scss';


const Dropdown = ({ label, formConfigs, rules,
  name, defaultValue, menuPlacement,
  options, change, placeholder, afterRender,
  className, components, hidden, disabled }) => {
  const methods = useFormContext();
  const [init, setInit] = useState(false);
  const [nameState, setNameState] = useState();
  const [labelState, setLabelState] = useState();
  const [rulesState, setRulesState] = useState({});
  const [defaultValueState, setDefaultValueState] = useState('');
  const [directionState, setDirectionState] = useState('');
  const [layoutState, setLayoutState] = useState('');
  const [menuPlacementState, setMenuPlacementState] = useState('down');
  const [optionsState, setOptionsState] = useState([]);
  const [placeholderState, setPlaceholderState] = useState('');
  const [hiddenState, setHiddenState] = useState(false);
  const [componentsState, setComponentsState] = useState({});
  const [t] = useTranslation('common');

  const invokeActions = (option) => {
    if (typeof change === 'function') {
      change(option);
    }
  }
  useEffect(() => {
    setNameState(name);
    setHiddenState(hidden);
    setRulesState(rules || {});
    setLabelState(label ? t(label) : '');
    setDefaultValueState(defaultValue || '');
    setMenuPlacementState(menuPlacement || 'auto');
    setOptionsState(options || []);
    setPlaceholderState(placeholder || '');
    setLayoutState(formConfigs && formConfigs.layout ? formConfigs.layout || '5|5' : methods.formConfigs.layout || '5|5');
    setDirectionState(formConfigs && formConfigs.direction ? formConfigs.direction || 'vertical' : methods.formConfigs.direction || 'vertical')
    setComponentsState(components || {});
    if (!init && typeof afterRender === 'function' && methods.control.getValues(name)) {
      afterRender(methods.control.getValues(name));
      setInit(true);
    }
  }, [
    afterRender,
    change,
    components,
    defaultValue,
    formConfigs,
    hidden,
    init,
    label,
    menuPlacement,
    methods.control,
    methods.formConfigs.direction,
    methods.formConfigs.layout,
    name,
    options,
    placeholder,
    rules,
    t
  ])

  return (
    <Fragment>
      {directionState === 'horizontal' ?
        <div hidden={hiddenState} className={'row form-group align-items-center ' + className + (disabled ? ' item-disabled' : '')}>
          {label && <label className={'mb-0 col-md-' + layoutState.split('|')[0]} required={rulesState.required}>{t(labelState)}</label>}
          <div className={'col-md-' + layoutState.split('|')[1]}>
            {layoutState &&
              <Controller
                name={nameState}
                defaultValue={defaultValueState? defaultValueState : optionsState?.length === 1 ? optionsState[0] : false}
                render={({ onChange, value }) => {
                  return <Select menuPlacement={menuPlacementState}
                    className={methods.errors[nameState] ? 'border-red dropdown-control' : 'dropdown-control'}
                    defaultValue={value}
                    onChange={(option) => {
                      onChange(option);
                      invokeActions(option);
                    }}
                    value={value}
                    options={optionsState}
                    placeholder={t(placeholderState)}
                  />
                }}
                rules={rulesState}
                control={methods.control}
              />
            }
            {nameState && methods.errors && <EM errors={methods.errors[nameState]} />}
          </div>
        </div>
        :
        <div hidden={hiddenState} className={'form-group ' + (disabled ? 'item-disabled' : '')}>
          {label && <label className='' required={rulesState.required}>{t(labelState)}</label>}
          <div className={className} >
            {directionState &&
              <Controller
                name={nameState}
                defaultValue={defaultValueState? defaultValueState : optionsState?.length === 1 ? optionsState[0] : false}
                render={({ onChange, value }) => {
                  return <Select menuPlacement={menuPlacementState}
                    className={methods.errors[nameState] ? 'border-red dropdown-control' : 'dropdown-control'}
                    defaultValue={value}
                    components={componentsState}
                    onChange={(option) => {
                      onChange(option);
                      invokeActions(option);
                    }}
                    value={value}
                    options={optionsState}
                    placeholder={t(placeholderState)}
                  />
                }}
                rules={rulesState}
                control={methods.control}
              />
            }
            {nameState && methods.errors && <EM errors={methods.errors[nameState]} />}
          </div>
        </div>
      }
    </Fragment>
  )
}
export default Dropdown;