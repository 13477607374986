const actionsTypes = {
  error: 'ERROR',
  success: 'SUCCESS',
  information: 'INFORMATION',
};

const snackBarReducer = (state, action) => {
  switch (action.type) {
    case actionsTypes.error:
      return {
        ...state,
        isShow: true,
        message: action.message,
        title: action.title,
        snackBarType: 'sb-danger',
        delay: action.delay
      }
    case actionsTypes.success:
      return {
        ...state,
        isShow: true,
        message: action.message,
        title: action.title,
        snackBarType: 'sb-success',
        delay: action.delay
      }
    case actionsTypes.information:
      return {
        ...state,
        isShow: true,
        message: action.message,
        title: action.title,
        snackBarType: 'sb-info',
        delay: action.delay
      }
    default:
      return {
        isShow: false
      }
  }
}

export default snackBarReducer;