import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const EM = (errors) => {
  const [errorsState, setErrorsState] = useState()
  const [t] = useTranslation('common');
  useEffect(() => {
    setErrorsState(errors);
  }, [errors])

  return (
    <Fragment>
      {errorsState && errorsState.errors && errorsState.errors.type === 'required' &&
        <span className='error-message'>
          {t('required')}
        </span>}
      {errorsState && errorsState.errors && errorsState.errors.type === 'pattern' &&
        <span dangerouslySetInnerHTML={{ __html: t(errorsState.errors.message) }} className='error-message'>
        </span>}
    </Fragment>
  )
}
export default EM;