import React, { useState, Fragment } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from "reactstrap";
import webviewServices from "../../../../../utilities/services/webview";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
// Import Swiper styles
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectCoverflow, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import GoogleApiWrapper from "../modal-participant-detail/Maps";

SwiperCore.use([EffectCoverflow, Pagination]);

const ModalParticipantDetail = ({ participantId }) => {
  const [modal, setModal] = useState(false);
  const [data, setData] = useState(null);
  const SET_COLUMN_4 = "col-4 fw-5";
  const SET_COLUMN_8 = "col-8";
  const toggle = () => {
    setModal(!modal);
    if (!modal) {
      getParticipantDetail(participantId);
    }
  };

  const getParticipantDetail = async (id) => {
    try {
      const response = await webviewServices.getParticipantDetail(id);
      console.log("getParticipantDetail", response);
      if (response) {
        const value = typeof response.data === "object" ? response.data : {};
        setData(value);
      }
    } catch (err) {
      console.error("getParticipantDetail", err);
    }
  };

  const PhotoItem = ({ image, thumb, group }) => (
    <LightgalleryItem group={group} src={image} thumb={thumb}>
      <img src={image} style={{ width: "100%", height: "100%", objectFit: "cover" }} alt={group} />
    </LightgalleryItem>
  );

  return (
    <Fragment>
      <Button onClick={toggle} color="link" className={"p-0 text-center"}>
        <i className="fas fa-info-circle" />
        &nbsp; Xem thông tin
      </Button>
      <Modal isOpen={modal} toggle={toggle} className={"table-modal modal-lg customize-modal"} backdrop={"static"}>
        <Form>
          <ModalHeader className="top-modal-header" toggle={toggle}>
            Thông tin cơ sở
          </ModalHeader>
          {data ? (
            <ModalBody className="p-2">
              <div className="d-flex flex-column mb-2 text-dark">
                <h6 className="font-weight-bold text-primary">Thông tin cơ sở</h6>
                <div className="pl-2 pr-2">
                  {data.name && (
                    <div className="row">
                      <div className={SET_COLUMN_4}>Tên cơ sở:</div>
                      <div className={SET_COLUMN_8}>{data.name}</div>
                    </div>
                  )}
                  {data.aquacultureBranchCode && (
                    <div className="row">
                      <div className={SET_COLUMN_4}>Mã số:</div>
                      <div className={SET_COLUMN_8}>{data.aquacultureBranchCode}</div>
                    </div>
                  )}
                  {data.phone && (
                    <div className="row">
                      <div className={SET_COLUMN_4}>Số điện thoại:</div>
                      <div className={SET_COLUMN_8}>{data.phone}</div>
                    </div>
                  )}
                  {data.fullAddress && (
                    <div className="row">
                      <div className={SET_COLUMN_4}>Địa chỉ:</div>
                      <div className={SET_COLUMN_8}>{data.fullAddress}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className="d-flex flex-column mb-2 text-dark">
                <h6 className="font-weight-bold text-primary">Quy mô sản xuất</h6>
                <div className="pl-2 pr-2">
                  {data.farmingArea > 0 && (
                    <div className="row">
                      <div className={SET_COLUMN_4}>Tổng diện tích:</div>
                      <div className={SET_COLUMN_8}>{data.farmingArea} ha</div>
                    </div>
                  )}
                  {data.hatcheryCultureTankHouseCount > 0 && (
                    <div className="row">
                      <div className={SET_COLUMN_4}>Tổng số trại/nhà:</div>
                      <div className={SET_COLUMN_8}>{data.hatcheryCultureTankHouseCount}</div>
                    </div>
                  )}
                  {data.hatcheryCultureTankCount > 0 && (
                    <div className="row">
                      <div className={SET_COLUMN_4}>Tổng số bể/hồ:</div>
                      <div className={SET_COLUMN_8}>{data.hatcheryCultureTankCount}</div>
                    </div>
                  )}

                  {data.energy && (
                    <div className="row">
                      <div className={SET_COLUMN_4}>Nguồn điện:</div>
                      <div className={SET_COLUMN_8}>{data.energy}</div>
                    </div>
                  )}

                  {data.waters && (
                    <div className="row">
                      <div className={SET_COLUMN_4}>Nguồn nước:</div>
                      <div className={SET_COLUMN_8}>{data.waters}</div>
                    </div>
                  )}

                  {data.sheetNo > 0 && (
                    <div className="row">
                      <div className={SET_COLUMN_4}>Số khoảnh:</div>
                      <div className={SET_COLUMN_8}>{data.sheetNo}</div>
                    </div>
                  )}

                  {data.landPlotNo > 0 && (
                    <div className="row">
                      <div className={SET_COLUMN_4}>Số thửa:</div>
                      <div className={SET_COLUMN_8}>{data.landPlotNo}</div>
                    </div>
                  )}

                  {data.vehicleNote && (
                    <div className="row">
                      <div className={SET_COLUMN_4}>Phương tiện:</div>
                      <div className={SET_COLUMN_8}>{data.vehicleNote}</div>
                    </div>
                  )}

                  {data.supplyAbility && (
                    <div className="row">
                      <div className={SET_COLUMN_4}>Năng lực cung ứng:</div>
                      <div className={SET_COLUMN_8}>{data.supplyAbility + " " + data.supplyAbilityUnit ?? ""}</div>
                    </div>
                  )}
                </div>
              </div>

              {data && data?.certifications?.length ? (
                <>
                  <div className="d-flex flex-column mb-2 text-dark">
                    <h6 className="font-weight-bold text-primary">Thông tin chứng nhận/chứng chỉ</h6>
                    <div className="position-relative">
                      <Swiper
                        grabCursor={true}
                        slidesPerView={"auto"}
                        spaceBetween={15}
                        pagination={{
                          type: "progressbar",
                        }}
                        className="mySwiper"
                      >
                        {data.certifications.map((c, i) => {
                          return (
                            <SwiperSlide key={i}>
                              <LightgalleryProvider>
                                <div className="position-relative">
                                  <PhotoItem image={process.env.REACT_APP_BASEURL + c.file} group="group1" />
                                  <div className="position-absolute w-100" style={{ top: "0", background: "#f5f5f5", opacity: ".8", fontSize: "16px" }}>
                                    <span className="fw-5">{c.certificationName}</span>
                                    <br />
                                    <span className="">{c.note}</span>
                                  </div>
                                </div>
                              </LightgalleryProvider>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </div>
                  </div>
                </>
              ) : null}

              {data && data?.files?.length ? (
                <>
                  <div className="d-flex flex-column mb-2 text-dark">
                    <h6 className="font-weight-bold text-primary">Hình ảnh giới thiệu</h6>
                    <div className="position-relative">
                      <Swiper
                        grabCursor={true}
                        slidesPerView={"auto"}
                        spaceBetween={15}
                        pagination={{
                          type: "progressbar",
                        }}
                        className="mySwiper"
                      >
                        {data.files.map((_, i) => {
                          return (
                            <SwiperSlide key={i}>
                              <LightgalleryProvider>
                                <div className="position-relative">
                                  {data.files.map((f, index) => {
                                    return (
                                      <span hidden={i + 1 != index + 1} key={index}>
                                        <PhotoItem image={process.env.REACT_APP_BASEURL + f.path} group="group2" />
                                      </span>
                                    );
                                  })}
                                </div>
                              </LightgalleryProvider>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </div>
                  </div>
                </>
              ) : null}

              {data?.location && (
                <div className="d-flex flex-column mb-2 text-dark">
                  <h6 className="font-weight-bold text-primary">Vị trí trên bản đồ</h6>
                  <div style={{ height: "300px", position: "relative" }}>
                    <GoogleApiWrapper
                      locations={[
                        {
                          lat: parseFloat(data.location.split(",")[0]),
                          lng: parseFloat(data.location.split(",")[1]),
                          name: data.name || "",
                        },
                      ]}
                    />
                  </div>
                </div>
              )}
            </ModalBody>
          ) : (
            <small className="text-info">Dữ liệu đang cập nhật...</small>
          )}

          <ModalFooter>
            <Button color="secondary" onClick={toggle} className="fn-w-600">
              <i className="fas fa-times mr5"></i>
              Đóng
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default ModalParticipantDetail;
