// import axios from 'axios';
import proxyService from '../proxy/proxy.service';
import cacheService from '../cache/cache.service';
import { LOCAL_STORAGE_USER_INFO, ROOT } from '../../../constants/index';
export const routeService = { getMenuLeft };

async function getMenuLeft() {
  const userRole = cacheService.get(LOCAL_STORAGE_USER_INFO)?.role;
  const url = userRole === ROOT ? '/api/app/menuManagement/menuData' : '/api/app/menuManagement/userMenu?menuLocation=2';

  let menuList = [];
  let mapped = [];
  return await proxyService.get(url).then(result => {
    if (userRole === ROOT)
      mapped = routeMap(result.data.menuTableField.filter(x => x.menuLocation === 2 && x.activation));
    else
      mapped = routeMap(result.data.filter(x => x.activation));

    // menuList = mapped;
    menuList = mapped.reduce((accumulator, current) => {
      if (!accumulator.some(x => x.code === current.code)) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);

    const childMenu = mapped.filter(x => x.parentMenuId)
    if (childMenu.length) {
      childMenu.forEach(item => {
        const parentMenu = menuList.find(x => x.id === item.parentMenuId);
        if (!parentMenu) return;
        parentMenu.children.push(item);
        menuList = menuList.filter(x => x.id !== item.id);
      })
    }
    menuList = menuList.filter(x => !x.parentMenuId);
    return menuList;
  });
};

function routeMap(obj) {
  return obj && obj.map((x, index) => {
    return {
      id: x.id,
      code: x.menuCode,
      collapse: x.collapse || false,
      name: x.menuName,
      url: x.url,
      icon: x.iconPath,
      children: x.childMenu && x.childMenu.length ? routeMap(x.childMenu) : [],
      state: x.menuCode,
      parentMenuId: x.parentMenuId
    }
  });
}
export default routeService;