import React from "react";
import { LightgalleryProvider, LightgalleryItem, useLightgallery } from "react-lightgallery";

export const LightGallery = (props) => {
  const images = props?.images || [];
  const PhotoItem = ({ image, thumb, group }) => (
    <div style={{ maxWidth: "250px", width: "200px", padding: "5px" }}>
      <LightgalleryItem group={group} src={image} thumb={thumb}>
        <img src={image} style={{ width: "100%" }} alt="" />
      </LightgalleryItem>
    </div>
  );
  
  const OpenButtonImages = () => {
    const { openGallery } = useLightgallery();
    return <div onClick={() => openGallery("group2")} className="text-direction-color fas fa-images cursor-pointer"></div>;
  };
  return (
    <div>
      <LightgalleryProvider>
        <div
          style={{
            display: "none",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {images.map((p, idx) => (
            <PhotoItem key={idx} image={process.env.REACT_APP_BASEURL + p.path || ""} group="group2" />
          ))}
        </div>
        <OpenButtonImages />
      </LightgalleryProvider>
    </div>
  );
};
