import axios from 'axios';
import { tokenService } from '../token/token.service';
import { history } from '../../helpers';
import loadingActions from '../../../actions/loading/loading.action';
import snackBarActions from '../../../actions/snack-bar/snackBar.action';
import errorMessageHandlingService from '../error-message-handling/errorMessageHandlingService';

const handleError = (response, store) => {
  const message = errorMessageHandlingService.getErrorMessage(response.data?.error);
  store.dispatch(snackBarActions.error(message, 'Lỗi', 3000));
}

const interceptorService = (store) => {
  let requestCounter = 0;
  axios.interceptors.request.use(
    (conf) => {
      requestCounter++;
      store.dispatch(loadingActions.open());
      const token = tokenService.getToken();
      conf.headers['Authorization'] = `Bearer ${token}`;
      return conf;
    },
    (error) => {
      requestCounter--;
      store.dispatch(loadingActions.close());
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use((response) => {
    requestCounter--;
    if (!requestCounter) {
      store.dispatch(loadingActions.close());
    }
    switch (response.config.method) {
      case 'put':
        if (response.config?.url.includes('/api/app/menuManagement')
          && !(response.config?.data instanceof FormData)
          && Array.isArray(JSON.parse(response.config?.data))) {
          let mess = '';
          const changeSuccess = response.data.filter(x => x.isSuccess);
          if (changeSuccess.length) {
            mess += `<span class='text-success'><i class="fas fa-check-circle"></i> Cập nhật thành công ${changeSuccess.length} dòng</span>`
          }
          const changeFail = response.data.filter(x => !x.isSuccess);
          if (changeFail.length) {
            mess += `<span class='text-danger'><i class="fas fa-times-circle"></i> Cập nhật thất bại ${changeFail.length} dòng</span>`
          }
          store.dispatch(snackBarActions.information(mess, 'Thông báo', 3000));
        } else {
          store.dispatch(snackBarActions.success('Cập nhật thành công', 'Thông báo', 3000));
        }
        break;
      case 'post':
        store.dispatch(snackBarActions.success('Tạo mới thành công', 'Thông báo', 3000));
        break;
      case 'delete':
        store.dispatch(snackBarActions.success('Xóa thành công', 'Thông báo', 3000));
        break;
      default:
        break;
    }
    return response;
  }, (error) => {
    requestCounter--;
    store.dispatch(loadingActions.close());
    if (typeof error.response === "undefined") {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
    switch (error.response.status) {
      case 403:
        return new Promise((resolve, reject) => {
          handleError(error.response, store);
          reject(error);
        });
      case 404:
        return new Promise((resolve, reject) => {
          store.dispatch(snackBarActions.error('404 - Not found', 'Lỗi', 3000));
          reject(error);
        });

      case 401:
        tokenService.clear();
        history.push('/login');
        return new Promise((resolve, reject) => {
          reject(error);
        });
      default:
        return new Promise((resolve, reject) => {
          handleError(error.response, store);
          reject(error);
        });
    }
  });
};
export default {
  interceptorService
};