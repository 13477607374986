import React, { Fragment, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
} from "reactstrap";
import swal from "sweetalert";
import { FormProvider, useForm } from "react-hook-form";
import proxyService from "../../../utilities/services/proxy/proxy.service";
import { DATATABLE_MODAL_CREATE_MODE } from "../../../constants/index";
import Blockchain from './../buttons/Blockchain';

const CreateModal = ({
  dataSource,
  component,
  whenClose,
  api,
  headers,
  uiConfigs,
  transform2BE,
  handleResponseData,
  className,
  classNameModal,
  text,
  isShowIcon,
  data,
  disabled,
  buttons,
}) => {
  const [modal, setModal] = useState(false);
  const [formConfigs, setFormConfigs] = useState({});
  const toggle = () => setModal(!modal);
  const externalCloseBtn = <button style={{ display: "none" }}></button>;
  const modalOptions = {
    color: uiConfigs && uiConfigs.color ? uiConfigs.color : "primary",
    toggleButtonIconClass: "fas fa-plus mr5",
    toggleButtonClass:
      uiConfigs && uiConfigs.toggleButtonClass
        ? uiConfigs.toggleButtonClass
        : "",
    headerText:
      uiConfigs && uiConfigs.headerText ? uiConfigs.headerText : "Thêm mới",
    toggleButtonText:
      uiConfigs && uiConfigs.toggleButtonText
        ? uiConfigs.toggleButtonText
        : "Thêm mới",
    submitButtonText: "Lưu lại",
    submitButtonIconClass: "fas fa-save mr5",
    confirmButtonText: "Xác nhận",
    confirmButtonIconClass: "far fa-check-circle mr5",
    disabled: uiConfigs && uiConfigs.disabled,
    modalConfirm: uiConfigs && uiConfigs.modalConfirm?.status ? uiConfigs.modalConfirm : false
  };
  const methods = {
    ...useForm(),
    formConfigs,
    setFormConfigs,
    modalMode: { mode: DATATABLE_MODAL_CREATE_MODE, create: true },
  };
  const { handleSubmit, reset, watch } = methods;

  const handleConfirm =  () => {
    try {
      handleSubmit(async(data) => {
        if(data[`${modalOptions.modalConfirm.condition.key}`] === modalOptions.modalConfirm.condition.value) {
          const confirmed = await swal({
            title: "Xác nhận",
            text: "",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            ...modalOptions.modalConfirm.body(data)
          });
          if(confirmed) {
            onSubmit(data)
          }
        }else {
          onSubmit(data)
        }
      })()
    }catch (err) {
      if(!err.message.includes("status")) {
        swal({ title: "Thất bại!", icon: "error", timer: 2000 });
      }else {
        toggle()
      }
    }
  }

  const onSubmit = async (data) => {
    const payload =
      typeof transform2BE === "function" ? await transform2BE(data) : data;
    proxyService
      .post(api, payload, headers)
      .then((result) => {
        if (result.data) {
          toggle();
          data.id = result.data.id;
          const resData =
            typeof handleResponseData === "function"
              ? handleResponseData(result.data)
              : data;
          if (
            data.hasOwnProperty("concurrencyStamp") &&
            result.data.concurrencyStamp
          ) {
            resData.concurrencyStamp = result.data.concurrencyStamp;
          }
          if (data.hasOwnProperty("creationTime") && result.data.creationTime) {
            data.creationTime = result.data.creationTime;
          }
          whenClose(resData);
        }
      })
      .catch((err) => console.log("err api", err.response));
  };

  React.useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  return (
    <Fragment>
      <Button onClick={toggle} color={!modalOptions.toggleButtonClass ? modalOptions.color : ""} className={modalOptions.toggleButtonClass || ""} disabled={disabled}>
        {isShowIcon !== undefined
          ? isShowIcon && <i className={modalOptions.toggleButtonIconClass}></i>
          : <i className={modalOptions.toggleButtonIconClass}></i>}
        {text ? text : modalOptions.toggleButtonText}
      </Button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={"table-modal modal-lg " + classNameModal || ""}
        backdrop={"static"}
        external={externalCloseBtn}
      >
        <FormProvider {...methods}>
          <Form className={className} onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader className="top-modal-header" toggle={toggle}>
              {modalOptions.headerText}
            </ModalHeader>
            <ModalBody>
              {React.createElement(component, {
                dataSource: dataSource, data
              })}
            </ModalBody>
            <ModalFooter>

              {buttons?.map((btn, indexBtn) => {
                if (btn.isBlockchain) {
                  if (watch(btn.name) === "0") {
                    return (
                      <Blockchain
                        key={indexBtn}
                        api={btn.api}
                        method={btn.method}
                        data={data}
                        headers={headers}
                        callback={toggle}
                        handleResponseData={handleResponseData}
                        whenClose={whenClose}
                        transform2BE={transform2BE}
                        controls={methods}
                      />
                    );
                  }
                  return "";
                }
              })}
              <Button
                type={!modalOptions.modalConfirm ? "submit": "button"}
                color="primary"
                className="fn-w-600"
                disabled={modalOptions ? modalOptions.disabled : (dataSource && dataSource.disabled)}
              // hidden={true}
                onClick={() => !modalOptions.modalConfirm ? {} : handleConfirm()}
              >
                <i className={modalOptions.submitButtonIconClass}></i>
                {modalOptions.submitButtonText}
              </Button>
              {/* <Button
                type="button"
                color="success"
                className="fn-w-600"
              >
                <i className={modalOptions.confirmButtonIconClass}></i>
                {modalOptions.confirmButtonText}
              </Button> */}
              <Button color="secondary" onClick={toggle} className="fn-w-600">
                <i className="fas fa-times mr5"></i>
                Đóng
              </Button>
            </ModalFooter>
          </Form>
        </FormProvider>
      </Modal>
    </Fragment>
  );
};
export default CreateModal;
