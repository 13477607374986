import React, { useState, useEffect } from "react";
import moment from "moment";
import webviewServices from "../../../../../utilities/services/webview";
import BlockchainSearchBar from "./BlockchainSearchBar";
import { Input } from "reactstrap";
import { JsonToTable } from "react-json-to-table";
import { Tabs, Tab } from "react-bootstrap";

const BlockchainInfo = ({ dataBlock, id, formConfigs, queryBlock }) => {
  const showSearchBar = formConfigs && formConfigs.searchBar === "hidden" ? false : true;
  const showStatus = formConfigs && formConfigs.status === "hidden" ? false : true;

  const [layoutState, setLayoutState] = useState("");
  const [query, setQuery] = useState("");
  const [data, setData] = useState(dataBlock || null);
  const getDetailBlock = async (query_id) => {
    try {
      const response = await webviewServices.getBlockDetail(query_id);
      console.log("getDetailBlock", response);
      if (response) {
        const value = typeof response.data === "object" ? response.data : {};
        const dataConvertJSON = JSON.stringify(value.data || {}, undefined, 4);
        value.json = dataConvertJSON;

        setData(value);
      } else {
        setData(null);
      }
      setQuery(query_id);
    } catch (err) {
      console.error("getDetailBlock", err);
    }
  };

  const onChangeQuery = (value) => setQuery(value);

  useEffect(() => {
    setLayoutState(formConfigs && formConfigs.layout ? formConfigs.layout || "auto|auto" : "auto|auto");

    if (id) {
      getDetailBlock(id);
    }
  }, [id, formConfigs.layout]);

  return (
    <div className="w-100 overflow-hidden">
      {showSearchBar && <BlockchainSearchBar query={query} handleChangeQuery={onChangeQuery} handleSubmit={() => getDetailBlock(query)} />}

      {data && data?.transactionId ? (
        <div className="form-group mb-0 text-dark">
          {data.blockNum ? (
            <div className="row">
              <label className={"mb-0 col-" + layoutState.split("|")[0]}>Block Id:</label>
              <div className={"col-" + layoutState.split("|")[1]}>{String(data.blockNum).padStart(6, "0")}</div>
            </div>
          ) : null}

          <div className="row">
            <label className={"mb-0 col-" + layoutState.split("|")[0]}>Transaction Id:</label>
            <div style={{ overflowWrap: "break-word" }} className={"col-" + layoutState.split("|")[1]}>
              {typeof queryBlock === "function" ? queryBlock() : data.transactionId}
            </div>
          </div>

          {data.blockchainDate && (
            <div className="row">
              <label className={"mb-0 col-" + layoutState.split("|")[0]}>Block date:</label>
              <div className={"col-" + layoutState.split("|")[1]}>{moment(data.blockchainDate).format("HH:mm:ss - DD/MM/YYYY")}</div>
            </div>
          )}

          {showStatus ? (
            !data.verified ? (
              <div className="row">
                <label className={"mb-0 col-" + layoutState.split("|")[0]}>Status:</label>
                <div className={"text-danger col-" + layoutState.split("|")[1]}>
                  <i className="fas fa-exclamation-triangle" />
                  <span className=""> Invalid</span>
                </div>
              </div>
            ) : (
              <div className="row">
                <label className={"mb-0 col-" + layoutState.split("|")[0]}>Status:</label>
                <div className={"text-success col-" + layoutState.split("|")[1]}>
                  <i className="fas fa-check-circle" /> Valid
                </div>
              </div>
            )
          ) : null}

          {data.data && (
            <div className="row">
              <div className="col-12 flex-column">
                <label>Data:</label>
                <div className="overflow-auto">
                  <Tabs defaultActiveKey="json" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="table" title={<i className="fas fa-table"></i>}>
                      <JsonToTable json={data.data} />
                    </Tab>
                    <Tab eventKey="json" title={<i className="fas fa-code"></i>}>
                      <Input className="form-control" value={data.json || {}} rows={15} type="textarea" disabled={true} />
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <span className="text-info">Không tìm thấy dữ liệu!</span>
      )}
    </div>
  );
};

export default BlockchainInfo;
