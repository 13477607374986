import React, { useState, Fragment, Suspense, useEffect } from "react";
import { history } from "../../utilities/helpers/index";
import { useDispatch } from "react-redux";
import { profileService } from "../../utilities/services/user/profile/profile.service";
import { profileAction } from "../../actions/profile-actions";
import { routeService } from "../../utilities/services/routes/route.service";

const Dashboard = React.lazy(() => import("./Dashboard"));

const FullLayout = () => {
  const [userProfile, setUserProfile] = useState({});
  const [menuLeft, setMenuLeft] = useState([]);

  const dispatch = useDispatch();
  const loadMenuLeft = async () => {
    const response = await routeService.getMenuLeft();
    if (response) {
      setMenuLeft(response);
    }
  };
  const getUserInfo = async () => {
    const response = await profileService.getUserInfo();

    if (response) {
      dispatch(profileAction.getUserInfo(response.data));
      setUserProfile(response.data);
    } else {
      dispatch(profileAction.getUserInfoFailed(response));
    }
  };

  useEffect(() => {
    getUserInfo();
    loadMenuLeft();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //
  if (!userProfile) {
    history.push("/login");
  }
  return (
    <Fragment>
      <Suspense fallback={<div>Loading...</div>}>{<Dashboard data={menuLeft} />}</Suspense>
    </Fragment>
  );
};

export default FullLayout;
