import React, { useState, useEffect } from "react";
import "./css/DatePickerTools.scss";
import options from "./date.json";
import DatePicker from "react-datepicker";

const style = {
  width: "100%",
  maxWidth: "180px",
  display: "inline-block",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
};

export const DatePickerTools = ({ hidden, inputPlaceholder, getDate, components }) => {
  const initialDate = new Date();
  const initialMonth = "nam";
  const initialStartDate = new Date(initialDate.getFullYear(), 0, 1);
  const initialEndDate = new Date(initialDate.getFullYear(), 12, 0);

  const [inputSearch, setInputSearch] = useState("");
  const [option, setOption] = useState(initialMonth);
  const [year, setYear] = useState(initialDate);
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  // const [dateRange, setDateRange] = useState({ min: initialStartDate, max: initialEndDate });

  const [initialized, setInitialized] = useState(false);
  const [filterValues, setFilterValues] = useState(null);

  const _onSubmit = (event) => {
    event.preventDefault();
    handleGetDate();
  };

  const handleGetDate = () => {
    if (typeof getDate === "function") {
      getDate({ from: startDate, to: endDate, inputSearch: inputSearch, filterValues });
    }
  };

  function timeConvert(month, date) {
    let y = date.getFullYear();
    let m, firstDayOfMonth, lastDayOfMonth;
    if (month <= 12) {
      m = month - 1;
      firstDayOfMonth = new Date(y, m, 1);
      lastDayOfMonth = new Date(y, m + 1, 0);
    } else if (month.includes("quy")) {
      m = month.substr(5, 2) - 1;
      firstDayOfMonth = new Date(y, m, 1);
      lastDayOfMonth = new Date(y, m + 3, 0);
    } else if (month.includes("6thang")) {
      m = month.substr(7, 1) - 1;
      firstDayOfMonth = new Date(y, m, 1);
      lastDayOfMonth = new Date(y, m + 6, 0);
    } else if (month.includes("9thang")) {
      firstDayOfMonth = new Date(y, 0, 1);
      lastDayOfMonth = new Date(y, 9, 0);
    } else {
      firstDayOfMonth = new Date(y, 0, 1);
      lastDayOfMonth = new Date(y, 12, 0);
    }

    setOption(month);
    return { start: firstDayOfMonth, end: lastDayOfMonth };
  }

  const handleOptionDate = (_m, _date) => {
    const time = timeConvert(_m, _date);
    // setDateRange({ min: time.start, max: time.end });
    setStartDate(time.start);
    setEndDate(time.end);
  };

  const _onChangeYear = (date) => {
    if (date) {
      setYear(date);
      handleOptionDate(option, date);
    }
  };

  const changeValue = (value, fieldName) => {
    if (filterValues != null) {
      filterValues[fieldName] = value;
      setFilterValues(filterValues);
    }
  };

  const initFilterValues = () => {
    if (components) {
      const controls = {};
      components.forEach((item) => {
        controls[item.name] = item.defaultValue ? item.defaultValue.value : "";
      });
      setFilterValues(controls);
    }
  };

  useEffect(() => {
    if (!initialized) {
      initFilterValues();
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      handleGetDate();
    }
  }, [
    initialized,
    // year,
    // option,
    // dateRange
  ]);

  return (
    <div className="container-fluid p-0" hidden={hidden}>
      <div className="form-row date-picker-wrapper">
        {components && components.length
          ? components.map((comp, idx) => {
            if (!comp) {
              return;
            }
            return (
              <div className="col-auto" key={idx}>
                <label>{comp.label}:</label>
                <select className="form-select form-control rounded" onChange={(e) => changeValue(e.target.value, comp.name)} style={style}>
                  <option value="">-- Tất cả --</option>
                  {comp.data.map((item, i) => (
                    <option key={i} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
            );
          })
          : null}
        <div className="col-auto">
          <label>Năm:</label>
          <DatePicker className="form-control rounded" selected={year} onChange={_onChangeYear} showYearPicker dateFormat="yyyy" yearItemNumber={4} />
        </div>
        <div className="col-auto">
          <label>Thời gian:</label>
          <select value={option} className="form-select form-control rounded" onChange={(e) => handleOptionDate(e.target.value, year)}>
            {options.date.map((option, i) => (
              <option key={i} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div className="col-auto">
          <label>Từ ngày:</label>
          <DatePicker
            name="startDate"
            autoComplete="off"
            dateFormat="dd/MM/yyyy"
            onChange={(date) => {
              setStartDate(date);
            }}
            placeholderText="--Chọn--"
            className="form-control rounded"
            selected={startDate}
            // minDate={dateRange.min}
            // maxDate={endDate}
            showDisabledMonthNavigation
          // isClearable
          />
        </div>
        <div className="col-auto">
          <label>Đến ngày:</label>
          <DatePicker
            name="startDate"
            autoComplete="off"
            dateFormat="dd/MM/yyyy"
            onChange={(date) => {
              setEndDate(date);
            }}
            placeholderText="--Chọn--"
            className="form-control rounded"
            selected={endDate}
            // minDate={startDate}
            // maxDate={dateRange.max}
            showDisabledMonthNavigation
          // isClearable
          />
        </div>

        <div className="col-auto d-flex align-items-center" style={{ minWidth: "350px" }}>
          {inputPlaceholder && (
            <div>
              <label>Tìm kiếm:</label>
              <input
                type="text"
                className="form-control mr-2"
                style={{ width: "200px" }}
                defaultValue={inputSearch}
                placeholder={inputPlaceholder}
                onBlur={(e) => {
                  setInputSearch(e.target.value);
                }}
              />
            </div>
          )}
          <div>
            <label></label>
            <div>
              <button type="button" className="btn btn-sm btn-secondary" title="Thống kê dữ liệu" onClick={_onSubmit}>
                <i className="fas fa-search"></i> Thống kê
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
