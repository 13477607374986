import cacheService from "../cache/cache.service";
import { LOCAL_STORAGE_TOKEN } from "../../../constants";

export const tokenService = {
    isAuthenticated,
    storeToken,
    clear,
    getToken
}

function isAuthenticated() {
    return getToken() !== null;
}

function storeToken(token) {
    cacheService.set(LOCAL_STORAGE_TOKEN, token);
}

function clear() {
    cacheService.remove(LOCAL_STORAGE_TOKEN);
}

function getToken() {
    return cacheService.get(LOCAL_STORAGE_TOKEN);
}
export default tokenService;