import proxyService from '../../proxy/proxy.service';


export const profileService = {
  getUserInfo,
  postChangePassword
}

async function getUserInfo() {
  const url = '/api/identity/my-profile';
  const result = await proxyService.get(url);
  return result;
};
async function postChangePassword(data) {
  const url = '/api/identity/my-profile/change-password';
  const result = await proxyService.post(url, data);
  return result;
};

export default profileService;