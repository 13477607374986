import React, { useEffect, useState } from "react";
import { RenderRow } from "./RenderRow";
//CSS
import "../../../assets/css/dynamic-table.scss";

export const Table = ({ template, dataCallBack, webview }) => {
  const { header, rows, cells } = template;
  console.log(`\r\nTABLE`, template);
  const [tbody, setTbody] = useState([]);
  const subHeader1st = [];
  const subHeader2nd = [];
  // console.log("-----template-----\r\n", template, "\r\n-----tbody-----\r\n", tbody);

  const getHeader = () => {
    try {
      const columns = header?.columns || [];
      return columns.map((col, key) => {
        col?.subcolumns && col.subcolumns.forEach((sub) => subHeader1st.push(sub));
        return (
          <th key={key} rowSpan={col?.subcolumns?.length ? 1 : 3} colSpan={col?.colspan || 1}>
            <div dangerouslySetInnerHTML={{ __html: col.name }}></div>
          </th>
        );
      });
    } catch (err) {
      console.error("getHeader", err);
    }
  };

  const getSubHeader1st = () => {
    try {
      return subHeader1st.map((col, key) => {
        col?.subcolumns && col.subcolumns.forEach((sub) => subHeader2nd.push(sub));
        return (
          <th key={key} rowSpan={col?.subcolumns?.length ? 1 : 2} colSpan={col?.colspan || 1} style={header?.style?.subheader1st || {}}>
            <div dangerouslySetInnerHTML={{ __html: col.name }}></div>
          </th>
        );
      });
    } catch (err) {
      console.error("getSubHeaders1", err);
    }
  };

  const getSubHeader2nd = () => {
    try {
      return subHeader2nd.map((col, key) => {
        return (
          <th style={{ fontSize: "12px" }} key={key}>
            <div dangerouslySetInnerHTML={{ __html: col.name }}></div>
          </th>
        );
      });
    } catch (err) {
      console.error("getSubHeaders", err);
    }
  };

  const getRowsData = () => {
    try {
      if (tbody.length) {
        return [...tbody].map((row, index) => {
          return <RenderRow key={index} onChangeRow={(row_data, row_index, type) => handleDataCallBack(row_data, row_index, type)} rowData={row} rowIndex={index} webview={webview} />;
        });
      } else {
        return (
          <tr>
            <td colSpan="3" className="text-info border-0 text-left">
              Không có dữ liệu
            </td>
          </tr>
        );
      }
    } catch (err) {
      console.error("getRowsData", err);
    }
  };

  const handleAddRow = () => {
    try {
      let newCells = [...cells].map((cell, col_index) => ({ ...cell, id: `${tbody.length}-${col_index}` }));
      let newTbody = [...tbody, newCells];
      setTbody(newTbody);
      dataCallBack(newTbody);
    } catch (err) {
      console.error("handle-add-row", err);
    }
  };

  const handleDataCallBack = (row_data, row_index, type) => {
    try {
      let data = [...tbody];
      if (type === "remove") {
        let newTBody = data.filter((row, index) => {
          if (index !== row_index) {
            return row;
          }
        });
        setTbody(newTBody);
        dataCallBack(newTBody);
      } else {
        data[row_index] = row_data;
        dataCallBack(data);
      }
    } catch (err) {
      console.log("handle-data-callback", err);
    }
  };

  // useEffect(() => {
  //   getStages();
  //   getWorkDefine();
  // }, []);

  useEffect(() => {
    let refreshTbody =
      rows?.data.map((row, row_index) => {
        return row.map((cell, col_index) => ({ ...cell, id: `${row_index}-${col_index}` }));
      }) || [];
    setTbody(refreshTbody);
  }, [template, rows]);

  return (
    <div role="diaries" aria-labelledby="" tabIndex="0">
      <table id="table-diary" className="table-bordered table-condensed text-center text-dark w-100" style={{ fontSize: 14 }}>
        <thead>
          <tr className="tr-header">{getHeader()}</tr>
          {subHeader1st.length ? <tr className="tr-subHeader1st">{getSubHeader1st()}</tr> : null}
          {subHeader2nd.length ? <tr className="tr-subHeader2nd">{getSubHeader2nd()}</tr> : null}
        </thead>
        <tbody>{getRowsData()}</tbody>
        {!webview && (
          <tfoot>
            <tr>
              <td className="border-0">
                <button className="btn btn-sm btn-success mt-2 mb-2" type="button" onClick={handleAddRow}>
                  Thêm
                </button>
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
};
