import proxyService from "../proxy/proxy.service";

const shrimpHatcheryServices = {
  getReportTemplate,
  getDiariesByProduction,
  getDiariesByBroodstock,
  getStages,
  getWorkDefine,
  getExportsDetail,
  getInfoDetailProduction,
  getDiseases,
  getProductions,
  getBroodstockDetail
};

let params = null;
let header = null;

function getReportTemplate(temp_id = null) {
  const url = `/api/app/reportTemplate/${temp_id}`;
  return proxyService.get(url, header, params);
}

function getDiariesByProduction(production_id = null) {
  const url = `/api/app/hatcheryDiaries/byProduction/${production_id}`;
  return proxyService.get(url, header, params);
}

function getDiariesByBroodstock(broodstock_id = null) {
  const url = `/api/app/hatcheryDiaries/byBroodstock/${broodstock_id}`;
  return proxyService.get(url, header, params);
}

function getStages() {
  const url = "/api/app/shrimpHatcheryStage";
  return proxyService.get(url, header, params);
}

function getWorkDefine() {
  const url = "/api/app/workDefine";
  params = {
    RoleId: "a4ce2874-5752-6a5a-099a-39fd9c49b1e4",
  };
  return proxyService.get(url, header, params);
}

function getExportsDetail(id = null) {
  const url = `/api/app/hatcheryExports/${id}`;
  return proxyService.get(url, header, params);
}

function getInfoDetailProduction(id = null) {
  const url = `/api/app/hatcheryProductions/${id}`;
  return proxyService.get(url, header, params);
}

function getDiseases(role_name = null) {
  const url = "/api/app/diseases";
  params = { RolesName: role_name, Active: true };
  return proxyService.get(url, header, params);
}

function getProductions(participant_id = null, tank_house_id = null, status = null) {
  params = {
    ParticipantId: participant_id,
    ShrimpHatcheryCultureTankHouseId: tank_house_id,
    Status: status
  };
  const url = "/api/app/hatcheryProductions";
  return proxyService.get(url, header, params);
}

function getBroodstockDetail(brood_stock_id = null) {
  const url = `/api/app/hatcheryBroodstock/${brood_stock_id}`;
  return proxyService.get(url, header, params);
}

export default shrimpHatcheryServices;
