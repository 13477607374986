import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Accordion from "../../../../commons/components/accordion";
//Services
import webviewServices from "../../../../utilities/services/webview";
//Types
import { WebviewTypes } from "../type";
import TraceabilityPurchasingTransport from "./TraceabilityPurchasingTransport";
import { LightGallery } from "./../../../../commons/components/modal-lightGallery/LightGallery";
import { history } from "./../../../../utilities/helpers/history";
import TraceabilityProcessingFactory from "./TraceabilityProcessingFactory";
import headerTraceability from "../../../../assets/images/bgTraceability_v.png";
import BlockchainInfo from "./modal-blockchain-detail/BlockchainInfo";

const Traceability = () => {
  const search = useLocation().search;
  const type = new URLSearchParams(search).get("type");
  const id = new URLSearchParams(search).get("id");
  const date = new URLSearchParams(search).get("date");
  const queryBlockchain = new URLSearchParams(search).get("queryBlockchain");
  const goback = new URLSearchParams(search).get("goback");

  const [data, setData] = useState(null);

  const styleHeader = {
    backgroundImage: "url(" + headerTraceability + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top",
    backgroundSize: "cover",
    position: "relative",
    overflow: "hidden",
    minHeight: "370px",
    maxHeight: "370px",
  };

  const getDataTraceability = async (id, date) => {
    try {
      let response = null;
      let result = [];
      if (type === WebviewTypes.farmId) {
        response = await webviewServices.getTraceabilityByFarmId(id, date);
      }
      if (type === WebviewTypes.transportLotId) {
        response = await webviewServices.getTraceabilityByTransportLotId(id, date);
      }
      if (type === WebviewTypes.lotId) {
        response = await webviewServices.getTraceabilityByLotId(id, date);
      }
      ////
      if (response) {
        const value = response.data || {};
        Object.entries(value).forEach(([key, value]) => {
          if (key === "blockchain") {
            result.unshift({ value, key });
          } else {
            result.push({ value, key });
          }
        });
      }
      setData(result);
    } catch (err) {
      console.error("getDataTraceabilityByFarmId", err);
    }
  };

  const translateKey = (key) => {
    switch (key) {
      case "hatcherys":
        return "Nguồn gốc tôm giống";
      case "farm":
        return "cơ sở nuôi tôm sinh thái";
      case "farmGroups":
        return "cơ sở nuôi tôm sinh thái";
      case "transport":
        return "cơ sở thu mua - vận chuyển";
      case "processing":
        return "nhà máy chế biến";
      case "blockchain":
        return "qr code - mã truy xuất";
      default:
        return;
    }
  };

  const transferComponent = (key, value) => {
    if (type === WebviewTypes.farmId) {
      return <TraceabilityPurchasingTransport title={key} value={value} showDetail={renderCheckDetail} showImage={renderFiles} />;
    }
    if (type === WebviewTypes.lotId || type === WebviewTypes.transportLotId) {
      if (key === "farmGroups" && !value.length) {
        value = [value];
      }
      return <TraceabilityProcessingFactory title={key} value={value} showDetail={renderCheckDetail} showImage={renderFiles} type={type} />;
    }
  };

  const renderCheckDetail = (path, active) => {
    if (!active) {
      return <span onClick={() => handleRedirectWebview(path)} className="text-right text-info cursor-pointer" style={{ textDecoration: "underline" }}>{`Xem chi tiết >>`}</span>;
    }
  };

  const renderFiles = (files) => {
    if (files?.length) {
      return (
        <span className="mr-2 d-flex align-items-center">
          <LightGallery images={files} /> /{files.length < 10 ? "0" : ""}
          {files.length}
        </span>
      );
    }
  };

  const handleRedirectWebview = (path = null) => {
    if (path) {
      history.push(path);
    }
  };

  useEffect(() => {
    getDataTraceability(id, date);
  }, [id, date]);
  
  return (
    <>
      {type === WebviewTypes.blockchain && <BlockchainInfo  id={queryBlockchain} formConfigs={{layout: "3|9", status : 'hidden'}}/>}
      <div style={{ margin: "0 auto", maxWidth: "500px" }}>
        {type === WebviewTypes.lotId && (
          <div className="w-100 text-center">
            <div className="text-light bg-traceability" style={styleHeader}></div>
            <h3 className="fw-5 mt-2" style={{ color: "#2f5132" }}>
              KẾT QUẢ TRUY XUẤT
            </h3>
          </div>
        )}

        <div className="accordion mb-5">
          {data &&
            data.map(({ key, value }, index) => {
              if (key && value) {
                if (type === WebviewTypes.farmId && index === 2) {
                  return null;
                }
                return <Accordion key={index} title={translateKey(key)} component={transferComponent(key, value)} />;
              }
              return null;
            })}
        </div>
      </div>
      {type === WebviewTypes.lotId && (
        <div className="card" style={{ width: "100%", position: "absolute", bottom: 0, left: "50%", transform: "translateX(-50%)" }}>
          <div className="card-footer text-muted text-center">Ban quản lý Dự án GCF tỉnh Cà Mau</div>
        </div>
      )}
      {goback === "true" ? (
        <div className="backTop cd-top--is-visible cd-top--fade-out">
          <i className="fa fa-angle-left" onClick={() => window.history.back()}></i>
        </div>
      ) : null}
    </>
  );
};
export default Traceability;
