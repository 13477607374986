import React, { Fragment, useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from "reactstrap";
import { FormProvider, useForm } from "react-hook-form";
import swal from "sweetalert";
import proxyService from "../../../utilities/services/proxy/proxy.service";
import { Textbox } from "../../../commons/components/controls/index";
import { DATATABLE_MODAL_UPDATE_MODE } from "../../../constants/index";
import Blockchain from "../buttons/Blockchain";

const UpdateModal = ({
  dataSource,
  data,
  component,
  whenClose,
  api,
  headers,
  uiConfigs,
  transform2BE,
  handleResponseData,
  className,
  text,
  isShowIcon,
  classNameModal,
  buttons,
  disabled,
}) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [model, setModel] = useState({});
  const [formConfigs, setFormConfigs] = useState({});
  const modalOptions = {
    toggleColor: "link",
    submitColor: "success",
    toggleButtonIconClass: uiConfigs && uiConfigs.toggleButtonIconClass ? uiConfigs.toggleButtonIconClass : "fas fa-edit",
    toggleButtonClass: uiConfigs && uiConfigs.toggleButtonClass ? uiConfigs.toggleButtonClass : "",
    disabled: uiConfigs?.disabled,
    headerText: uiConfigs && uiConfigs.headerText ? uiConfigs.headerText : "Cập nhật",
    toggleButtonText: "",
    submitButtonText: uiConfigs && uiConfigs.submitButtonText ? uiConfigs.submitButtonText : "Cập nhật",
    submitButtonIconClass: "fas fa-edit mr5",
    showSubmitButton: uiConfigs && typeof uiConfigs.showSubmitButton === "function" ? uiConfigs.showSubmitButton(data) : true,
    modalConfirm: uiConfigs && uiConfigs.modalConfirm?.status ? uiConfigs.modalConfirm : false
  };
  const methods = {
    ...useForm({ initDefaultValues: model }),
    formConfigs,
    setFormConfigs,
    modalMode: { mode: DATATABLE_MODAL_UPDATE_MODE, update: true },
  };
  const { handleSubmit, reset, watch } = methods;

  const handleConfirm =  () => {
    try {
      handleSubmit(async(data) => {
        if(data[`${modalOptions.modalConfirm.condition.key}`] === modalOptions.modalConfirm.condition.value) {
          const confirmed = await swal({
            title: "Xác nhận",
            text: "",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            ...modalOptions.modalConfirm.body(data)
          });
          if(confirmed) {
            onSubmit(data)
          }
        }else {
          onSubmit(data)
        }
      })()
    }catch (err) {
      if(!err.message.includes("status")) {
        swal({ title: "Thất bại!", icon: "error", timer: 2000 });
      }else {
        toggle()
      }
    }
  }

  const onSubmit = async (data) => {
    const payload = typeof transform2BE === "function" ? await transform2BE(data) : data;
    proxyService
      .put(api + `/${data.id}`, payload, headers)
      .then((result) => {
        if (result.data) {
          toggle();
          const resData = typeof handleResponseData === "function" ? handleResponseData(result.data) : data;
          if (data.hasOwnProperty("concurrencyStamp") && result.data.concurrencyStamp) {
            resData.concurrencyStamp = result.data.concurrencyStamp;
          }
          whenClose(resData);
        }
      })
      .catch((err) => console.log("err api", err));
  };

  useEffect(() => {
    setModel(data);
    reset(model);
  }, [data, model, reset]);

  return (
    <Fragment>
      <Button
        // disabled={disabled}
        onClick={() => {
          !disabled && toggle();
        }}
        color={modalOptions.toggleColor}
        className={"p-0 text-left " + (disabled ? "text-secondary" : "") + (modalOptions.toggleButtonClass || "")}
      >
        {isShowIcon && <i className={modalOptions.toggleButtonIconClass}> </i>} {text}
      </Button>
      <Modal isOpen={modal} toggle={toggle} className={"table-modal modal-lg " + classNameModal || ""} backdrop={"static"}>
        <FormProvider {...methods}>
          <Form className={className} onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader className="top-modal-header" toggle={toggle}>
              {modalOptions.headerText}
            </ModalHeader>
            <ModalBody>
              <Textbox
                label=""
                name="id"
                hidden={true}
                configs={{
                  direction: "horizontal",
                  layout: "3|9",
                }}
              />
              {React.createElement(component, { dataSource, data })}
            </ModalBody>
            <ModalFooter>
              {buttons?.map((btn, indexBtn) => {
                if (btn.isBlockchain) {
                  if (!data?.transactionId) {
                    if (watch(btn.name) === "0") {
                      return (
                        <Blockchain
                          key={indexBtn}
                          api={btn.api}
                          method={btn.method}
                          data={data}
                          headers={headers}
                          callback={toggle}
                          handleResponseData={handleResponseData}
                          whenClose={whenClose}
                          transform2BE={transform2BE}
                          controls={methods}
                        />
                      );
                    }
                    return "";
                  }
                  return (
                    <Button key={indexBtn} type="button" className="text-dark" color="warning">
                      <i className="fas fa-lock"></i> Đã lưu Blockchain
                    </Button>
                  );
                }
              })}

              {modalOptions.showSubmitButton ? (
                <Button color={modalOptions.submitColor} className="fn-w-600" disabled={modalOptions.disabled} 
                  type={!modalOptions.modalConfirm ? "submit": "button"}
                  onClick={() => !modalOptions.modalConfirm ? {} : handleConfirm()}
                >
                  <i className={modalOptions.submitButtonIconClass}></i>
                  {modalOptions.submitButtonText}
                </Button>
              ) : null}

              <Button color="secondary" onClick={toggle} className="fn-w-600">
                <i className="fas fa-times mr5"></i>
                Đóng
              </Button>
            </ModalFooter>
          </Form>
        </FormProvider>
      </Modal>
    </Fragment>
  );
};
export default UpdateModal;
