import proxyService from "../../proxy/proxy.service";
const accountManagementService = {
  getAll,
  postNewPassword,
  getProfile,
  postAvatar
};
async function getAll() {
  const url = "/api/identity/users";
  const result = await proxyService
    .get(url)
    .then(async (result) => result.data);
  return result;
  // return proxyService.getJSON('/assets/datas/user-info/accounts.json').then(async result => result.data);
}
async function getProfile() {
  const url = "/api/identity/my-profile";
  const result = await proxyService
    .get(url)
    .then(async (result) => result.data);
  console.log("profile", result);
  return result;
}
async function postNewPassword(params) {
  const URL = "/api/identity/my-profile/change-password";
  return await proxyService.post(URL, params);
}
async function postAvatar(params) {
  const URL = "/api/identity/users/avatar";
  return await proxyService.post(URL, params);
}
export default accountManagementService;
