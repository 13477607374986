/* eslint-disable no-extend-native */
Array.prototype.basicOptionFormat = function (label, value) {
  if (Array.isArray(this)) {
    return this.map(item => {
      return {
        label: item[label],
        value: item[value || label]
      }
    });
  }
  return;
}

String.prototype.normalizeStr = function () {
  return this
    .valueOf()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D')
    .toLowerCase()
    .trim();
}

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
}
// Object.prototype.basicOptionFormat = function (label, value) {
//   if (typeof(this) === 'object') {
//     return { label: this[label], value: this[value || label] };
//   }
//   return;
// }