// import proxyService from "../proxy/proxy.service";
import proxyService from "../proxy/proxy.service";
export const FarmHarvestService = {
  getListParticipant,
  getInfo,
  getListConfirm,
  getDetail,
  getDayIncludes,
  getForFarm
};
async function getListParticipant(params = null) {
  const url = "/api/app/farmHarvests/forCreate";
  const result = await proxyService.get(url, null, params);
  return result;
}
async function getInfo(params = null) {
  const url = "/api/app/farmHarvests";
  const result = await proxyService.get(url, null, params);
  return result;
}
async function getListConfirm(params = null) {
  const url = "/api/app/farmHarvests/forConfirm";
  const result = await proxyService.get(url, null, params);
  return result;
}

async function getDetail(params = null) {
  const url = "/api/app/farmHarvestDetail/harvestByListGroup";
  return await proxyService.get(url, null, params);
}

async function getDayIncludes(params = null) {
  const url = "/api/app/farmHarvestDetail/dayHarvest";
  return await proxyService.get(url, null, params);
}

async function getForFarm(params = null) {
  const url = "/api/app/farmHarvestDetail/harvestForFarm";
  return await proxyService.get(url, null, params);
}
export default FarmHarvestService;
