import { combineReducers } from "redux"
import settings from "./settings/settingsReducers"
import user from "./userReducer"
import menuReducers from "./routes/routeReducers"
import formFieldReducers from "./form-field/formFieldReducer"
import modalReducer from "./modal/modalReducer"
import loadingReducer from "./loading/loadingReducer"
import snackBarReducer from "./snack-bar/snackBarReducer"
import anouncementReducer from "./anouncements/anouncementReducers"
import datatableReducer from "./datatable/datatableReducer"

const rootReducers = combineReducers({
  datatableReducer,
  settings,
  user,
  menuReducers,
  loadingReducer,
  formFieldReducers,
  modalReducer,
  anouncementReducer,
  snackBarReducer,
})

export default rootReducers
