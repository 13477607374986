import { createStore, applyMiddleware } from "redux"
import { createLogger } from "redux-logger"
import rootReducers from "../reducers/rootReducers"
import interceptorService from "../utilities/services/proxy/interceptor.service"
import thunk from 'redux-thunk'
const logger = createLogger()

export function configureStore(initialState) {
  const middleWares = [thunk]

  const store = createStore(rootReducers, initialState, applyMiddleware(...middleWares, logger))

  interceptorService.interceptorService(store)

  return store
}
