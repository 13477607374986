import React from "react";
//Commons
import Util from "../../../../utilities/services/utils/utils.service";
import { WebviewTypes } from "../type";

const TraceabilityPurchasingTransport = ({ title, value, showDetail, showImage }) => {
  const SET_COLUMN_5 = "col-5 fw-5";
  const SET_COLUMN_7 = "col-7";
  return (
    <React.Fragment>
      {title === "hatcherys" &&
        value.map((v, index) => (
          <React.Fragment key={index}>
            <li className="list-group-item text-center title-content">
              <p className="fw-6">
                {v.name} {!v.isForeign ? <i className="fas fa-check-circle text-success" /> : ""}
              </p>
              <p className="fw-4">{v.address}</p>
            </li>
            {v?.stockings.map((s, i) => {
              return (
                <React.Fragment key={i}>
                  <li className="list-group-item " key={index}>
                    <div className="d-flex align-items-center justify-content-between" style={{ position: "relative" }}>
                      <label className="fw-5 mb-0">Đợt thả nuôi {Util.yyyymmddFormatTimeString(s.stockingDate)}</label>
                      <div className="d-flex align-items-center">{s?.files?.length ? showImage(s.files) : null}</div>
                    </div>
                    <div className="d-flex flex-column pl-2">
                      {s.name && (
                        <div className="row">
                          <div className={SET_COLUMN_5}>Cơ sở giống:</div>
                          <div className={SET_COLUMN_7}>{s.name}</div>
                        </div>
                      )}

                      <div className="row">
                        <div className={SET_COLUMN_5}>Tổng số lượng:</div>
                        <div className={SET_COLUMN_7}>{s.quantity > 0 ? Util.FormatCurrency(s.quantity) + " con" : 0}</div>
                      </div>

                      {/* {s.name ? <span>Cơ sở giống : {s.name}</span> : null}
                      <span>Tổng số lượng: {s.quantity > 0 ? s.quantity + " nghìn con" : s.quantity}</span> */}
                      <span className="d-flex justify-content-between">
                        {s.note ? "Ghi chú:" + s.note : <span />}
                        {showDetail(`/diaries?type=${WebviewTypes.farmStockingId}&id=${s.id}&goback=true`, v.isForeign)}
                      </span>
                    </div>
                  </li>
                </React.Fragment>
              );
            })}
          </React.Fragment>
        ))}

      {title === "farm" && (
        <>
          <li className="list-group-item text-center title-content">
            <p className="fw-6">Cơ sở nuôi {value.name}</p>
            <p className="fw-4">{value.address}</p>
            <p>Ngày thu hoạch: {Util.yyyymmddFormatTimeString(value?.harvestDate)}</p>
            {value?.size?.length > 0 && (
              <table className="table table-bordered mt-1 bg-white" id="datatable" style={{ fontSize: "14px" }}>
                <thead>
                  <tr>
                    <td scope="col" className="text-center">
                      STT
                    </td>
                    <td scope="col" className="text-center">
                      Loại (con/kg)
                    </td>
                    <td scope="col" className="text-center">
                      Trọng lượng (kg)
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {value.size.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        {item.value > 0 && (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.sizeName}</td>
                            <td>{item.value}</td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })}
                  <tr>
                    <td className="fw-5" colSpan={2}>
                      Tổng sản lượng
                    </td>
                    <td>{value.total}</td>
                  </tr>
                </tbody>
              </table>
            )}
          </li>
          {value?.diaris.map((d, index) => {
            return (
              <React.Fragment key={index}>
                {index === 0 ? (
                  <li className="list-group-item border-bottom-0 pb-0">
                    <h5 className="fw-6 text-info text-center mb-0">Nhật ký ghi nhận</h5>
                  </li>
                ) : (
                  ""
                )}
                <li className="list-group-item " key={index}>
                  <div className="d-flex align-items-center justify-content-between" style={{ position: "relative" }}>
                    <label className="fw-5 mb-0">
                      {d.workName} ngày {Util.yyyymmddFormatTimeString(d.implementationDate)}
                    </label>
                    <div className="d-flex align-items-center">{d?.files?.length ? showImage(d.files) : null}</div>
                  </div>
                  <div className="d-flex flex-column pl-2">
                    {d?.jobs.map((j, index) => (
                      <React.Fragment key={index}>
                        {j.value && j.value > 0 ? (
                          <div className="row">
                            <div className={SET_COLUMN_5}>{j.name}:</div>
                            <div className={SET_COLUMN_7}>
                              {j.value} {j.unit}
                            </div>
                          </div>
                        ) :
                        null}
                      </React.Fragment>
                    ))}
                  </div>
                </li>
              </React.Fragment>
            );
          })}
        </>
      )}
    </React.Fragment>
  );
};

export default TraceabilityPurchasingTransport;
