import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { useDebouncedCallback } from "use-debounce";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dtActions from "../../../actions/datatable/datatable.action";
import proxyService from "../../../utilities/services/proxy/proxy.service";
import cacheService from "../../../utilities/services/cache/cache.service";
import moment from "moment";
// import { Link } from "react-router-dom";

const FilterTools = ({ metadata, data, inputFilterBy, setBack, className, hasChangeData, setHasChangeData, url }) => {
  const dtReducer = useSelector((x) => x.datatableReducer);
  const serverSideMode = !!metadata.serverSide;
  const initRender = useRef(true);
  const dispatch = useDispatch();
  const cacheFiltering = serverSideMode && metadata.serverSide.cacheFiltering;
  const [cache, setCache] = useState(cacheService.get(document.location.pathname));
  const { components, inputPlaceholder, width } = metadata.filterTools;
  /*
  Dùng cho load lại dữ liệu không refresh lại fitler
   dùng document.getElementById("find-btn").click();
  */
  const [find, setFind] = useState(false);

  const [initialized, setInitialized] = useState(false);
  /* 
  Lưu các giá trị cần lọc trong các dropdown
  */
  const [filterValues, setFilterValues] = useState(null);
  /*
  Lưu toàn bộ dữ liệu gốc -> để mỗi khi reset bộ lọc sẽ hiển thị lại toàn bộ dữ liệu 
  */
  const [original, setOriginal] = useState([]);
  /*
  Lưu các giá trị cần lọc trong input 
  */
  const [inputSearch, setInputSearch] = useState("");

  // Date
  const [date, setDate] = useState("");
  const [endDate, setEndDate] = useState("");
  // let initialDate = new Date();
  /* 
  Bộ lọc 
  */
  const filterator = useCallback(
    (rows) => {
      const keys = Object.keys(filterValues);
      keys.forEach((key) => {
        const component = components.find((x) => x.name === key);
        if (filterValues[key]?.toString().length) {
          rows = rows.filter((x) => {
            const value = levelResolver(x, component.filterByDataField);
            if (value === filterValues[key]) {
              return x;
            }
            return null;
          });
        }
      });
      if (inputSearch) {
        let keywords = inputSearch;
        keywords = keywords.normalizeStr();
        return rows.filter((item) => {
          return inputFilterBy.some((col) => {
            return levelResolver(item, col.filterBy)?.toString().normalizeStr().includes(keywords);
          });
        });
      }

      return rows;
    },
    [components, filterValues, inputFilterBy, inputSearch]
  );

  /* 
  Lấy giá trị của thuộc tính trong đối tượng theo đường dẫn truyền vào 
  */
  const levelResolver = (obj, path = "") => {
    path = path.split(".");
    let current = obj;
    while (path.length) {
      if (typeof current !== "object") {
        return undefined;
      }
      current = current[path.shift()];
    }
    return current || "";
  };

  /* 
  Gán lại giá trị cần lọc trước khi đưa vào bộ lọc
  */
  const changeValue = (value, fieldName) => {
    if (filterValues != null) {
      filterValues[fieldName] = value;
      setFilterValues(filterValues);
    }
    return filterValues;
  };

  /* 
  Sau khi người dùng ngưng nhập ký tự trong input -> 250ms sau sẽ lọc dữ liệu 
  */
  const debounceTime = serverSideMode ? 300 : 150;
  const debounced = useDebouncedCallback(() => {
    if (serverSideMode) {
      dispatch(dtActions.textSearchChange(inputSearch));
      initRender.current = false;
    } else {
      setBack(filterator(original));
    }
  }, debounceTime);

  const onFilter = (value, fieldName) => {
    const valueChanged = changeValue(value, fieldName);
    if (serverSideMode) {
      dispatch(dtActions.optionsSearchChange(valueChanged));
      initRender.current = false;
    } else {
      setBack(filterator(original));
    }
  };

  /* 
  Xóa toàn bộ dữ liệu cần lọc và hiển thị toàn bộ dữ liệu lên bảng 
  */
  const refresh = () => {
    if (serverSideMode) {
      dispatch(dtActions.offFilter());
      initRender.current = false;
      cacheService.remove(document.location.pathname);
      setCache(null);
    } else {
      setBack(original);
    }
    initFilterValues();
    setDate("");
    setEndDate("");
  };

  /*
  Khởi tạo biến lưu trữ giá trị cần lọc 
  */
  const initFilterValues = useCallback(() => {
    const controls = {};
    if (cacheFiltering) {
      setInputSearch(cache?.textSearch || "");
      components.forEach((item) => {
        controls[item.name] = cache && cache.optionsSearch ? cache.optionsSearch[item.name] : item.defaultValue ? item.defaultValue.value : "";
      });
    } else {
      setInputSearch("");
      components.forEach((item) => {
        controls[item.name] = item.defaultValue ? item.defaultValue.value : "";
      });
    }
    setFilterValues(controls);
    // dispatch(dtActions.optionsSearchChange(controls));
  }, [cache, cacheFiltering, components]);

  useEffect(() => {
    // console.log("dtReducer.query", dtReducer.query);
    if (!initRender.current || find) {
      // let query = dtReducer.query;
      // if (query === "" && find) {
      //    for (const [key, value] of Object.entries(url.query)) {
      //      if (value !== null && value !== undefined && value !== "") {
      //        query += `&${key}=${value}`;
      //      }
      //    }
      // }
      proxyService.get(metadata.serverSide.api + dtReducer.query).then((result) => {
        const transformed = metadata.serverSide.transformStrategy(result.data.items);
        dispatch(dtActions.updateTotalRows(result.data.totalCount));
        setBack(transformed);
        initRender.current = true;
      });

      setFind(false);
    }

    if (!initialized) {
      if(!serverSideMode && data && data.length) {
        setOriginal(data)
        initFilterValues();
        setInitialized(true);
      }
      if(serverSideMode){
        initFilterValues();
        setInitialized(true);
      }
    }
    
    if (hasChangeData) {
      setOriginal(data);
      setHasChangeData(false);
      setBack(filterator(data));
    }
  }, [
    initialized,
    data,
    components,
    initFilterValues,
    hasChangeData,
    setHasChangeData,
    setBack,
    filterator,
    original,
    metadata.serverSide,
    dtReducer.query,
    dispatch,
    find,
  ]);

  return (
    <div className={className + " filter-tools"}>
      {components.map((comp) => {
        if (!comp) {
          return;
        }
        if (comp.custom === true) {
          return (
            <div key={comp.name + "div"} className="item" style={{ width: comp.width || "200px" }} hidden={comp.hidden}>
              {comp.component(comp)}
            </div>
          );
        }
        if (comp.isDateTime === true)
          return (
            <div key={comp.name + "div"} className="item" style={{ width: comp.width || "200px" }} hidden={comp.hidden}>
              <DatePicker
                yearItemNumber={comp.yearItemNumber}
                autoComplete="off"
                name={comp.name}
                dateFormat={comp.dateFormat}
                onChange={(date) => {
                  setDate(date);
                  // comp.change
                  //   ? comp.change(date?.valueOf() / 1000 || 0, onFilter)
                  //   : onFilter(date?.valueOf() / 1000 || 0, comp.name);
                  let d = date ? moment(date?.valueOf()).format("YYYY-MM-DD") : "";
                  comp.change ? comp.change(d || "", onFilter) : onFilter(d || "", comp.name);
                }}
                placeholderText={comp.placeholder || "Chọn"}
                className="form-control mr-3 rounded pr-0 pb-1"
                selected={date}
                showYearPicker={comp.showYearPicker || false}
                excludeDates={comp.excludeDates || []}
                includeDates={comp.includeDates || null}
              // value={comp.value}
              // isClearable
              />
            </div>
          );
        if (comp.isEndDate === true) {
          return (
            <div key={comp.name + "div"} className="item" style={{ width: comp.width || "200px" }} hidden={comp.hidden}>
              <DatePicker
                yearItemNumber={comp.yearItemNumber}
                name={comp.name}
                dateFormat={comp.dateFormat}
                onChange={(date) => {
                  setEndDate(date);
                  // onFilter(date?.valueOf() / 1000 || 0, comp.name);
                  // comp.change &&
                  //   comp.change(date?.valueOf() / 1000 || 0, onFilter);

                  let d = date ? moment(date?.valueOf()).format("YYYY-MM-DD") : "";
                  comp.change ? comp.change(d || "", onFilter) : onFilter(d || "", comp.name);
                }}
                placeholderText={comp.placeholder}
                className="form-control mr-3 rounded pr-0 pb-1"
                selected={endDate}
                excludeDates={comp.excludeDates || []}
                includeDates={comp.includeDates || null}
              // isClearable
              />
            </div>
          );
        }

        return (
          <div key={comp.name + "div"} className="item" style={{ width: comp.width || "200px" }} hidden={comp.hidden}>
            <Select
              name={comp.name}
              onChange={(e) => {
                onFilter(e.value, comp.name);
                comp.change && comp.change(e.value);
              }}
              options={comp.data}
              placeholder={comp.placeholder}
              value={
                filterValues && filterValues[comp.name]?.toString().length
                  ? {
                    label: comp.data.find((x) => x.value === filterValues[comp.name])?.label,
                    value: filterValues[comp.name],
                  }
                  : comp?.defaultValue
                    ? {
                      label: comp.defaultValue?.label || "",
                      value: comp.defaultValue?.value || "",
                    }
                    : null
              }
            />
          </div>
        );
      })}
      {inputPlaceholder && (
        <div className="item">
          <input
            type="text"
            className="form-control"
            value={inputSearch}
            style={{ width: width ?? "auto" }}
            placeholder={inputPlaceholder}
            onChange={(e) => {
              setInputSearch(e.target.value);
              debounced.callback(e.target.value);
            }}
          />
        </div>
      )}
      {components && !components.length && !inputPlaceholder ? null : (
        <>
          <button
            type="button"
            className="btn btn-sm btn-info"
            id="refresh-btn"
            title="Tải lại"
            onClick={() => {
              refresh();
            }}
          >
            <i className="fas fa-sync-alt"></i> Tải lại
          </button>

          <button
            type="button"
            className="btn btn-sm btn-info"
            id="find-btn"
            title="Tải lại"
            hidden={true}
            onClick={() => {
              // if (serverSideMode) {
              //   dispatch(dtActions.textSearchChange(inputSearch));
              //   initRender.current = false;
              // } else {
              //   setBack(filterator(original));
              // }
              setFind(true);
            }}
          >
            <i className="fas fa-sync-alt"></i> Tìm kiếm
          </button>
        </>
      )}
      {/* {!metadata.crudButtons?.create?.active && !metadata.crudButtons?.delete?.active && !metadata.crudButtons?.updateList?.active && url && !url?.query?.RoleId && (
        <div style={{margin: 'auto 0 auto auto'}}>
          <Link to={url?.path}>
            <button className="btn btn-primary">Danh sách cơ sở</button>
          </Link>
        </div>
      )} */}
    </div>
  );
};

export default FilterTools;
