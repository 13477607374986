import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "reactstrap";
import { Controller, useFormContext } from "react-hook-form";
import EM from "../error-message/errorMessage";
import "../../../assets/css/controls/textbox.scss";
import { Fragment } from "react";

const TextBox = ({
  label,
  formConfigs,
  rules,
  name,
  defaultValue,
  hidden,
  className,
  classFormName,
  textAreaRow,
  placeholder,
  type,
  change,
  value,
  disabled,
  inputAppend,
}) => {
  const methods = useFormContext();
  const [nameState, setNameState] = useState("");
  const [labelState, setLabelState] = useState("");
  const [rulesState, setRulesState] = useState({});
  const [defaultValueState, setDefaultValueState] = useState("");
  const [valueState, setValueState] = useState("");
  const [placeholderState, setPlaceholderState] = useState("");
  const [directionState, setDirectionState] = useState("");
  const [layoutState, setLayoutState] = useState("");
  const [t] = useTranslation("common");

  const invokeActions = (option) => {
    if (typeof change === "function") {
      change(option);
    }
  };
  useEffect(() => {
    setNameState(name);
    setRulesState(rules || {});
    setLabelState(label);
    setDefaultValueState(defaultValue || "");
    setValueState(value || "");
    setPlaceholderState(placeholder || "");
    setLayoutState(
      formConfigs && formConfigs.layout
        ? formConfigs.layout || "5|5"
        : methods.formConfigs.layout || "5|5"
    );
    setDirectionState(
      formConfigs && formConfigs.direction
        ? formConfigs.direction || "vertical"
        : methods.formConfigs.direction || "vertical"
    );
  }, [
    value,
    defaultValue,
    formConfigs,
    label,
    methods.formConfigs.direction,
    methods.formConfigs.layout,
    name,
    placeholder,
    rules,
    disabled,
  ]);

  return (
    <Fragment>
      {directionState === "horizontal" ? (
        <div hidden={hidden} className={"row form-group align-items-center " + (classFormName || "")}>
          <label
            className={"mb-0 col-md-" + layoutState.split("|")[0]}
            required={rulesState.required}
          >
            {t(labelState)}
          </label>
          <div className={"col-md-" + layoutState.split("|")[1] + `${inputAppend ? ` input-group` : ""}`}>
            {layoutState && (
              <Controller
                onFocus={() => { }}
                name={nameState}
                defaultValue={defaultValueState}
                render={({ onChange, value }) => {
                  return (
                    <Input
                      className={
                        methods.errors[nameState]
                          ? " form-control border-red " + className
                          : "form-control " + className
                      }
                      value={valueState ? valueState : value ?? ""}
                      type={textAreaRow ? "textarea" : type ? type : ""}
                      rows={textAreaRow}
                      placeholder={t(placeholderState)}
                      disabled={disabled ? disabled : false}
                      onChange={(value) => {
                        onChange(value);
                        invokeActions(value);
                      }}
                    />
                  );
                }}
                control={methods.control}
                rules={rulesState}
              />
            )}
            {inputAppend && (
              <div className="input-group-append">
                <span className="input-group-text" id="basic-addon2">
                  {inputAppend}
                </span>
              </div>
            )}
            {nameState && methods.errors && (
              <EM errors={methods.errors[nameState]} />
            )}
          </div>
        </div>
      ) : (
        <div hidden={hidden} className={"form-group " + className}>
          <label required={rulesState.required}>{t(labelState)}</label>
          <div
            style={{ width: "100%" }}
            className={inputAppend ? `input-group` : ""}
          >
            {directionState && (
              <Controller
                onFocus={() => { }}
                className={
                  methods.errors[nameState]
                    ? "form-control border-red"
                    : "form-control"
                }
                name={nameState}
                defaultValue={defaultValueState}
                render={({ onChange, value }) => {
                  return (
                    <Input
                      className={
                        methods.errors[nameState]
                          ? " form-control border-red"
                          : "form-control"
                      }
                      value={valueState ? valueState : value ?? ""}
                      type={textAreaRow ? "textarea" : type ? type : ""}
                      rows={textAreaRow}
                      placeholder={t(placeholderState)}
                      disabled={disabled ? disabled : false}
                      onChange={(value) => {
                        onChange(value);
                        invokeActions(value);
                      }}
                    />
                  );
                }}
                control={methods.control}
                rules={rulesState}
              />
            )}
            {nameState && methods.errors && (
              <EM errors={methods.errors[nameState]} />
            )}
            {inputAppend && (
              <div className="input-group-append">
                <span className="input-group-text" id="basic-addon2">
                  {inputAppend}
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};
export default TextBox;
