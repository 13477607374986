import React, { useState, Fragment, useRef } from "react";
import { useParams } from "react-router-dom";
// Import Highcharts
import Highcharts from "highcharts";
import typeData from "highcharts/modules/data.js";
import HighchartsReact from "highcharts-react-official";
//Commons
import { DatePickerTools } from "../../../../commons/components/date-picker/DatePickerTools";
//services
import webviewServices from "../../../../utilities/services/webview";
//type
import { WebviewTypes } from "../../webview/type";
import utilsService from "../../../../utilities/services/utils/utils.service";
import { ExportCSV } from "../../../../commons/components/export-excel/ExportCSV";
import moment from "moment";

typeData(Highcharts);

const UNIT = "ha";

const metadataChart = {
  credits: {
    enabled: false,
  },
  chart: {
    type: "column",
  },
  title: {
    text: "",
  },
  xAxis: {
    categories: [],
  },
  yAxis: {
    title: {
      text: "Đơn vị tính: " + UNIT,
    },
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    series: {
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        formatter: function () {
          return utilsService.FormatCurrency(this.y) + " " + UNIT;
        },
      },
    },
  },
  tooltip: {
    pointFormatter: function () {
      return '<span style="color:' + this.color + '">' + this.series.name + "</span>: <b>" + utilsService.FormatCurrency(this.y) + " " + UNIT;
    },
  },
  series: [],
};

const StatisticDiseases = (props) => {
  const [options, setOptions] = useState(metadataChart);
  const [dataTable, setDataTable] = useState(null);
  const timeOption = useRef(null);
  const dataList = [];
  const { id } = useParams();

  const styleHeader = {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#123E69",
    textAlign: "left",
    marginBottom: "0",
  };
  /////////***********  Start Function-Management  ***********/////////////////
  const getDataChart = async (time) => {
    try {
      timeOption.current = time;
      let group = [];
      let production = [];
      const response = await webviewServices.getDataReport(id || props.id, time.from, time.to, WebviewTypes.farmDisease);
      if (response) {
        console.log("getDataChart", response);
        const value = typeof response.data === "object" && response.data.length ? response.data : [];
        value.forEach((v) => {
          group.push(v.groupName);
          v.data.forEach((d) => {
            let index = production.findIndex((prod) => prod.name === d.name);
            if (index !== -1) {
              production[index].data.push(d.value);
            } else {
              production.push({ name: d.name, data: [d.value] });
            }
          });
        });

        setOptions({ ...options, series: production, xAxis: { categories: group } });
        setDataTable(value);
      }
    } catch (err) {
      console.error("getDataChart", err);
    }
  };

  const defineTable = (data, quantity) => {
    const title = timeOption?.current ? `THỐNG KÊ DỊCH BỆNH TỪ NGÀY ${moment(timeOption.current.from).format("DD/MM/YYYY")} ĐẾN ${moment(timeOption.current.to).format("DD/MM/YYYY")}` : "";
    let renderRows = `<tr><th colspan="2" style="text-align:'left'">TỔNG CÁC NHÓM</th><th style="text-align:'left'">${quantity}</th></tr>`;
    data.forEach((item) => {
      if (item.isGroup) {
        renderRows += `<tr>
          <th >${item.index}</th>
          <th style="text-align:'left'">${item.name || ""}</th>
          <th style="text-align:'left'">${item.quantity}</th>
        </tr>`;
      } else {
        renderRows += `<tr>
          <td >${item.index}</td>
          <td>${item.name || ""}</td>
          <td style="text-align:'left'">${item.quantity}</td>
        </tr>`;
      }
    });

    return `
      <table border="1px">
          <thead>
            <tr>
              <th style="background-color:'#eaecf4'; font-size:'16px'" colspan="3">
                ${title}
              </th>
            </tr>
            <tr>
              <th>STT</th>
              <th>Cơ sở</th>
              <th>Số lượng (${UNIT})</th>
            </tr>
          </thead>
          <tbody>
            ${renderRows}
          </tbody>
      </table>`;
  };

  const getDataExport = async () => {
    try {
      if (dataTable) {
        let dataTotal = [];
        let quantity = 0;
        const groupIndex = ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", "XI", "XII", "XIII", "XIV", "XV"];
        const groups = dataTable || [];
        groups.forEach((group, i) => {
          quantity += group.total;
          dataTotal.push({ isGroup: true, index: groupIndex[i], name: group.groupName, quantity: group.total || 0 });
          group.data.forEach((item, index) => {
            dataTotal.push({ index: index + 1, name: item.name, participantTeamName: item.participantTeamName, quantity: item.value || 0 });
          });
        });
        return defineTable(dataTotal, quantity);
      }
      return;
    } catch (err) {
      console.error("getDataExport", err);
    }
  };
  /////////***********   End  Function-Management  ***********/////////////////
  return (
    <Fragment>
      <div className="mb-3">
        <DatePickerTools getDate={getDataChart} />
      </div>
      <div className="mb-3">
        <h4 style={styleHeader}>Biểu đồ thống kê dịch bệnh</h4>
        {dataTable?.length ? <HighchartsReact highcharts={Highcharts} options={options} /> : <div className="text-info">Không có dữ liệu</div>}
      </div>

      <div className="mb-3">
        <div className="d-flex align-items-center justify-content-between">
          <h4 style={styleHeader}>Bảng biểu dữ liệu thống kê dịch bệnh</h4>
          {!props.webview && (dataTable?.length ? <ExportCSV csvData={getDataExport} fileName={"ThongKeDichBenh"} /> : "")}
        </div>
        <div className="mt-2">
          {dataTable?.length ? (
            <table className="table table-bordered table-hover" id="table-diseases" style={{ fontSize: "14.5px" }}>
              <thead>
                <tr>
                  <th scope="col" className="text-center">
                    Thống kê theo nhóm
                  </th>
                  {dataTable[0]?.data?.length
                    ? dataTable[0].data.map((d, i) => {
                        return (
                          <th scope="col" key={i} className="text-center">
                            {d.name} ({UNIT})
                          </th>
                        );
                      })
                    : null}
                </tr>
              </thead>
              <tbody>
                {dataTable.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td scope="row" className="text-center">
                        {item.groupName}
                      </td>
                      {item?.data?.length
                        ? item?.data.map((i, key) => {
                            dataList[key] = (dataList[key] ?? 0) + i.value || 0;
                            return (
                              <td key={key} className="text-center">
                                {i.value || ""}
                              </td>
                            );
                          })
                        : ""}
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr className="text-center fw-5">
                  <td>TỔNG CÁC NHÓM</td>
                  {dataList.map((d, j) => {
                    return <td key={j}>{d || ""}</td>;
                  })}
                </tr>
              </tfoot>
            </table>
          ) : (
            <div className="text-info">Không có dữ liệu</div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default StatisticDiseases;
