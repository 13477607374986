function pageChange(current, size) {
  return {
    type: 'PAGE_CHANGE',
    payload: { current, size }
  }
}

function updateTotalRows(value) {
  return {
    type: 'UPDATE_TOTAL_ROWS',
    payload: value
  };
}

function textSearchChange(value) {
  return {
    type: 'TEXT_SEARCH_CHANGE',
    payload: value
  };
}

function optionsSearchChange(options) {
  return {
    type: 'OPTIONS_SEARCH_CHANGE',
    payload: options
  }
}

function onFilter(text, pagination, options) {
  return {
    type: 'FILTERING'
  }
}

const updateCacheMode = (value) => {
  return ({ type: 'UPDATE_CACHE_MODE', payload: value })
};

const offFilter = () => { return ({ type: 'ALL' }) }

export default {
  updateTotalRows,
  pageChange,
  textSearchChange,
  optionsSearchChange,
  onFilter,
  offFilter,
  updateCacheMode
}