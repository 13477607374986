
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import proxyService from '../proxy/proxy.service';

const paginationOptions = ({
  page,
  prePage,
  nextPage,
  firstPage,
  lastPage,
  className,
  noDataText,
}) => {
  return {
    page: page || 1,
    prePage: prePage || "<",
    nextPage: nextPage || ">",
    firstPage: firstPage || "Trang đầu",
    lastPage: lastPage || "Trang cuối",
    className: className || "pagination-table",
    noDataText: noDataText || "Không tìm thấy dữ liệu",
  };
};

const normalizeString = (str) => {
  if (!str) {
    return "";
  }
  return str
    .toString()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/đ/g, "d")
    .replace(/Đ/g, "D")
    .toLowerCase()
    .trim();
};

const yyyymmddFormatTimeString = (str) => {
  if (!str) {
    return "";
  }
  const textString = new Date(str);
  const dd = textString.getDate();
  const mm = textString.getMonth() + 1;
  const yyyy = textString.getFullYear();
  return [dd > 9 ? dd : `0${dd}`, mm > 9 ? mm : `0${mm}`, yyyy].join("/");
};

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
const FormatCurrency = (str) => {
  let val = str.toString();
  val = val.replace(/\./g, "");

  val += "";
  let x = val.split(",");
  let x1 = x[0];
  let x2 = x.length > 1 ? "," + x[1] : "";
  var rgx = /(\d+)(\d{3})/;

  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1" + "." + "$2");
  }

  return x1 + x2;
};
function FormatCurrency2(str, n = 100) {
  if (typeof str === "undefined") return "0";

  if (typeof str === "number")
    str = String(Math.round(str * n) / n).replace(/\./g, ",");
  let val = String(str);
  val = val.replace(/\./g, "");
  let str1 = "";
  val += "";
  let x = val.split(",");
  let x1 = x[0];
  let x2 = x.length > 1 ? "," + x[1] : "";
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1" + "." + "$2");
  }
  str1 = x1 + x2;
  return str1;
}
const generateIV = () =>
  (
    Math.random().toString(36).substring(2) +
    Math.random().toString(36).substring(2)
  ).substring(0, 16);

const exportToCSV = (csvData, fileName) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const ws = XLSX.utils.json_to_sheet(csvData);
  
    let objectMaxLength = []; 
    for (let i = 0; i < csvData.length; i++) {
      let value =Object.values(csvData[i]);
      console.log(value);
      for (let j = 0; j < value.length; j++) {
        if (typeof value[j] == "number") {
          objectMaxLength[j] = 10;
        } else {
          objectMaxLength[j] =
            objectMaxLength[j] >= value[j].length
              ? objectMaxLength[j]
              : value[j].length;
        }
      }
    }
  const wscols = objectMaxLength.map(w => { return { width: w} });
  ws["!cols"] = wscols;
  
  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  console.log("data", wb);
  FileSaver.saveAs(data, fileName + fileExtension);
}

const getVerificationStatusInBlockchain = async (id = null, api) => {
  return await proxyService.get(api + `/${id}`);
}

export default {
  paginationOptions,
  normalizeString,
  generateIV,
  yyyymmddFormatTimeString,
  numberWithCommas,
  FormatCurrency,
  FormatCurrency2,
  exportToCSV,
  getVerificationStatusInBlockchain
};
