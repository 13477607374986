import React from "react";

const AccordionTitle = ({ toggle, active, title }) => {
  return (
    <div className={`accordion-title ${!active ? "open" : ""} text-uppercase`} onClick={toggle}>
      {title}
    </div>
  );
};

export default AccordionTitle;
